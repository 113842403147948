import { PaletteMode } from '@mui/material';
import { LinkProps } from '@mui/material/Link';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { createTheme } from '@mui/material/styles';
import ThemeProvider, { ThemeProviderProps } from '@mui/material/styles/ThemeProvider';
import React, { ComponentType } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import useDarkMode from 'use-dark-mode';

// import {  } from 'react-router-dom/server';
export const GeoscapeDeveloperThemeProvider: ComponentType<
  Omit<ThemeProviderProps, "theme">
> = ({ children, ...props }) => {
  const { value: isDark } = useDarkMode();
  const mode = isDark ? 'dark' : 'light';
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider {...props} theme={theme(mode)}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
};


const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return (
    <RouterLink data-testid="custom-link" ref={ref} to={href} {...other} />
  );
});


const theme = (mode: PaletteMode) => createTheme({
  // breakpoints: {
  //   values: {
  //     xs: 0,
  //     sm: 600,
  //     md: 900,
  //     lg: 1200,
  //     xl: 1536,
  //   },
  // },

  palette: {
    mode: mode,
    // secondary: {
    //  main: '#999999',
  },

  //   primary: {
  //     main: '#f2f2f2',
  //    },
  // },

  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    // MuiButtonBase: {
    //   defaultProps: {
    //     disabled	: true,
    //   },
    // },

    // MuiButton: {
    //   defaultProps: {
    //     // disabled: true,
    //     color: 'secondary'
    //   },
    // },
  },
});

// theme.typography.h5 = {
  // fontSize: '14px',
  // "@media (min-width:900px)": {
  //   fontSize: "12px",
  // },

  // "@media (min-width:1200px)": {
  //   fontSize: "16px",
  // },
  // [theme.breakpoints.up('md')]: {
  //   // fontSize: '2.4rem',
  // },
// };

// Documentation
// https://mui.com/material-ui/customization/typography/
