import * as QueryKeys from "data";
import { useIntercom } from "react-use-intercom";

import { Box, Card, CardContent, CardHeader, FormGroup } from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";

import { makeStyles } from "@mui/styles";
import CustomTable from "components/shared/table";
import { fetchAdminDatasets, fetchDefinitions } from "data/queries";
import { useEffect, useState } from "react";
import { UseQueryResult, useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { customColumns, datasetsToRows } from "./columns-rows";


import { DatasetFiltersType, DatasetList } from "data/models";
import FilterComponent from "pages/geoscape-data/share-component/filter-component";
import { updateQueryAndFilters } from "pages/geoscape-data/utils";
import { useLocation } from "react-router-dom";
import { ChangeStatus } from "./change-status";
import { AdminDatasetFilters } from "./filters";
import * as localStorageKeys from "data/local-storage-keys";
import { GridRowClassNameParams } from "@mui/x-data-grid-premium";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

export const AdminDatasets = () => {
  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const urlLocationQuery = useLocation().search;

  const savedSearch = localStorage.getItem(
    localStorageKeys.searchAdminDatasets
  );

  let initialQueryState: DatasetFiltersType = {
    query: "",
    product: [],
    region: [],
    format: [],
    datum: [],
    release: "",
    latestRelease: false,
    licenced: false,
    notPublished: false,
  };

  const [queryState, setQueryState] =
    useState<DatasetFiltersType>(initialQueryState);

  const [loadingFilters, setLoadingFilters] = useState<boolean>(false);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const datasetsSearchQuery: UseQueryResult<DatasetList> = useQuery(
    [QueryKeys.datasetsSearchQuery, identityId, queryState],
    () => fetchAdminDatasets(queryState, identityState)
  );

  const datasetDefinitions = useQuery([QueryKeys.definitionsKey], () =>
    fetchDefinitions(identityState)
  );

  const { show } = useIntercom();

  useEffect(() => {
    updateQueryAndFilters({
      savedSearch,
      setQueryFilterState: setQueryState,
      setLoadingFilters,
      urlLocationQuery,
      initialQueryFilterState: initialQueryState,
      definitions: datasetDefinitions,
    });
  }, [datasetDefinitions.isSuccess]);

  const useStyles = makeStyles({
    highlightedRow: {
      color: "grey",
    },
  });

  const classes = useStyles();

  const getCustomRowLicence = (params: GridRowClassNameParams): string => {
    if (params.row.licenced == false) {
      return classes.highlightedRow;
    }
    return "";
  };

  const customColumns_ = customColumns(identityState);

  const isSuccess = datasetsSearchQuery.isSuccess;
  const isLoading = datasetsSearchQuery.isLoading;
  const rows = datasetsSearchQuery.data
    ? datasetsToRows(datasetsSearchQuery.data)
    : [];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          position: "relative",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[datasetsSearchQuery, datasetDefinitions]}
          mutations={[]}
        />

        <Card>
          <CardHeader
            title="Search Datasets"
            style={{ padding: "5" }}
            variant="h5"
          />

          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "10px",
              gap: "20px",
            }}
          >
            <FormGroup style={{ display: "flex", flexDirection: "row" }}>
              {loadingFilters ? (
                <Box
                  style={{
                    display: "flex",
                    padding: "0px",
                    gap: "10px",
                  }}
                >
                  <h2>Loading...</h2>
                </Box>
              ) : (
                <FilterComponent
                  queryFilterState={queryState}
                  setQueryFilterState={setQueryState}
                  initialQueryFilterState={initialQueryState}
                  renderFilter={AdminDatasetFilters}
                  definitions={datasetDefinitions}
                  localStorageKey={localStorageKeys.searchAdminDatasets}
                />
              )}
            </FormGroup>
          </CardContent>
        </Card>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <ChangeStatus
              queryState={queryState}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
            />
          </CardContent>
        </Card>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            // height: "80%"
          }}
        >
          <CustomTable
            getCustomRowClass={getCustomRowLicence}
            rows={isSuccess ? rows : []}
            cols={isLoading ? [] : customColumns_}
            isLoading={isLoading}
            overlayText={
              "We couldn't find any datasets that match your filter criteria."
            }
            checkboxSelection={true}
            onSelectionModelChange={(ids) => {
              setSelectedIds(ids as string[]);
            }}
          />
        </Card>
      </div>
    </>
  );
};
