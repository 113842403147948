import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from "@mui/icons-material/Download";
import EmailIcon from "@mui/icons-material/Email";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LayersIcon from "@mui/icons-material/Layers";
import { Button, ButtonGroup, Link, Popover } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import { useIntercom } from "react-use-intercom";
import * as Routes from "routes";
import { viewDatasetInExplorer } from "../catalogue-page";
import { productCodeToExplorerLayerIdMappings } from "pages/geoscape-data/constants";

const CustomButtonGroup = ({ licenced, displayProduct }: any) => {
  const { showNewMessage } = useIntercom();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        color="primary"
      >
        {licenced && (
          <Button
            LinkComponent={Link}
            href={`${Routes.dataDownload}?product=${displayProduct}&licenced=${licenced}`}
            startIcon={<DownloadIcon />}
          >
            Download
          </Button>
        )}
        {!licenced && (
          <Button
            onClick={() => {
              showNewMessage(
                `I’d like to talk to someone about "${displayProduct}"`
              );
            }}
            startIcon={<EmailIcon />}
          >
            Contact
          </Button>
        )}
        <Button
          color="primary"
          disabled={true}
          startIcon={<DownloadIcon />}
          onClick={() => {}}
        >
          Sample
        </Button>
        <Button
          color="primary"
          startIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
        >
          More
        </Button>
      </ButtonGroup>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ButtonGroup orientation="vertical" variant="contained">
          <Button
            key="create-key"
            color="primary"
            disabled
            startIcon={<AddIcon />}
          >
            Create Key
          </Button>
          <Button
            key="create-key"
            color="primary"
            disabled={!productCodeToExplorerLayerIdMappings.get(displayProduct)}
            onClick={() => viewDatasetInExplorer(displayProduct, history)}
            startIcon={<LayersIcon />}
          >
            Map
          </Button>
        </ButtonGroup>
      </Popover>
    </div>
  );
};

export default CustomButtonGroup;
