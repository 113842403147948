import React, {
  useState,
  Dispatch,
  SetStateAction,
  CSSProperties,
} from "react";
import { Box, TextField, Autocomplete, CircularProgress } from "@mui/material";
import { Field } from "formik";
import _ from "lodash";

interface AddressResult {
  address: string;
  id: string;
}

const fetchSuggestions = async (
  input: string,
  setLoading: Dispatch<SetStateAction<boolean>>,
  setIsManualAddress: Dispatch<SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    if (!input || input.length < 3) {
      return [];
    }
    const response = await fetch(
      `https://api.psma.com.au/v1/predictive-demo/address?query=${input}`
    );
    if (response.status !== 200) {
      return [];
    }
    const body = await response.json();
    if (body.suggest.length === 0) {
      setIsManualAddress(true);
    } else {
      setIsManualAddress(false);
    }
    const addressResults: AddressResult[] = body.suggest.map((s: any) => {
      return {
        address: s.address,
        id: s.id,
      };
    });
    return addressResults;
  } finally {
    setLoading(false);
  }
};

interface OverrideCSSProperties {
  [key: string]: CSSProperties;
}

interface PredictiveAddressSearchProps {
  onChange: any;
  onBlur: any;
  id: string;
  name: string;
  label: string;
  value: string;
  error: boolean | undefined;
  helperText: string | false | undefined;
  setFieldValue: any;
  readOnly: boolean;
  disabled: boolean;
  size: "small" | "medium";
  showManualAddressLabel?: boolean;
  onClearAddress?: () => void;
  textFieldStyle?: OverrideCSSProperties | CSSProperties;
}

export const PredictiveAddressSearch = (
  props: PredictiveAddressSearchProps
) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<AddressResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isManualAddress, setIsManualAddress] = useState<boolean>(false);

  const updateInput = async (event: any) => {
    props.onChange(event);
    const input = event.target.value;
    const addresses: AddressResult[] = await fetchSuggestions(
      input,
      setLoading,
      setIsManualAddress
    );
    _.debounce(async (input) => setSuggestions(addresses), 300)(input);
  };

  return (
    <>
      <Autocomplete
        disabled={props.disabled}
        readOnly={props.readOnly}
        freeSolo
        id={props.id}
        open={open}
        size={props.size}
        fullWidth
        noOptionsText="Manual address entry"
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        value={props.value}
        onChange={(e, value: AddressResult | string | null) => {
          if (value == null) {
            if (props.onClearAddress) props.onClearAddress();
            return;
          }
          if (typeof value === "string") {
            props.setFieldValue("address", value);
          } else {
            props.setFieldValue("address", value.address);
            props.setFieldValue("addressId", value.id);
          }
        }}
        options={suggestions}
        getOptionLabel={(option: AddressResult | string) =>
          typeof option === "string" ? option : option.address
        }
        filterOptions={(x) => x}
        loading={loading}
        renderInput={(params) => (
          <Field
            sx={props.textFieldStyle}
            component={TextField}
            {...params}
            onBlur={props.onBlur}
            variant="outlined"
            margin="normal"
            id={props.id}
            name={props.name}
            label={props.label}
            value={props.value}
            error={props.error}
            helperText={props.helperText}
            disabled={props.disabled}
            onChange={(event: any) => updateInput(event)}
            InputProps={{
              readOnly: props.readOnly,
              disabled: props.disabled,
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {props.showManualAddressLabel && (
        <Box ml={2} textAlign={"left"} hidden={!isManualAddress}>
          {isManualAddress ? "Address entered manually" : ""}
        </Box>
      )}
    </>
  );
};
