import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  IconButton,
  Paper,
  Stack,
} from "@mui/material";
import { useRef, useState } from "react";
import { layerIdToPrettyFormat } from "../layers/layer-utils";
import CloseIcon from "@mui/icons-material/Close";
import Draggable from "react-draggable";
import InfoIcon from "@mui/icons-material/Info";
import { makeStyles } from "@mui/styles";
import { AttributesDynamicTable } from "./attributes-dynamic-table";
import { AttributesKeyValueTable } from "./attributes-key-value-table";
import _ from "lodash";

interface AttributesCardProps {
  show: boolean;
  attributes: { [layerId: string]: { [key: string]: string }[] };
  selectedAttribute: string;
  onSelectAttribute: (key: string) => void;
  onSelectFeature: (key: string) => void;
  onClose: () => void;
  onInfoClick: () => void;
}

const rowsKeyValue = (attributes: {
  [key: string]: string;
}): {
  attribute: string;
  value: string;
}[] => {
  return Object.keys(attributes)
    .filter((key) => attributes[key] != undefined && attributes[key] != null)
    .map((key) => {
      return {
        attribute: key,
        value: attributes[key],
      };
    });
};

export const AttributesCard = (props: AttributesCardProps) => {
  const [expanded, setExpanded] = useState(props.show);
  const classes = useStyles();
  const initialPosition = { x: 0, y: 0 };

  const [position, setPosition] = useState(initialPosition);
  const nodeRef = useRef(null);

  const resetPosition = () => {
    setPosition(initialPosition);
  };

  const handleInfoClick = () => {
    resetPosition();
    if (!expanded) setExpanded(true);
    props.onInfoClick();
  };
  return (
    <>
      <Paper className={classes.minimized}>
        <IconButton
          disabled={_.isEmpty(props.attributes)}
          onClick={handleInfoClick}
        >
          <InfoIcon />
        </IconButton>
      </Paper>
      {(props.show || expanded) && (
        <Draggable
          nodeRef={nodeRef}
          position={position}
          onStop={(e, data) => setPosition({ x: data.x, y: data.y })}
        >
          <Card
            ref={nodeRef}
            style={{
              border: "none",
              boxShadow: "none",
              width: "450px",
              maxHeight: "100%",
              display: "flex",
              flexDirection: "column",
              alignSelf: "self-end",
              position: "fixed",
              bottom: "calc(0px + 64px + 10px)", // footer + offset
              left: "50%",
            }}
          >
            <CardHeader
              sx={{
                cursor: "move",
              }}
              title="Selected Features"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setExpanded(false);
                    props.onClose();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              }
            ></CardHeader>
            <CardContent>
              <Box sx={{ maxWidth: "450px" }} overflow="auto">
                <Stack gap={1} mb="15px" direction="row">
                  {Object.keys(props.attributes)
                    .sort()
                    .map((key) => {
                      return (
                        <Chip
                          size="small"
                          key={key}
                          sx={{ gap: 10 }}
                          label={layerIdToPrettyFormat(key)}
                          variant={
                            key == props.selectedAttribute
                              ? "filled"
                              : "outlined"
                          }
                          onClick={() => props.onSelectAttribute(key)}
                        />
                      );
                    })}
                </Stack>
              </Box>
              {props.attributes &&
                props.attributes[props.selectedAttribute].length === 1 && (
                  <AttributesKeyValueTable
                    rows={rowsKeyValue(
                      props.attributes[props.selectedAttribute][0]
                    )}
                  />
                )}
              {props.attributes &&
                props.attributes[props.selectedAttribute].length > 1 && (
                  <AttributesDynamicTable
                    attributes={props.attributes[props.selectedAttribute]}
                    onSelectFeature={props.onSelectFeature}
                  />
                )}
            </CardContent>
          </Card>
        </Draggable>
      )}
    </>
  );
};

const useStyles = makeStyles({
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});
