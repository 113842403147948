import * as QueryKeys from "data";
import { Form, Formik } from "formik";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { useIdentity } from "contexts/identity-context";
import {
  ReactQueryQueryError,
  fetchAvailableSubscriptions,
  fetchUserDetailsAdmin,
} from "data/queries";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  cancelAdminUserFutureSubscription,
  editAdminUserSubscription,
} from "data/mutations";
import { formatTimestamp } from "utils/format-date";
import { LoadingButton } from "@mui/lab";
import { PortalPlan } from "data/models";
import {
  ChangeType,
  EditAdminUserSubscription,
  HistoryState,
  ProductIds,
  SubmitEditAdminUserSubscription,
} from "./types";
import {
  updateAdminUserSubscriptionValidationSchema,
  isDisabledTerm,
  isDisabledRateLimit,
  isDisabledAllowance,
  isDisabledBudgetDollars,
  isDisabledBudgetCredits,
  isDisabledChargeOverage,
  isDisabledApply,
} from "./validation";
import dayjs, { ManipulateType } from "dayjs";
import * as Routes from "routes";
import { extractPlanField, extractProductId } from "./utils";
import { useState } from "react";
import { start } from "repl";
import ConfirmationModal from "components/modals/confirmation-modal";
import { formatInterval } from "utils/format";
import BackButton from "components/navigation/back-button";
import { useSnackbar } from "notistack";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

const FREEMIUM = "freemium";
const SAME = "same";
const UPGRADE = "upgrade";
const DOWNGRADE = "downgrade";

const showMethodMYOB = (
  productId: string,
  cadence: string,
  productIds: ProductIds
) => {
  return (
    productId === productIds.enterprise ||
    (productId === productIds.professional && cadence === "year")
  );
};

const calculateChangeType = (
  oldSubscriptionProductId: string,
  oldCadence: string,
  newSubscriptionProductId: string,
  newCadence: string,
  productIds: ProductIds,
  setSubscriptionAction: any,
  paymentServiceChanged: boolean
): ChangeType => {
  // Uses the product IDs and the cadence to calculate if the user is trying to upgrade/downgrade
  // TODO: Improve this function - difficult to do dictionary since product IDs are dynamic

  if (
    oldSubscriptionProductId === newSubscriptionProductId &&
    oldCadence === newCadence
  ) {
    setSubscriptionAction(SAME);
    return SAME;
  }

  if (oldSubscriptionProductId === productIds.freemium) {
    // Fremium ->  Product
    if (newSubscriptionProductId !== productIds.freemium) {
      setSubscriptionAction(UPGRADE);
      return UPGRADE;
    }
  }

  // Professional
  if (oldSubscriptionProductId === productIds.professional) {
    //Professional -> Enterprise
    if (newSubscriptionProductId === productIds.enterprise) {
      setSubscriptionAction(UPGRADE);
      return UPGRADE;
    }

    //Profesional Monthly -> Profesional Annual
    if (
      oldCadence === "month" &&
      newSubscriptionProductId === productIds.professional &&
      newCadence === "year"
    ) {
      setSubscriptionAction(UPGRADE);
      return UPGRADE;
    }
  }

  setSubscriptionAction(DOWNGRADE);
  return DOWNGRADE;
};

const AdminAccountSubscription = () => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const queryClient = useQueryClient();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const accountId = (history.location.state as HistoryState).accountId;

  const [changeType, setChangeType] = useState<ChangeType>(SAME);

  const [
    cancelFutureSubscriptionModalIsOpen,
    setCancelFutureSubscriptionModalIsOpen,
  ] = useState(false);

  const adminUserDetailsQuery = useQuery(
    [QueryKeys.adminUserDetailsQuery, accountId],
    () => fetchUserDetailsAdmin(accountId, identityState),
    { staleTime: 10000 }
  );

  const availableSubscriptionsQuery = useQuery(
    [QueryKeys.availableSubscriptions],
    () => fetchAvailableSubscriptions(identityState)
  );

  const adminUserSubscriptionMutation = useMutation(
    (values: SubmitEditAdminUserSubscription) =>
      editAdminUserSubscription(accountId, values, identityState),
    {
      onSuccess: (newSubscription: any) => {
        queryClient.removeQueries(["adminUserDetailsQuery", accountId]);

        enqueueSnackbar(
          newSubscription.enterpriseCreditAmountOverride
            ? `The allowance has been updated to ${newSubscription.enterpriseCreditAmountOverride} Credits for the Upcoming Subscription Cycle`
            : `You successfully ${changeType}d them to ${
                newSubscription.activeSubscription.plan.name
              } ${
                newSubscription.activeSubscription.plan.interval === "year"
                  ? "Annual"
                  : "Monthly"
              } subscription.`,
          { variant: "success" }
        );

        history.push(Routes.adminAccountDetailsPath + accountId, {
          ...(history.location.state as Object),
        });
      },
    }
  );

  const futureSubscriptionMutation = useMutation(
    () => cancelAdminUserFutureSubscription(accountId, identityState),
    {
      onSuccess: () => {
        queryClient.setQueryData([QueryKeys.adminUserDetailsQuery, accountId], {
          ...adminUserDetailsQuery.data,
          nextSub: null,
        });

        setCancelFutureSubscriptionModalIsOpen(false);
        enqueueSnackbar(
          "You successfully cancelled your pending subscription downgrade.",
          { variant: "success" }
        );
      },
    }
  );

  const isLoading =
    adminUserDetailsQuery.isLoading || availableSubscriptionsQuery.isLoading;
  const isSuccess =
    adminUserDetailsQuery.isSuccess && availableSubscriptionsQuery.isSuccess;

  const productIds = {
    enterprise: extractProductId(
      availableSubscriptionsQuery.data,
      "enterprise"
    ),
    professional: extractProductId(
      availableSubscriptionsQuery.data,
      "professional"
    ),
    freemium: extractProductId(availableSubscriptionsQuery.data, FREEMIUM),
  };

  const [subscriptionAction, setSubscriptionAction] = useState("");

  const currentPaymentService = adminUserDetailsQuery.data?.isMyob
    ? "myob"
    : "stripe";

  const [newPaymentService, setNewPaymentService] = useState(
    currentPaymentService
  );

  return (
    <Box sx={{ position: "relative", top: "-70px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
          gap: "16px",
          height: "43px",
        }}
      >
        <BackButton />
      </Box>

      <ReactQueryErrorWrapper
        queries={[adminUserDetailsQuery, availableSubscriptionsQuery]}
        mutations={[adminUserSubscriptionMutation, futureSubscriptionMutation]}
      />

      {isLoading && <Skeleton variant="rectangular" height={600} />}

      {isSuccess && (
        <Formik<EditAdminUserSubscription>
          validationSchema={updateAdminUserSubscriptionValidationSchema}
          initialValues={{
            productId: adminUserDetailsQuery.data.productId,
            subscriptionStart: adminUserDetailsQuery.data.subscriptionStart,
            subscriptionRenewal: adminUserDetailsQuery.data.subscriptionRenewal,
            subscriptionCadence: adminUserDetailsQuery.data.subscriptionCadence,
            automaticRenewal: true,
            creditAllowance: adminUserDetailsQuery.data.creditAllowance,
            creditRemaining: adminUserDetailsQuery.data.creditRemaining,
            overageLimitDollars: adminUserDetailsQuery.data.overageLimitDollars,
            overageLimitCredits: adminUserDetailsQuery.data.overageLimitCredits,
            overageUsageCredits: adminUserDetailsQuery.data.overageUsageCredits,
            overageUsageDollars: adminUserDetailsQuery.data.overageUsageDollars,
            rateLimit: parseInt(
              adminUserDetailsQuery.data.rateLimit.replace("ps", "")
            ),
            overageRate: adminUserDetailsQuery.data.overageRate,
            paymentService: currentPaymentService, //  "myob" | "stripe"
            chargeOverage: false,
            paymentMethod: adminUserDetailsQuery.data.paymentMethod, // "Card ending in ... Expires ..." | "No payment method"
            enterpriseCreditAmountOverride:
              adminUserDetailsQuery.data.enterpriseCreditAmountOverride,
          }}
          onSubmit={async (values) => {
            adminUserSubscriptionMutation.reset();

            const submitValues: SubmitEditAdminUserSubscription = {
              productId: values.productId,
              rps: values.rateLimit,
              autoRenew: values.automaticRenewal,
              creditAllowance:
                values.productId === productIds.enterprise
                  ? values.enterpriseCreditAmountOverride
                  : values.creditAllowance, //for enterprise the creditAllowance = enterpriseCreditAmountOverride otherwise is set to null
              cadence: values.subscriptionCadence,
              waiveOverage: !values.chargeOverage, // By default we send true, Not ChargeOverage
              overageLimitDollars: values.overageLimitDollars,
              paymentService: values.paymentService,
              enterpriseCreditAmountOverride:
                values.enterpriseCreditAmountOverride,
            };
            adminUserSubscriptionMutation.mutate(submitValues);
          }}
        >
          {({
            values: {
              productId,
              subscriptionStart,
              subscriptionRenewal,
              subscriptionCadence,
              automaticRenewal,
              creditAllowance,
              creditRemaining,
              overageLimitDollars,
              overageLimitCredits,
              overageUsageCredits,
              overageUsageDollars,
              rateLimit,
              overageRate,
              paymentService,
              chargeOverage,
              paymentMethod,
              enterpriseCreditAmountOverride,
            },
            handleReset,
            handleChange,
            setFieldValue,

            handleSubmit,
            errors,
            touched,
            isValid,
          }) => {
            return (
              <Form>
                <Box>
                  <Card
                    sx={{
                      marginTop: "8px",
                      padding: "16px",
                      display: "flex",
                      flexDirection: "row-reverse",
                      gap: "8px",
                    }}
                  >
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => history.goBack()}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={adminUserSubscriptionMutation.isLoading}
                      type="submit"
                      variant="contained"
                      size="small"
                      disabled={isDisabledApply(
                        paymentService,
                        paymentMethod,
                        changeType,
                        adminUserDetailsQuery.data.nextSub,
                        productId == productIds.enterprise,
                        paymentService !== currentPaymentService
                      )}
                    >
                      Apply
                    </LoadingButton>
                  </Card>

                  {adminUserDetailsQuery.data.nextSub && (
                    <>
                      <Card sx={{ marginTop: "16px", padding: "16px" }}>
                        <Alert
                          severity="warning"
                          action={
                            <Button
                              size="small"
                              color="warning"
                              onClick={() => {
                                futureSubscriptionMutation.reset();
                                setCancelFutureSubscriptionModalIsOpen(true);
                              }}
                            >
                              Cancel scheduled change
                            </Button>
                          }
                        >
                          This user has a scheduled downgrade from{" "}
                          {
                            adminUserDetailsQuery.data.subscriptionPlan.split(
                              " "
                            )[0]
                          }{" "}
                          {formatInterval(
                            adminUserDetailsQuery.data.subscriptionCadence
                          )}{" "}
                          to {adminUserDetailsQuery.data.nextSub.plan.name}{" "}
                          {formatInterval(
                            adminUserDetailsQuery.data.nextSub.plan.interval
                          )}
                          .
                        </Alert>
                      </Card>

                      <ConfirmationModal
                        isLoading={futureSubscriptionMutation.isLoading}
                        isError={futureSubscriptionMutation.isError}
                        open={cancelFutureSubscriptionModalIsOpen}
                        header={`Confirm Cancellation of Downgrade`}
                        body={`Are you sure you want to cancel the scheduled downgrade? When cancelled, the previous subscription of ${
                          adminUserDetailsQuery.data.subscriptionPlan
                        } ${formatInterval(
                          adminUserDetailsQuery.data.subscriptionCadence
                        )} will renew on ${formatTimestamp(
                          adminUserDetailsQuery.data.subscriptionRenewal * 1000
                        )}.`}
                        leftButtonText={"Confirm"}
                        leftButtonColor="primary"
                        rightButtonText={"Cancel"}
                        rightButtonColor="warning"
                        handleLeftButton={() =>
                          futureSubscriptionMutation.mutate()
                        }
                        handleRightButton={() => {
                          setCancelFutureSubscriptionModalIsOpen(false);
                        }}
                        handleClose={() =>
                          setCancelFutureSubscriptionModalIsOpen(false)
                        }
                      />
                    </>
                  )}

                  <Card sx={{ marginTop: "16px", padding: "16px" }}>
                    {productId == productIds.enterprise && (
                      <Alert severity="warning">
                        For Enterprise downgrades and changes to the allowance
                        will take effect at the end of the current subscription
                        term.
                      </Alert>
                    )}

                    <br />

                    {changeType === SAME &&
                      productId !== productIds.enterprise && (
                        <Alert severity="error">
                          You are only allowed to upgrade or downgrade the
                          user's subscription, please choose a different
                          subscription or term.
                        </Alert>
                      )}

                    {changeType === UPGRADE && (
                      <Alert severity="info">
                        Upgrades will be applied immediately.
                      </Alert>
                    )}

                    {changeType === DOWNGRADE && (
                      <Alert severity="info">
                        Downgrades will take effect at the end of the current
                        billing cycle.
                      </Alert>
                    )}
                  </Card>
                </Box>

                <Box sx={{ display: "flex", gap: "16px", marginTop: "16px" }}>
                  <Card sx={{ flexGrow: "1", flexBasis: "0", padding: "16px" }}>
                    <Typography variant="h5" sx={{ marginBottom: "32px" }}>
                      Billing
                    </Typography>
                    <Typography variant="body1">Subscription</Typography>

                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="plan-label">Plan</InputLabel>
                      <Select
                        size="small"
                        id="productId"
                        label="Plan"
                        labelId="plan-label"
                        name="productId"
                        value={productId}
                        onChange={async (event) => {
                          // Handle this async to ensure validation runs on new value
                          // https://github.com/jaredpalmer/formik/issues/2059#issuecomment-1338542955

                          const newProductId = event.target.value;
                          let newSubscriptionCadence = "month";

                          if (newProductId === productIds.freemium) {
                            // FREEMIUM

                            await setFieldValue(
                              "subscriptionCadence",
                              newSubscriptionCadence
                            );
                            await setFieldValue(
                              "rateLimit",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "rateLimit"
                              )
                            );
                            await setFieldValue(
                              "creditAllowance",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "creditAmount"
                              )
                            );
                            await setFieldValue(
                              "overageRate",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "overageRate"
                              )
                            );

                            await setFieldValue(
                              "enterpriseCreditAmountOverride",
                              null
                            );

                            await setFieldValue("paymentService", "stripe");
                            setNewPaymentService("stripe");
                          } else if (newProductId === productIds.professional) {
                            // PROFESSIONAL

                            await setFieldValue(
                              "subscriptionCadence",
                              newSubscriptionCadence
                            );
                            await setFieldValue(
                              "rateLimit",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "rateLimit"
                              )
                            );
                            await setFieldValue(
                              "creditAllowance",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "creditAmount"
                              )
                            );

                            await setFieldValue(
                              "overageRate",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "overageRate"
                              )
                            );

                            await setFieldValue(
                              "enterpriseCreditAmountOverride",
                              null
                            );
                            await setFieldValue("paymentService", "stripe");
                            setNewPaymentService("stripe");
                          } else if (newProductId === productIds.enterprise) {
                            // ENTERPRISE
                            newSubscriptionCadence = "year";

                            await setFieldValue(
                              "subscriptionCadence",
                              newSubscriptionCadence
                            );
                            await setFieldValue(
                              "rateLimit",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "rateLimit"
                              )
                            );
                            await setFieldValue(
                              "creditAllowance",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "creditAmount"
                              )
                            );

                            await setFieldValue(
                              "enterpriseCreditAmountOverride",
                              extractPlanField(
                                availableSubscriptionsQuery.data,
                                newProductId,
                                newSubscriptionCadence,
                                "creditAmount"
                              )
                            );
                            await setFieldValue("overageLimitDollars", 0);
                            await setFieldValue("overageLimitCredits", 0);
                            await setFieldValue("paymentService", "myob");
                            setNewPaymentService("myob");
                          }

                          // Set the start and end dates
                          const newChangeType = calculateChangeType(
                            adminUserDetailsQuery.data.productId,
                            adminUserDetailsQuery.data.subscriptionCadence,
                            newProductId,
                            newSubscriptionCadence,
                            productIds,
                            setSubscriptionAction,
                            newPaymentService !== currentPaymentService
                          );

                          let startDate = dayjs();
                          let endDate = startDate;
                          if (newChangeType === DOWNGRADE) {
                            startDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionRenewal
                            );
                            endDate = startDate.add(
                              1,
                              newSubscriptionCadence as ManipulateType
                            );
                          } else if (newChangeType === SAME) {
                            startDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionStart
                            );
                            endDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionRenewal
                            );
                          } else if (newChangeType === UPGRADE) {
                            endDate = startDate.add(
                              1,
                              newSubscriptionCadence as ManipulateType
                            );
                          }

                          await setFieldValue(
                            "subscriptionStart",
                            startDate.unix()
                          );
                          await setFieldValue(
                            "subscriptionRenewal",
                            endDate.unix()
                          );

                          await setChangeType(newChangeType);
                          await setFieldValue("productId", newProductId);
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        <MenuItem
                          key={productIds.freemium}
                          value={productIds.freemium}
                        >
                          Freemium
                        </MenuItem>
                        <MenuItem
                          key={productIds.professional}
                          value={productIds.professional}
                        >
                          Professional
                        </MenuItem>
                        <MenuItem
                          key={productIds.enterprise}
                          value={productIds.enterprise}
                        >
                          Enterprise
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="subscriptionCadence-label">
                        Term
                      </InputLabel>
                      <Select
                        size="small"
                        id="subscriptionCadence"
                        label="Term"
                        labelId="subscriptionCadence-label"
                        name="subscriptionCadence"
                        value={subscriptionCadence}
                        onChange={async (event) => {
                          const newSubscriptionCadence =
                            event.target.value.toLowerCase();

                          await setFieldValue(
                            "creditAllowance",
                            extractPlanField(
                              availableSubscriptionsQuery.data,
                              productId,
                              newSubscriptionCadence,
                              "creditAmount"
                            )
                          );

                          // Set the start and end dates
                          const newChangeType = calculateChangeType(
                            adminUserDetailsQuery.data.productId,
                            adminUserDetailsQuery.data.subscriptionCadence,
                            productId,
                            newSubscriptionCadence,
                            productIds,
                            setSubscriptionAction,
                            newPaymentService !== currentPaymentService
                          );

                          let startDate = dayjs();
                          let endDate = startDate;
                          if (newChangeType === DOWNGRADE) {
                            startDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionRenewal
                            );
                            endDate = startDate.add(
                              1,
                              newSubscriptionCadence as ManipulateType
                            );
                          } else if (newChangeType === SAME) {
                            startDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionStart
                            );
                            endDate = dayjs.unix(
                              adminUserDetailsQuery.data.subscriptionRenewal
                            );
                          } else if (newChangeType === UPGRADE) {
                            endDate = startDate.add(
                              1,
                              newSubscriptionCadence as ManipulateType
                            );
                          }

                          await setFieldValue(
                            "subscriptionStart",
                            startDate.unix()
                          );
                          await setFieldValue(
                            "subscriptionRenewal",
                            endDate.unix()
                          );

                          await setChangeType(newChangeType);

                          await setFieldValue(
                            "subscriptionCadence",
                            newSubscriptionCadence
                          );

                          await setFieldValue(
                            "paymentService",
                            newSubscriptionCadence == "year"
                              ? currentPaymentService
                              : "stripe"
                          );
                        }}
                        variant="outlined"
                        disabled={isDisabledTerm(productId, productIds)}
                        fullWidth
                      >
                        <MenuItem key="month" value="month">
                          Monthly
                        </MenuItem>
                        <MenuItem key="year" value="year">
                          Annual
                        </MenuItem>
                      </Select>
                    </FormControl>

                    {productId == productIds.enterprise && (
                      <TextField
                        size="small"
                        id="outlined-basic"
                        name="enterpriseCreditAmountOverride"
                        label="Allowance"
                        variant="outlined"
                        value={enterpriseCreditAmountOverride}
                        onChange={handleChange}
                        type="number"
                        inputProps={{ min: 2, max: 500000000 }}
                        error={!!errors.rateLimit}
                        helperText={errors.rateLimit}
                        fullWidth
                        margin="normal"
                        disabled={isDisabledRateLimit(productId, productIds)}
                      />
                    )}
                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="rateLimit"
                      label="Requests per second"
                      variant="outlined"
                      value={rateLimit}
                      onChange={handleChange}
                      type="number"
                      inputProps={{ min: 2, max: 1000 }}
                      error={!!errors.rateLimit}
                      helperText={errors.rateLimit}
                      fullWidth
                      margin="normal"
                      disabled={isDisabledRateLimit(productId, productIds)}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="Start Date"
                      label="Start Date"
                      variant="outlined"
                      value={formatTimestamp(subscriptionStart * 1000)}
                      type="text"
                      error={!!errors.subscriptionStart}
                      helperText={errors.subscriptionStart}
                      fullWidth
                      margin="normal"
                      disabled
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="Renews on"
                      label="Renews on"
                      variant="outlined"
                      value={formatTimestamp(subscriptionRenewal * 1000)}
                      type="text"
                      error={!!errors.subscriptionRenewal}
                      helperText={errors.subscriptionRenewal}
                      fullWidth
                      margin="normal"
                      disabled
                    />

                    <FormControlLabel
                      label="Automatic Renewal"
                      control={
                        <Switch
                          disabled
                          id="automaticRenewal"
                          checked={automaticRenewal}
                          onChange={handleChange}
                        />
                      }
                      sx={{ marginTop: "16px", marginBottom: "8px" }}
                    />

                    <Typography variant="body1">Payment</Typography>
                    <FormControl margin="normal" fullWidth>
                      <InputLabel id="method-label">Method</InputLabel>
                      <Select
                        size="small"
                        id="paymentService"
                        label="Method"
                        labelId="method-label"
                        name="paymentService"
                        value={paymentService}
                        onChange={async (event) => {
                          const newPaymentService = event.target.value;

                          if (newPaymentService === "None") {
                            await setFieldValue("overageLimitDollars", 0);
                            await setFieldValue("overageLimitCredits", 0);
                          }

                          setNewPaymentService(newPaymentService);

                          const newChangeType = calculateChangeType(
                            adminUserDetailsQuery.data.productId,
                            adminUserDetailsQuery.data.subscriptionCadence,
                            productId,
                            subscriptionCadence,
                            productIds,
                            setSubscriptionAction,
                            newPaymentService !== currentPaymentService
                          );
                          await setChangeType(newChangeType);

                          await setFieldValue(
                            "paymentService",
                            newPaymentService
                          );
                        }}
                        variant="outlined"
                        fullWidth
                      >
                        {productId === productIds.freemium &&
                          overageLimitDollars === 0 && (
                            <MenuItem key="None" value="None">
                              None
                            </MenuItem>
                          )}
                        {productId !== productIds.enterprise && (
                          <MenuItem key="Stripe" value="stripe">
                            Stripe
                          </MenuItem>
                        )}
                        {showMethodMYOB(
                          productId,
                          subscriptionCadence,
                          productIds
                        ) && (
                          <MenuItem key="MYOB" value="myob">
                            MYOB
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>

                    {paymentService === "stripe" &&
                      paymentMethod === "No payment method" && (
                        <Alert severity="error">
                          Unable to use `Stripe` as the method as this user does
                          not have a valid payment method attached to their
                          account.
                        </Alert>
                      )}

                    {subscriptionAction == UPGRADE && (
                      <FormControlLabel
                        label="Deduct pending overage from new balance"
                        control={
                          <Switch
                            id="chargeOverage"
                            checked={chargeOverage}
                            onChange={handleChange}
                          />
                        }
                        sx={{ marginTop: "16px", marginBottom: "8px" }}
                        disabled={isDisabledChargeOverage(overageUsageCredits)}
                      />
                    )}

                    {subscriptionAction == UPGRADE && chargeOverage && (
                      <>
                        <Alert severity="info">
                          When upgrading, overage will be added to the starting
                          credit usage for the new subscription.
                          {/* When
                          downgrading, overage will be charged to the invoice at
                          the end of the billing period. */}
                        </Alert>
                      </>
                    )}
                  </Card>

                  <Card sx={{ flexGrow: "1", flexBasis: "0", padding: "16px" }}>
                    <Typography variant="h5" sx={{ marginBottom: "32px" }}>
                      Usage
                    </Typography>
                    <Typography variant="body1">Credits</Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="creditAllowance"
                      label="Allowance"
                      variant="outlined"
                      value={creditAllowance}
                      onChange={handleChange}
                      type="number"
                      inputProps={{ min: 20_000, max: 500_000_000 }}
                      error={!!errors.creditAllowance}
                      helperText={errors.creditAllowance}
                      fullWidth
                      margin="normal"
                      disabled
                      // disabled={isDisabledAllowance(productId, productIds)}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="Final Allowance"
                      label="Final Allowance"
                      variant="outlined"
                      value={
                        changeType === DOWNGRADE ||
                        productId === productIds.enterprise ||
                        changeType === SAME
                          ? creditAllowance
                          : creditAllowance + Math.max(creditRemaining, 0)
                      }
                      onChange={handleChange}
                      type="number"
                      fullWidth
                      margin="normal"
                      disabled
                    />

                    {subscriptionAction == UPGRADE &&
                      chargeOverage &&
                      overageUsageCredits >=
                        creditAllowance + Math.max(creditRemaining, 0) && (
                        <Alert
                          sx={{ marginTop: "8px", marginBottom: "8px" }}
                          severity="error"
                        >
                          You will be adding{" "}
                          {overageUsageCredits.toLocaleString()} credits to the
                          starting balance of the new subscription which is
                          greater than their allowance. This plan may not be
                          appropriate.
                        </Alert>
                      )}

                    {chargeOverage &&
                      overageUsageCredits <
                        creditAllowance + Math.max(creditRemaining, 0) && (
                        <Alert
                          sx={{ marginTop: "8px", marginBottom: "8px" }}
                          severity="warning"
                        >
                          {overageUsageCredits.toLocaleString()} credits will be
                          added to the starting balance of the new subscription.
                        </Alert>
                      )}

                    <Typography variant="body1">Overage</Typography>
                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="overageLimitDollars"
                      label="Budget Dollars"
                      variant="outlined"
                      value={overageLimitDollars}
                      onChange={(event) => {
                        const newLimitDollars = parseInt(event.target.value);

                        setFieldValue("overageLimitDollars", newLimitDollars);
                        setFieldValue(
                          "overageLimitCredits",
                          (newLimitDollars / overageRate) * 1000
                        );
                      }}
                      onBlur={({ target }) => {
                        const newLimitDollars =
                          parseInt(target.value) -
                          (parseInt(target.value) % overageRate);

                        setFieldValue("overageLimitDollars", newLimitDollars);
                        setFieldValue(
                          "overageLimitCredits",
                          (newLimitDollars / overageRate) * 1000
                        );
                      }}
                      type="number"
                      inputProps={{ step: overageRate, min: 0, max: 10000 }}
                      error={!!errors.overageLimitDollars}
                      helperText={errors.overageLimitDollars}
                      fullWidth
                      margin="normal"
                      disabled={true}
                      // disabled={isDisabledBudgetDollars(
                      //   productId,
                      //   method,
                      //   productIds
                      // )}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="overageLimitCredits"
                      label="Budget Credits"
                      variant="outlined"
                      value={overageLimitCredits}
                      onChange={(event) => {
                        const newLimitCredits = parseInt(event.target.value);

                        setFieldValue("overageLimitCredits", newLimitCredits);
                        setFieldValue(
                          "overageLimitDollars",
                          (newLimitCredits / 1000) * overageRate
                        );
                      }}
                      onBlur={({ target }) => {
                        const newLimitCredits =
                          parseInt(target.value) -
                          (parseInt(target.value) % 1000);

                        setFieldValue("overageLimitCredits", newLimitCredits);
                        setFieldValue(
                          "overageLimitDollars",
                          (newLimitCredits / 1000) * overageRate
                        );
                      }}
                      type="number"
                      inputProps={{ step: 1000, min: 0, max: 1_000_000 }}
                      error={!!errors.overageLimitCredits}
                      helperText={errors.overageLimitCredits}
                      fullWidth
                      margin="normal"
                      disabled={true}
                      // disabled={isDisabledBudgetCredits(
                      //   productId,
                      //   method,
                      //   productIds
                      // )}
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="Pending Usage Dollars"
                      label="Pending Usage Dollars"
                      variant="outlined"
                      value={overageUsageDollars}
                      onChange={handleChange}
                      type="text"
                      error={!!errors.overageUsageDollars}
                      helperText={errors.overageUsageDollars}
                      fullWidth
                      margin="normal"
                      disabled
                    />

                    <TextField
                      size="small"
                      id="outlined-basic"
                      name="Pending Usage Credits"
                      label="Pending Usage Credits"
                      variant="outlined"
                      value={overageUsageCredits}
                      onChange={handleChange}
                      type="text"
                      error={!!errors.overageUsageCredits}
                      helperText={errors.overageUsageCredits}
                      fullWidth
                      margin="normal"
                      disabled
                    />
                  </Card>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </Box>
  );
};

export { AdminAccountSubscription };
