import Box from "@mui/material/Box";

import {
  OutputFormat,
  SetCheckedItems,
  setOutputFormat,
} from "pages/geoscape-batch/models";
import React from "react";

import { SxProps, Theme } from "@mui/material/styles";
import {
  DataGridPremium,
  GridRowOrderChangeParams,
  GridRowSpacingParams,
  gridClasses,
} from "@mui/x-data-grid-premium";
import CustomTable from "components/shared/table";
import { BatchConfigProps } from "pages/geoscape-batch/types";
import { buildColumns } from "./columns-row";
import "./style.css"; // Import your CSS file
import { Item, addIndex } from "./utils";

type Props = {
  props: BatchConfigProps;
  setCheckedItems: SetCheckedItems;
  enrichmentRows: OutputFormat[];
  setEnrichmentRows: setOutputFormat;
};

type PropsTable = {
  setCheckedItems: SetCheckedItems;
  enrichmentRows: OutputFormat[];
  setEnrichmentRows: setOutputFormat;
};

const MappingTableEnrichment = ({
  props,
  setCheckedItems,
  enrichmentRows,
  setEnrichmentRows,
}: Props) => {
  const headers: OutputFormat[] = enrichmentRows;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100%",
          marginTop: "16px",
        }}
      >
        {headers && (
          <DataTable
            enrichmentRows={enrichmentRows}
            setEnrichmentRows={setEnrichmentRows}
            setCheckedItems={setCheckedItems}
          />
        )}
      </Box>
    </>
  );
};

function DataTable({
  enrichmentRows,
  setEnrichmentRows,
  setCheckedItems,
}: PropsTable) {
  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 1 : 10,
      bottom: params.isLastVisible ? -1 : 10,
    };
  }, []);

  const editRowUpdate = (newRow: OutputFormat) => {
    const updatedRow = { ...newRow, isNew: false };

    setEnrichmentRows(
      enrichmentRows.map((row: OutputFormat) =>
        row.source === newRow.source ? updatedRow : row
      )
    );
    return updatedRow;
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setLoading(true);
    await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      enrichmentRows
    );

    setLoading(false);
  };

  const [loading, setLoading] = React.useState(false);

  function updateRowPosition(
    initialIndex: number,
    newIndex: number,
    rows: Array<Item>
  ): Promise<any> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const rowsClone = [...rows];
        const row = rowsClone.splice(initialIndex, 1)[0];
        rowsClone.splice(newIndex, 0, row);
        setEnrichmentRows(addIndex(rowsClone));
        resolve(rowsClone);
      }, Math.random() * 500 + 100); // update setEnrichmentRows latency
    });
  }

  const columns = buildColumns({
    enrichmentRows,
    setEnrichmentRows,
    setCheckedItems,
  });

  function getRowId(row: OutputFormat) {
    return row.source;
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <>
        <CustomTable
          rows={enrichmentRows}
          cols={columns}
          isLoading={loading}
          rowReordering={true}
          getRowId={getRowId}
          editMode="row"
          onRowOrderChange={handleRowOrderChange}
          processRowUpdate={editRowUpdate}
          getRowSpacing={getRowSpacing}
          rowHeight={80}
          pagination={false}
          overlayText={""}
          customStyle={customStyle}
        />
      </>
    </div>
  );
}

const customStyle: SxProps<Theme> = {
  border: "none",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  alignItems: "center",

  [`& .${gridClasses.cell}`]: {
    border: "none",
  },

  [`& .MuiDataGrid-virtualScroller`]: {
    paddingBottom: "15px",
  },
  [`& .MuiDataGrid-main`]: {},
  [`& .MuiDataGrid-footerContainer`]: {
    width: "100%",
  },
  [`& .${gridClasses.columnHeader}`]: {
    display: "none",
  },

  [`& .${gridClasses.rowReorderCellContainer}`]: {
    display: "flex",
    width: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",

    borderRadius: "4px 0px 0px 4px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderLeft: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    background: "rgba(0, 0, 0, 0.00)",
  },
  ["& .super-app-theme--cell-0"]: {
    display: "flex",
    padding: "8px 10px",
    alignItems: "center",
    alignSelf: "stretch",

    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderRight: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    background: "rgba(0, 0, 0, 0.00)",
  },

  ["& .super-app-theme--cell"]: {
    display: "flex",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",

    borderRadius: "0px 4px 4px 0px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderLeft: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
  },

  ["& .super-app-theme--cell-2"]: {
    display: "flex",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",

    borderRadius: "0px 4px 4px 0px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderRight: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
  },

  ["& .super-app-theme--cell-3"]: {
    display: "flex",
    paddingLeft: "8px",
    alignItems: "center",
    alignSelf: "stretch",
    border: "none",
  },

  [`& .${gridClasses.row}`]: {},
};
export default MappingTableEnrichment;
