import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Icon,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import BboxSvg from "../../icons/bbox.svg?react";
import CircleSvg from "../../icons/circle.svg?react";
import PolygonSvg from "../../icons/polygon.svg?react";
import MapboxDraw, {
  DrawCreateEvent,
  DrawModeChangeEvent,
  DrawSelectionChangeEvent,
  DrawUpdateEvent,
} from "@mapbox/mapbox-gl-draw";
import { BoundaryToolProps } from "./define-boundary";

export const DrawComponent = (
  props: ClipConfigProps & ClipStepsControlProps & BoundaryToolProps
) => {
  const classes = useStyles();

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  return (
    <Box display="flex" flexDirection="column" gap={2} height="100%">
      <Typography variant="subtitle1" color="text.secondary">
        Use the drawing tools below to draw a boundary
      </Typography>
      <Box display="flex" flexDirection="row" gap={1}>
        <Card
          className={
            props.currentMode === "draw_rectangle"
              ? classes.selectedDrawType
              : classes.drawTypes
          }
          variant="outlined"
        >
          <CardActionArea
            onClick={() => {
              props.draw.changeMode("draw_rectangle");
              props.setCurrentMode("draw_rectangle");
            }}
          >
            <CardContent className={classes.drawTypes}>
              <Icon sx={{ textAlign: "center" }}>
                <BboxSvg fill={isDark ? "white" : "black"} />
              </Icon>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          className={
            props.currentMode === "draw_polygon"
              ? classes.selectedDrawType
              : classes.drawTypes
          }
          variant="outlined"
        >
          <CardActionArea
            onClick={() => {
              props.draw.changeMode("draw_polygon");
              props.setCurrentMode("draw_polygon");
            }}
          >
            <CardContent className={classes.drawTypes}>
              <Icon sx={{ textAlign: "center" }}>
                <PolygonSvg fill={isDark ? "white" : "black"} />
              </Icon>
            </CardContent>
          </CardActionArea>
        </Card>
        <Card
          className={
            props.currentMode === "draw_circle"
              ? classes.selectedDrawType
              : classes.drawTypes
          }
          variant="outlined"
        >
          <CardActionArea
            onClick={() => {
              props.draw.changeMode("draw_circle");
              props.setCurrentMode("draw_circle");
            }}
          >
            <CardContent className={classes.drawTypes}>
              <Icon sx={{ textAlign: "center" }}>
                <CircleSvg fill={isDark ? "white" : "black"} />
              </Icon>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  drawTypes: {
    width: "40px",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  selectedDrawType: {
    width: "40px",
    height: "40px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.action.focus,
  },
}));
