import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import {
  BatchConfigProps,
  StepperControlProps,
} from "pages/geoscape-batch/types";
import MappingTableEnrichment from "./mapping-table-enrichment";
import MappingTableInput from "./mapping-table-input";
import { useIdentity, getActiveOrganisation } from "contexts/identity-context";
import { fetchOverage, ReactQueryQueryError } from "data/queries";
import { resolveIdentityId } from "utils/identity";
import * as QueryKeys from "data";
import { useQuery, useQueryClient } from "react-query";
import { fetchBatchesAdditionalProperties } from "data/queries";
import { LoadingButton } from "@mui/lab";
import { CheckedItems, OutputFormat } from "pages/geoscape-batch/models";
import {
  batchOutputGeocode,
  batchOutputReverseGeocode,
} from "pages/geoscape-batch/utils";
import { useEffect, useState } from "react";
import { Md5 } from "ts-md5";
import { AccordionComponent } from "./accordion";
import { addIndex } from "./utils";

interface DefineOutputFileMappingsProps {}

const mapAdditionalProperties: { [key: string]: string } = {
  localGovernmentArea: "localGovernmentArea",
  asgsMain2011: "asgsMain",
  asgsMain2016: "asgsMain",
  asgsMain2021: "asgsMain",
  asgsRemoteness2016: "asgsRemoteness",
  asgsRemoteness2011: "asgsRemoteness",
  stateElectorate: "stateElectorate",
  solarPanelBundle: "solarPanelBundle",
  asgsRemoteness2021: "asgsRemoteness",
  seifa: "seifa",
  commonwealthElectorate: "commonwealthElectorate",
  buildings: "buildings",
};

export const DefineOutputFileMappings = (
  props: DefineOutputFileMappingsProps & BatchConfigProps & StepperControlProps
) => {
  const allCheckedItems = props.batchConfig.hubInfo?.checkedItems;
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);
  const queryClient = useQueryClient();

  const inPutParameters = props.batchConfig.hubInfo.csvHeaders;

  const [loading, setLoading] = useState(false);

  const outPutEnrichment: OutputFormat[] =
    props.batchConfig.output?.format || [];

  const definitionsQuery = useQuery<any>(
    [QueryKeys.batchesAdditionalPropertiesKey, identityId],
    () => fetchBatchesAdditionalProperties(identityState)
  );

  const isLoading = definitionsQuery.isLoading;

  const [checkedItems, setCheckedItems] = useState<CheckedItems>({});

  const [enrichmentRows, setEnrichmentRows] = useState<OutputFormat[]>([]);

  useEffect(() => {
    setCheckedItems(props.batchConfig.hubInfo.checkedItems);

    setEnrichmentRows(
      addIndex(
        props.batchConfig.output.format.length > 0
          ? props.batchConfig.output.format
          : props.batchConfig.type == "addressGeocoder"
          ? batchOutputGeocode.output
          : batchOutputReverseGeocode.output
      ) || []
    );
  }, []);

  const updateBatchConfig = () => {
    const additionalProperties = [];

    for (const [key, value] of Object.entries(checkedItems)) {
      if (Object.values(value).some(Boolean)) {
        const propertyName = mapAdditionalProperties[key];
        if (propertyName) {
          additionalProperties.push(propertyName);
        }
      }
    }

    const batchConfigHash = Md5.hashStr(
      JSON.stringify(props.batchConfig.output.format) +
        JSON.stringify(props.batchConfig.hubInfo.checkedItems)
    );

    const newConfig = {
      ...props.batchConfig,
      output: {
        ...props.batchConfig.output,
        format: enrichmentRows,
      },
      hubInfo: {
        ...props.batchConfig.hubInfo,
        checkedItems: checkedItems,
        batchConfigHashOutput: batchConfigHash,
      },
      parameters: {
        ...props.batchConfig.parameters,
        additionalProperties: additionalProperties,
      },
    };

    props.setBatchConfig(newConfig);

    return newConfig;
  };

  // Pre cache for step 5
  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage(isUser, identityState)
  );

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "16px",
        }}
      >
        {definitionsQuery.isError && (
          <Alert severity="error">
            <AlertTitle>
              There was an error fetching the batch definitions.
            </AlertTitle>
            {(definitionsQuery.error as ReactQueryQueryError).message}
          </Alert>
        )}

        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "654px",
          }}
        >
          <CardHeader
            variant="h1"
            title="Output - File Mapping"
            subheader="Review the arrangement of your processed data. The 'Column Name'
        displays your file's column names and Attribute displays the attribute
        name from the request and response."
          />

          {enrichmentRows && (
            <CardContent>
              <Typography variant="h6">From Input File</Typography>
              <MappingTableInput inPutParameters={inPutParameters} />
              <Typography variant="h6">Enrichment</Typography>
              <MappingTableEnrichment
                props={props}
                setCheckedItems={setCheckedItems}
                enrichmentRows={enrichmentRows}
                setEnrichmentRows={setEnrichmentRows}
              />
            </CardContent>
          )}
        </Card>

        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "530px",
            height: "100%",
          }}
        >
          <CardHeader
            variant="h1"
            title="Enrichment"
            subheader="Some info here about additional properties"
            sx={{ marginBottom: 0 }}
          />
          {/* Additional Properties */}
          {isLoading && (
            <>
              <Box padding={2}>
                <Skeleton variant="rectangular" height={247} />
              </Box>
            </>
          )}
          {definitionsQuery?.data &&
            Object.keys(definitionsQuery.data).map((key) => {
              const isAddressGeocoderAndRelevantKey =
                props.batchConfig.type === "addressGeocoder" &&
                (key === "matchingMetadataReverseGeocode" ||
                  key === "queryMetadataReverseGeocode");

              const isReverseGeocoderAndRelevantKey =
                props.batchConfig.type === "reverseGeocoder" &&
                (key === "matchingMetadataGeocode" ||
                  key === "queryMetadataGeocode");

              if (
                isAddressGeocoderAndRelevantKey ||
                isReverseGeocoderAndRelevantKey
              ) {
                return <></>;
              }

              return (
                <AccordionComponent
                  key={key}
                  batchConfig={props.batchConfig}
                  outPutFormatDefinition={definitionsQuery.data[key]}
                  propertyCheckedItems={allCheckedItems[key]}
                  title={key}
                  setBatchConfig={props.setBatchConfig}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                  enrichmentRows={enrichmentRows}
                  setEnrichmentRows={setEnrichmentRows}
                />
              );
            })}
        </Card>
      </Box>

      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          width: "100%",
          padding: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: "16px",
          }}
        >
          <Button
            onClick={() =>
              props.setActiveStep(Math.max(props.activeStep - 1, 0))
            }
          >
            Back
          </Button>

          <LoadingButton
            variant="contained"
            onClick={() => {
              // Remove `batchesQuotedBatchConfig` to prevent funny behaviour when moving back and forth between steps 4/5
              queryClient.removeQueries([
                QueryKeys.batchesQuotedBatchConfig,
                identityId,
              ]);

              const newConfig = updateBatchConfig();

              props.saveBatchConfig(
                newConfig,
                Math.min(props.activeStep + 1, props.maxSteps)
              );
            }}
            loading={props.isSaveBatchConfigLoading}
          >
            Continue
          </LoadingButton>
        </Box>
      </Card>
    </>
  );
};
