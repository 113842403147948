import { Box, IconButton, Paper, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { PredictiveAddressSearch } from "components/organisations/pav-address-input";
import { Form, Formik } from "formik";
import { useQuery } from "react-query";
import * as QueryKeys from "data";
import { fetchAddressById } from "data/queries";
import { makeStyles } from "@mui/styles";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

interface AddressSearchProps {
  onSelectAddress: ({
    longitude,
    latitude,
    geocoder_attributes,
  }: {
    longitude: number;
    latitude: number;
    geocoder_attributes: { [key: string]: string };
  }) => void;
  onClearAddress: () => void;
}

export const AddressSearch = (props: AddressSearchProps) => {
  const [expanded, setExpanded] = useState(false);
  const [addressId, setAddressId] = useState("");
  const classes = useStyles();

  const { enqueueQueryFailed, enqueueMutationFailed } = useCustomSnackbars();

  const addressByIdQuery = useQuery(
    [QueryKeys.addressById, addressId],
    () => fetchAddressById(addressId),
    {
      enabled: !!addressId,
      onSuccess: (addressDetails: any) => {
        setAddressId("");
        props.onSelectAddress({
          longitude: addressDetails?.address?.geometry?.coordinates[0],
          latitude: addressDetails?.address?.geometry?.coordinates[1],
          geocoder_attributes: addressDetails?.address?.properties,
        });
      },
      onError: (error: Error) => {
        enqueueQueryFailed(error.toString());
      },
    }
  );

  return (
    <Paper className={expanded ? classes.expanded : classes.minimized}>
      <IconButton aria-label="search" onClick={() => setExpanded(!expanded)}>
        <SearchIcon />
      </IconButton>
      {expanded && (
        <Formik
          initialValues={{
            address: "",
          }}
          onSubmit={async (values) => {}}
        >
          {({
            values: { address },
            setFieldValue,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => {
            return (
              <>
                <Form
                  style={{
                    width: "340px",
                    alignSelf: "center",
                    marginBottom: "7px",
                    marginRight: "7px",
                  }}
                >
                  <PredictiveAddressSearch
                    readOnly={false}
                    disabled={false}
                    textFieldStyle={{
                      "& .MuiInputBase-root": {
                        height: "32px",
                      },
                      "& .MuiInputBase-input": {
                        height: "15px",
                      },
                    }}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    setFieldValue={(field: string, value: string) => {
                      if (!value) {
                        props.onClearAddress();
                        return;
                      }
                      if (field === "addressId") {
                        setAddressId(value);
                      }
                    }}
                    id="address"
                    size="small"
                    name="address"
                    label=""
                    value={address}
                    error={
                      address !== null &&
                      touched.address &&
                      Boolean(errors.address)
                    }
                    helperText={touched.address && errors.address}
                    showManualAddressLabel={false}
                    onClearAddress={props.onClearAddress}
                  />
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </Paper>
  );
};

const useStyles = makeStyles({
  expanded: {
    height: "40px",
    width: "288px",
    alignItems: "center",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  minimized: {
    width: "40px",
    height: "40px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
});
