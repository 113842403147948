import { Button } from "@mui/material";
import { useIntercom } from "react-use-intercom";

const SupportButton = () => {
  const { show } = useIntercom();

  return (
    <Button sx={{ color: "white" }} onClick={() => show()}>
      Contact Us
    </Button>
  );
};

export { SupportButton };
