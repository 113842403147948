import * as Routes from "routes";

import { ReactQueryQueryError } from "data/queries";

import { useSnackbar, SnackbarKey } from "notistack";
import { SupportButton } from "components/snackbars/SupportButton";
import { CloseButton } from "components/snackbars/CloseButton";
import { Box } from "@mui/material";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

const buildDashboardCardRenewal = (
  subscriptionQuery: any,
  isLoading: boolean,
  history: any
) => {
  return {
    title: "Subscription Renews On",
    content: new Date(
      subscriptionQuery.isSuccess
        ? subscriptionQuery.data.activeSubscription.renewalDate * 1000
        : ""
    ).toDateString(),
    actionName: "View Reciepts",
    actionFunc: () => history.push(Routes.billing),
    isLoading: isLoading,
    isError: subscriptionQuery.isError,
    message: subscriptionQuery.isError
      ? (subscriptionQuery.error as ReactQueryQueryError).message
      : "",
  };
};

const buildDashboardCardPaymentMethod = (
  paymentMethodQuery: any,
  isUserPage: boolean,
  isLoading: boolean,
  isEnterprise: boolean,
  isPaymentMyob: boolean,
  history: any
) => {
  const dashboardCardPaymentMethod = {
    title: "Card on File",
    content: `**** **** **** ${paymentMethodQuery.data?.last4}`,
    actionName: "UPDATE CARD",
    actionFunc: () =>
      history.push(
        isUserPage ? Routes.userBillingDetails : Routes.orgBillingDetails
      ),
    isLoading: isLoading,
    isError: paymentMethodQuery.isError,
    message: "",
    isPaymentMyob: isPaymentMyob,
    isEnterprise: isEnterprise,
  };

  if (isPaymentMyob) {
    dashboardCardPaymentMethod.title = "";
    dashboardCardPaymentMethod.content = "";
    dashboardCardPaymentMethod.actionName = "";
    dashboardCardPaymentMethod.message = isEnterprise
      ? "Payment Method managed as part of your Enterprise Subscription"
      : "Payment Method managed as part of your Profesional Subscription";
  } else if (paymentMethodQuery.isSuccess && paymentMethodQuery.data == null) {
    dashboardCardPaymentMethod.title = "";
    dashboardCardPaymentMethod.content = "";
    dashboardCardPaymentMethod.isError = true;
    dashboardCardPaymentMethod.message = "No payment method";
  }

  return dashboardCardPaymentMethod;
};

export function abbreviateNumber(allowance: number): string {
  if (allowance < 1000000) {
    return `${(allowance / 1000).toFixed(1).replace(/\.0$/, "") + "K"}`;
  }
  return `${(allowance / 1000000).toFixed(1).replace(/\.0$/, "") + "M"}`;
}

const buildDashboardCardUsage = (
  billingUsageQuery: any,
  creditAmount: number,
  isLoading: boolean,
  history: any
) => {
  return {
    title: "Credit Usage Sum",
    content:
      Number(billingUsageQuery.data?.creditsUsedTotal).toFixed(1) +
      ` / ${abbreviateNumber(creditAmount)}`,
    actionName: "View Usage",
    actionFunc: () => history.push(Routes.developerUsage),
    isLoading: isLoading,
    isError: billingUsageQuery.isError,
    message: billingUsageQuery.isError
      ? (billingUsageQuery.error as ReactQueryQueryError).message
      : "",
  };
};

const buildDashboardCardSubscription = (
  subscriptionQuery: any,
  history: any,
  isLoading: boolean,
  isUser: boolean,
  isEnterprise: boolean,
  isPaymentMyob: boolean,
  isDashboard: boolean = false,
  
) => {
  const { enqueueSupportSnackbar } = useCustomSnackbars();

  return {
    title: "Subscription",
    content: subscriptionQuery.data
      ? subscriptionQuery.data?.activeSubscription.plan.name
      : "",
    actionName: "Manage Subscription",
    actionFunc: () => {
      if (isEnterprise  || isPaymentMyob ) {
        enqueueSupportSnackbar(
          "To manage your subscription, please contact support."
        );
      } else {
        history.push(
          isDashboard
            ? isUser
              ? Routes.userSubscription
              : Routes.orgSubscription
            : isUser
            ? Routes.userManageSubscription
            : Routes.orgManageSubscription
        );
      }
    },
    isLoading: isLoading,
    isError: subscriptionQuery.isError,
    message: subscriptionQuery.isError
      ? (subscriptionQuery.error as ReactQueryQueryError).message
      : "",
  };
};

const buildDashboardCardOverage = (
  billingUsageQuery: any,
  history: any,
  isLoading: boolean,
  isUser: boolean,
  isEnterprise: boolean
) => {
  const { enqueueSupportSnackbar } = useCustomSnackbars();

  return {
    title: "Current Overage Spend",
    content:
      "$" +
      (billingUsageQuery.data ? billingUsageQuery.data?.overageDollars : ""),
    actionName: "Configure Overage",
    actionFunc: () => {
      if (isEnterprise) {
        enqueueSupportSnackbar(
          "To manage your subscription, please contact support."
        );
      } else {
        history.push(isUser ? Routes.userOverage : Routes.orgOverage);
      }
    },
    isLoading: isLoading,
    isError: billingUsageQuery.isError,
    message: billingUsageQuery.isError
      ? (billingUsageQuery.error as ReactQueryQueryError).message
      : "",
  };
};

export {
  buildDashboardCardRenewal,
  buildDashboardCardPaymentMethod,
  buildDashboardCardUsage,
  buildDashboardCardSubscription,
  buildDashboardCardOverage,
};
