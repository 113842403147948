import Crop32Icon from "@mui/icons-material/Crop32";
import ImageIcon from "@mui/icons-material/Image";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PolylineIcon from "@mui/icons-material/Polyline";
import TableViewIcon from "@mui/icons-material/TableView";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Link,
  Typography,
} from "@mui/material";
import { ShowMore } from "components/shared/show-more";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import * as Routes from "routes";
import { productCodeToExplorerLayerIdMappings } from "../constants";
import { viewDatasetInExplorer } from "./catalogue-page";

interface CatalogueCardProps {
  productId: string;
  displayProduct: string;
  totalFeatureCount: number;
  shortDescription?: string;
  updatedDate?: string;
  image: string;
  dataType: string[];
}

export const CatalogueCard = (props: CatalogueCardProps) => {
  // Substitute GEOSCAPE PLANNING INSIGHTS for GEOSCAPE PLANNING INSIGHTS - BASE
  const datasetId =
    props.productId == "GEOSCAPE PLANNING INSIGHTS"
      ? "GEOSCAPE PLANNING INSIGHTS - BASE"
      : props.productId;

  const history = useHistory();

  return (
    <Card
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <CardActionArea
        href={`${Routes.dataCatalogue}/${props.productId}`}
        LinkComponent={Link}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardMedia
          sx={{ height: 250, width: 250 }}
          component="img"
          src={props.image}
          title={props.displayProduct}
        />
        <Chip
          label={`${Intl.NumberFormat("en", { notation: "compact" }).format(
            props.totalFeatureCount
          )} ${humanizeFeatures(props.dataType[0])}`}
          size="medium"
          sx={{
            position: "absolute",
            top: "16px",
            left: "16px",
            width: "27%",
            textAlign: "center",
          }}
          avatar={<Avatar>{getFeatureIcon(props.dataType[0])}</Avatar>}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <CardHeader
            sx={{
              alignSelf: "flex-start",
              padding: 0,
              paddingLeft: 2,
              flexGrow: 1,
            }}
            title={
              <ShowMore lines={1} showButton={false}>
                {props.displayProduct}
              </ShowMore>
            }
          ></CardHeader>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
            >
              <Box sx={{ width: "150px", display: "flex", gap: "8px" }}>
                {props.dataType.map((type) => (
                  <Chip key={type} label={type} color="primary" size="small" />
                ))}
              </Box>
              <Typography variant="body2">
                {props.updatedDate
                  ? `Updated: ${dayjs(props.updatedDate).format("MMM YYYY")}`
                  : ""}
              </Typography>
            </Box>
            <ShowMore lines={3} showButton={false}>
              <Typography variant="body2">{props.shortDescription}</Typography>
            </ShowMore>
          </CardContent>
          <CardActions
            sx={{
              gap: 1,
            }}
          >
            <Button
              size="small"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                viewDatasetInExplorer(datasetId, history);
              }}
              disabled={!productCodeToExplorerLayerIdMappings.get(datasetId)}
            >
              View in explorer
            </Button>
            <Button
              size="small"
              color="primary"
              // component={Link}
              LinkComponent={Link}
              href={`${Routes.dataDownload}?product=${datasetId}`}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              Downloads
            </Button>
          </CardActions>
        </Box>
      </CardActionArea>
    </Card>
  );
};

const getFeatureIcon = (dataType: string) => {
  switch (dataType) {
    case "Point":
      return <LocationOnIcon fontSize="small" sx={{ color: "white" }} />;

    case "Polygon":
      return <Crop32Icon fontSize="small" sx={{ color: "white" }} />;

    case "Multi Polygon":
      return <Crop32Icon fontSize="small" sx={{ color: "white" }} />;

    case "Line":
      return <PolylineIcon fontSize="small" sx={{ color: "white" }} />;

    case "Multi Line":
      return <PolylineIcon fontSize="small" sx={{ color: "white" }} />;

    case "Raster":
      return <ImageIcon fontSize="small" sx={{ color: "white" }} />;

    case "Table":
      return <TableViewIcon fontSize="small" sx={{ color: "white" }} />;

    default:
      return <LocationOnIcon fontSize="small" sx={{ color: "white" }} />;
  }
};

const humanizeFeatures = (dataType: string) => {
  switch (dataType) {
    case "Point":
      return "Features";

    case "Polygon":
      return "Features";

    case "Multi Polygon":
      return "Features";

    case "Line":
      return "Features";

    case "Multi Line":
      return "Features";

    case "Raster":
      return "Sq KM";

    case "Table":
      return "Rows";

    default:
      return "Features";
  }
};
