import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormHelperText,
  Link,
  SxProps,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import Alert, { AlertColor } from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { BaseState } from "common/models";
import { signUpUser } from "contexts/identity-context";
import { Form, Formik } from "formik";
import { replace } from "lodash/fp";
import { PostHog } from "posthog-js";
import { usePostHog } from "posthog-js/react";
import React, { createRef, Dispatch, SetStateAction, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import * as Routes from "routes";
import { boolean as bool, object, ref, string } from "yup";
import "./style.css";

export const signUpValidationSchema = object({
  email: string().email("Enter a valid email").required("Email is required"),
  firstName: string().required("First Name is required."),
  lastName: string().required("Last Name is required."),
  password: string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required."),
  confirmPassword: string()
    .oneOf([ref("password"), null], "Passwords must match")
    .required("Confirm Password is required."),
  termsOfUse: bool().oneOf(
    [true],
    "In order to continue, you must accept Geoscape Hub's Terms of Use."
  ),
});

type SignUpValues = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
  termsOfUse: boolean;
};

interface SignUpState extends BaseState {
  messageSeverity?: AlertColor;
}

function trimmessage(message: string): string {
  return replace(
    "..",
    ".",
    replace("PreSignUp failed with error ", "", message)
  );
}

async function signUp(
  event: SignUpValues,
  recaptchaToken: string,
  setState: Dispatch<SetStateAction<SignUpState>>,
  posthog: PostHog
) {
  if (!recaptchaToken) {
    setState((prevState) => {
      return {
        ...prevState,
        isError: true,
        message: "Please complete the reCaptcha challenge.",
        messageSeverity: "error",
      };
    });
    return;
  }
  setState((prevState) => {
    return {
      ...prevState,
      isLoading: true,
    };
  });
  try {
    const confirm = await signUpUser(
      event.email,
      event.firstName,
      event.lastName,
      event.password,
      recaptchaToken
    );
    switch (confirm.state) {
      case "left": {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            message: `A verification email has been sent to: ${event.email}`,
            messageSeverity: "success",
          };
        });
        posthog.capture("user_signed_up");
        break;
      }
      case "right": {
        setState((prevState) => {
          return {
            ...prevState,
            isLoading: false,
            message: trimmessage(confirm.value.message),
            messageSeverity: "error",
          };
        });
        break;
      }
    }
  } finally {
    // just make sure that we've unlocked the page at the very least by setting isLoading to false
    setState((prevState) => {
      return {
        ...prevState,
        isLoading: false,
      };
    });
  }
}

export const SignUp = () => {
  const initialState = {
    isLoading: false,
    isError: false,
    message: "",
  };
  const [state, setState] = useState<SignUpState>(initialState);
  const [recaptchaToken, setRecaptchaToken] = useState<string>("");
  const posthog = usePostHog();

  const recaptchaRef = createRef<ReCAPTCHA>();
  const recaptchaKey: string = import.meta.env.VITE_RECAPTCHA_KEY || "EMPTY";

  const handleVerify = () => {
    const token = recaptchaRef?.current?.getValue();
    setRecaptchaToken(token || "");
    recaptchaRef?.current?.reset();
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik<SignUpValues>
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          password: "",
          confirmPassword: "",
          termsOfUse: false,
        }}
        onSubmit={async (e) => {
          signUp(e, recaptchaToken, setState, posthog);
        }}
        validationSchema={signUpValidationSchema}
      >
        {({
          values: {
            email,
            firstName,
            lastName,
            password,
            confirmPassword,
            termsOfUse,
          },
          handleChange,
          handleBlur,
          errors,
          touched,
        }) => (
          <Form style={{ paddingBottom: "50px" }}>
            <Card
              style={{
                borderRadius: "32px 32px 32px 32px",
                width: "410px",
                maxWidth: "500px",

                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <CardHeader
                sx={{
                  padding: "32px 32px 0px 32px",
                }}
                titleTypographyProps={{ variant: "h5" }}
                title="Create your Geoscape Hub account"
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "var(--4,32px) !important",
                  alignItems: "flex-start",
                  gap: "16px",
                  alignSelf: "stretch",
                }}
              >
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  type="text"
                  value={email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  disabled={state.isLoading}
                  sx={customStyle}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                  fullWidth
                />
                <TextField
                  id="firstName"
                  name="firstName"
                  label="First Name"
                  type="text"
                  value={firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  disabled={state.isLoading}
                  fullWidth
                  sx={customStyle}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
                <TextField
                  id="lastName"
                  name="lastName"
                  label="Last Name"
                  type="text"
                  value={lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  disabled={state.isLoading}
                  fullWidth
                  sx={customStyle}
                  InputProps={{
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  disabled={state.isLoading}
                  // variant="filled"
                  fullWidth
                  sx={customStyle}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
                <TextField
                  id="confirmPassword"
                  name="confirmPassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.confirmPassword && Boolean(errors.confirmPassword)
                  }
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  disabled={state.isLoading}
                  // variant="filled"
                  fullWidth
                  sx={customStyle}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                    sx: {
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    },
                  }}
                />
                <FormControlLabel
                  sx={{
                    display: "flex",
                    height: "38px",
                    margin: "0px",
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      id="termsOfUse"
                      name="termsOfUse"
                      checked={termsOfUse}
                      onChange={handleChange}
                    />
                  }
                  label={
                    <>
                      {"I agree to Geoscape Hub's "}
                      <a
                        href="https://geoscape.com.au/legal/geoscape-developer-terms"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms of Use
                      </a>
                    </>
                  }
                />
                <FormHelperText error={true}>
                  {errors["termsOfUse"]}
                </FormHelperText>
                <Box width="100%" display="flex" justifyContent="center">
                  <ReCAPTCHA
                    id="recaptcha"
                    ref={recaptchaRef}
                    sitekey={recaptchaKey}
                    onChange={handleVerify}
                    theme={isDark ? "dark" : "light"}
                  />
                </Box>
                {state.isLoading ? (
                  <Box width="100%" display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <Box
                      hidden={
                        !Boolean(state.message) &&
                        !Boolean(state.messageSeverity)
                      }
                      marginBottom={2}
                      marginTop={2}
                      style={{ width: "100%" }}
                    >
                      <Alert
                        variant="outlined"
                        severity={state.messageSeverity}
                        sx={{
                          borderRadius: "8px",
                        }}
                      >
                        {state.message}
                      </Alert>
                    </Box>

                    <Button
                      variant="contained"
                      type="submit"
                      disabled={state.isLoading}
                      fullWidth
                      sx={{ borderRadius: "8px", height: "36px" }}
                      children="Sign Up"
                    />

                    <Button
                      LinkComponent={Link}
                      disabled={state.isLoading}
                      size="small"
                      fullWidth
                      href={Routes.signIn}
                    >
                      Sign In
                    </Button>
                  </>
                )}
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

const customStyle: SxProps<Theme> = {
  [`& .MuiInputLabel-root `]: {
    top: "-5px",
  },

  [`& .MuiInputLabel-shrink `]: {
    top: "0px",
  },
};
