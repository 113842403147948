import { Box, Card, CardContent, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { UserInvitations } from "components/settings/user/pending-invitations/user-invitations";
import { Route } from "react-router";
import * as Routes from "routes";

import { CreateOrganisation } from "./create-organisation";

export const Organisations = () => {
  return (
    <Box>
      <Route exact path={Routes.userCreateOrganisation}>
        <Grid spacing={2} container paddingY={4}>
          <Grid item md={6}>
            <Card>
              <CardContent>
                <Typography component="p" variant="h5" textAlign="left">
                  New Org Details
                </Typography>
                <Typography
                  variant="body2"
                  textAlign="left"
                  color="text.secondary"
                >
                  Details of the org being created
                </Typography>
                <CreateOrganisation />
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}></Grid>
        </Grid>
      </Route>
    </Box>
  );
};
