import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { styled, useTheme } from "@mui/material/styles";
import { DynamicBreadcrumbsMenu } from "components/breadcrumbs/dynamic-breadcrumbs";
import FadeMenu from "components/navigation/menu-bar";
import { getMenuItems } from "components/navigation/utils-side-bar";
import {
  User,
  getActiveOrganisation,
  isAdminIdentity,
  useIdentity,
} from "contexts/identity-context";
import * as QueryKeys from "data";
import {
  fetchApiKeys,
  fetchApiProducts,
  fetchBillingUsage,
  fetchDefinitions,
  fetchOverage,
  fetchPaymentMethod,
  fetchSubscription,
} from "data/queries";
import {
  ChevronLeft,
  ChevronRight,
  Contact,
  LifeBuoy,
  Scale,
} from "lucide-react";
import { usePostHog } from "posthog-js/react";
import * as React from "react";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router";
import { RouteSwitch } from "routes/route-switch";
import { resolveIdentityId } from "utils/identity";
import * as Routes from "../../routes";
import { Intercom } from "./boot-intercom";
import {
  DrawerSideBar,
  DrawerSideBarFooter,
  DrawerSideBarHeader,
  RenderMenu,
} from "./side-bar";

export const SignedIn = () => {
  const [identityState] = useIdentity();

  const org = getActiveOrganisation(identityState);
  const isUser = !org;
  const identityId = resolveIdentityId(identityState, isUser);
  const posthog = usePostHog();

  useEffect(() => {
    const user = identityState as User;
    posthog?.identify(user.cognitoSub, {
      email: user.email,
    });

    if (!isUser) {
      posthog.group("organisation", org.id, { orgName: org.name });
    }
  }, [identityState]);

  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.prefetchQuery([QueryKeys.definitionsKey], () =>
      fetchDefinitions(identityState)
    );
    queryClient.prefetchQuery([QueryKeys.apiKeys, identityId], () =>
      fetchApiKeys(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.apiProducts], () =>
      fetchApiProducts(identityState)
    );
    queryClient.prefetchQuery([QueryKeys.subscription, identityId], () =>
      fetchSubscription(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.billingUsage, identityId], () =>
      fetchBillingUsage(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.paymentMethod, identityId], () =>
      fetchPaymentMethod(isUser, identityState)
    );
    queryClient.prefetchQuery([QueryKeys.overage, identityId], () =>
      fetchOverage(isUser, identityState)
    );
  }, [identityId]);

  /**
   * App in a signed out state.
   * The "Geo-Sidebar" with our menu items on the left.
   * On the right, a paper with the context for whatever route we're on
   */

  return (
    <>
      <Intercom />
      {/* MAIN BOX */}
      <Box
        style={{
          display: "block",
          width: "100vw",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <MainLayout />
      </Box>
    </>
  );
};

export default function MainLayout() {
  // This component divied the layout in two LEFT - RIGHT
  const theme = useTheme();
  // const [open, setOpen] = React.useState(true);
  const [identityState] = useIdentity();

  const storedValue = localStorage.getItem("openMenu");
  const openbMenu = storedValue !== null ? JSON.parse(storedValue) : true;

  const [open, setOpen] = React.useState<boolean>(openbMenu);

  React.useEffect(() => {
    localStorage.setItem("openMenu", JSON.stringify(open));
  }, [open]);

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  const currentRoute = useLocation().pathname;

  const isUser = !getActiveOrganisation(identityState);
  const isLight = theme.palette.mode === "light";

  const sideMenu = getMenuItems(isUser).filter((item, index) => {
    if (item.adminOnly) {
      return isAdminIdentity(identityState);
    }
    return true;
  });

  return (
    <Box
      sx={{ display: "flex" }}
      style={{
        // display: "block",
        width: "100%",
        height: "100%",
        // backgroundColor: "red",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <CssBaseline />

      {/* LEFT CONTAINER - SIDEBAR */}
      <DrawerSideBar
        className="left-container-sidebar"
        variant="permanent"
        open={open}
      >
        {/* HEADER - DISPLAY ACCOUNT/USER NAME - MENU */}
        <DrawerSideBarHeader className="left-container-header-user">
          <FadeMenu openMenu={open} />
        </DrawerSideBarHeader>

        <ScrollableMenuWrapper className="left-container-menu-wrapper">
          {/* MENU */}
          <RenderMenu
            sideMenu={sideMenu}
            currentRoute={currentRoute}
            open={open}
          />
        </ScrollableMenuWrapper>

        <BottomMenuWrapper>
          {/* <Box style={{ position: "fixed", bottom: 0, width: "auto" }}>? */}

          <RenderMenu
            sideMenu={[
              {
                name: "Help",
                link: "https://geoscape.com.au/support/",
                Icon: LifeBuoy,
                target: "_blank",
              },
              {
                name: "Legal",
                link: Routes.legal,
                Icon: Scale,
              },
              {
                name: "User Profile",
                link: Routes.userSecurity,
                Icon: Contact,
              },
            ]}
            currentRoute={currentRoute}
            open={open}
          />

          <DrawerSideBarFooter>
            <List
              className="menu-list"
              sx={{
                display: "block",
                width: "226px",
                padding: "8px",
              }}
            >
              <ListItemButton
                onClick={handleDrawerClose}
                style={{
                  justifyContent: "center",
                  padding: "4px 16px 4px 16px",
                  height: "40px",
                  width: open ? "212px" : "56px",
                  justifyItems: "center",

                  gap: "16px",
                }}
              >
                {open ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
                <ListItemText
                  primary="Collapse Menu"
                  primaryTypographyProps={{
                    variant: "body1",
                  }}
                  sx={{
                    display: open ? "inline" : "none",
                    // opacity: 0,
                    // width: open ? "200px" : "900px",
                  }}
                />
              </ListItemButton>
            </List>
          </DrawerSideBarFooter>
          {/* </Box> */}
        </BottomMenuWrapper>
      </DrawerSideBar>

      {/* RIGHT CONTAINER */}

      {/* Flex Container, it contains 2 Containers, one for the menu-name-dashboards and  for -footbar*/}
      <Box
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <Box
          display="flex"
          style={{
            position: "relative",
            flexDirection: "column",
            alignItems: "flex-start",
            height: "100%",
            width: "100%",
            padding: "64px",
            gap: "24px",
            top: "0px",
            overflowY: "auto",
            backgroundColor: isLight ? "#F9F9F9" : undefined,
          }}
        >
          <DynamicBreadcrumbsMenu />

          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "100%",
                lg: "100%",
                xl: "1400px",
              },
            }}
          >
            <RouteSwitch></RouteSwitch>
          </Box>
          {/*  */}
          {/*  */}
        </Box>
      </Box>
    </Box>
  );
}

const ScrollableMenuWrapper = styled(Box)({
  flex: 1,
  overflowY: "auto",
  overflowX: "hidden",
  // backgroundColor: "red",
  paddingBottom: "16px",
  // Hide scrollbar for Chrome, Safari and Opera
  "&::-webkit-scrollbar": {
    display: "none",
  },
  // Hide scrollbar for IE, Edge and Firefox
  msOverflowStyle: "none",
  scrollbarWidth: "none",
});

// Bottom menu wrapper
const BottomMenuWrapper = styled(Box)({
  // borderTop: "1px solid rgba(0, 0, 0, 0.12)", // Optional divider
  paddingTop: "15px",
  // backgroundColor: "red",
});
