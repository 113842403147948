import { Box, Grid } from "@mui/material";
import * as Routes from "routes";
import { useHistory } from "react-router";
import {
  getActiveOrganisation,
  isAdminIdentity,
  useIdentity,
} from "contexts/identity-context";
import { HubDashboardCard } from "pages/shared/hub-dashboard-card";

export const DashboardCards = () => {
  const history = useHistory();

  const [identity, setIdentity] = useIdentity();
  const activeOrg = getActiveOrganisation(identity);
  const isAdmin = isAdminIdentity(identity);

  const cardContent = [
    {
      title: "Download the Latest Release",
      subtitle: "Data",
      actionName: "Download",
      actionFunc: () => history.push(Routes.dataDownload),
      actionDisabled: false,
    },
    {
      title: "Create a new API Key",
      subtitle: "Developer",
      actionName: "Create key",
      actionFunc: () => history.push(Routes.developerApiKeysCreateKey),
      actionDisabled: false,
    },
    {
      title: "Manage my Subscription",
      subtitle: "Settings",
      actionName: "Subscription",
      actionFunc: () => {
        activeOrg
          ? history.push(Routes.orgSubscription)
          : history.push(Routes.userSubscription);
      },
      actionDisabled: false,
    },
    {
      title: "View Data Catalogue",
      subtitle: "Data",
      actionName: "View",
      actionFunc: () => history.push(Routes.dataCatalogue),
      actionDisabled: false,
    },
  ];

  const cardContentAdmin = [
    {
      title: "Publish a Release",
      subtitle: "Admin",
      actionName: "Release management",
      actionFunc: () => history.push(Routes.adminReleases),
    },
    {
      title: "Manage a Customer",
      subtitle: "Admin",
      actionName: "Account Admin",
      actionFunc: () => history.push(Routes.adminAccount),
      actionDisabled: false,
    },
  ];

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Grid container spacing={2}>
          {cardContent.map((key) => {
            return (
              <Grid item xs={4} key={key.title}>
                <HubDashboardCard
                  title={key.title}
                  subtitle={key.subtitle}
                  actionName={key.actionName}
                  actionFunc={key.actionFunc}
                  actionDisabled={key.actionDisabled}
                  cardStyle={userCarddStyle}
                />
              </Grid>
            );
          })}
        </Grid>
        {isAdmin ? (
          <Grid container spacing={2}>
            {cardContentAdmin.map((key) => {
              return (
                <Grid item xs={6} key={key.title}>
                  <HubDashboardCard
                    title={key.title}
                    subtitle={key.subtitle}
                    actionName={key.actionName}
                    actionFunc={key.actionFunc}
                    cardStyle={adminCarddStyle}
                  />
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

const adminCarddStyle: any = {
  height: "100%",
  marginBottom: "auto",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
};

const userCarddStyle: any = {
  height: "100%",
  flex: 1,
  width: "350px",
  marginBottom: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "16px",
};
