import { CustomDatasetFilterType, DatasetFiltersType } from "data/models";
import { Form, Formik } from "formik";

import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { useEffect, useState } from "react";
import { resolveIdentityId } from "utils/identity";
import { transformDateFormat } from "../utils";

export default function FilterComponent({
  initialQueryFilterState,
  queryFilterState,
  setQueryFilterState,
  renderFilter,
  definitions,
  localStorageKey,
}: any) {
  const [time, setTime] = useState({ startTime: 0, endTime: 0 });

  const [identityState] = useIdentity();

  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const [presetRange, setPresetRange] = useState(
    queryFilterState?.release
      ? transformDateFormat(queryFilterState.release)
      : ""
  );

  useEffect(() => {
    setPresetRange(
      queryFilterState?.release
        ? transformDateFormat(queryFilterState.release)
        : ""
    );
  }, [queryFilterState]);

  type CombinedFiltersType = DatasetFiltersType | CustomDatasetFilterType;

  return (
    <>
      <Formik<CombinedFiltersType>
        initialValues={queryFilterState}
        onSubmit={(values) => {
          setQueryFilterState(values);
          localStorage.setItem(localStorageKey, JSON.stringify(values));
        }}
        enableReinitialize={true}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          resetForm,
          submitForm,
          errors,
          touched,
        }) => {
          return (
            <Form
              style={{
                width: "100%",
              }}
            >
              <>
                {renderFilter({
                  values,
                  handleChange,
                  handleSubmit,
                  definitions,
                  time,
                  setTime,
                  setFieldValue,
                  setQueryFilterState,
                  resetForm,
                  presetRange,
                  setPresetRange,
                  initialQueryFilterState,
                  queryFilterState,
                  localStorageKey
                })}
              </>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
