import { Box, LinearProgress } from "@mui/material";

import {
  DataGridPremium,
  GridCallbackDetails,
  GridColDef,
  GridColumnGroupingModel,
  GridDensity,
  GridEventListener,
  GridOverlay,
  GridRowClassNameParams,
  GridRowSelectionModel,
  GridToolbar,
  GridValidRowModel
} from "@mui/x-data-grid-premium";
import { GridInitialStatePremium } from "@mui/x-data-grid-premium/models/gridStatePremium";
import { useState } from "react";

export interface CustomTableProps {
  rows: GridValidRowModel[];
  cols: GridColDef[];
  handleOnRowClick?: GridEventListener<"rowClick">;
  isLoading: boolean;
  overlayText: string;
  getCustomRowClass?: (params: GridRowClassNameParams) => string;
  checkboxSelection?: boolean;
  onSelectionModelChange?: (
    selectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => void;
  isRowSelectable?: any;
  density?: GridDensity;
  hideFooter?: boolean;
  toolbar?: boolean;
  initialState?: GridInitialStatePremium;
  getRowId?: any;
  rowReordering?: boolean;
  editMode?: any;
  processRowUpdate?: any;
  customStyle?: any;
  rowHeight?: any;
  pagination?: any;
  getRowSpacing?: any;
  onRowOrderChange?: any;
  columnGroupingModel?: GridColumnGroupingModel;
}

const CustomTable = ({
  rows,
  cols,
  handleOnRowClick,
  isLoading,
  overlayText: OverlayText,
  getCustomRowClass,
  checkboxSelection,
  onSelectionModelChange,
  isRowSelectable,
  initialState,
  density = "standard",
  hideFooter = false,
  toolbar = false,
  getRowId,
  rowReordering,
  editMode,
  processRowUpdate,
  customStyle,
  rowHeight,
  pagination = true,
  getRowSpacing,
  onRowOrderChange,
  columnGroupingModel,
}: CustomTableProps) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const LoadingOverlay = () => {
    return (
      <GridOverlay>
        <div
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <LinearProgress sx={{ width: 100, height: 10 }} color="primary" />
        </div>
      </GridOverlay>
    );
  };

  const NoDataOverlay = () => {
    return (
      <GridOverlay style={{ zIndex: 5 }}>
        <Box>
          <Box>{OverlayText}</Box>
        </Box>
      </GridOverlay>
    );
  };

  const GridToolbarCustom = () => (
    <GridToolbar
      printOptions={{
        allColumns: true,
      }}
    />
  );

  return (
    <>
      <DataGridPremium
        checkboxSelection={checkboxSelection}
        columnGroupingModel={columnGroupingModel}
        onRowClick={handleOnRowClick}
        getRowClassName={getCustomRowClass}
        loading={isLoading}
        columns={cols}
        rows={rows}
        rowHeight={rowHeight ?? 52} // THIS PROPERTY IS USED AND INTERFIERE WITH AUTOHEIGHT
        autoHeight={true}
        pageSizeOptions={[10, 25, 50, 100]}
        paginationModel={paginationModel}
        pagination={pagination}
        onPaginationModelChange={setPaginationModel}
        slots={
          toolbar
            ? {
              toolbar: GridToolbarCustom,
              loadingOverlay: LoadingOverlay,
              noRowsOverlay: NoDataOverlay,
            }
            : {
              loadingOverlay: LoadingOverlay,
              noRowsOverlay: NoDataOverlay,
            }
        }
        onRowSelectionModelChange={onSelectionModelChange}
        disableRowSelectionOnClick
        isRowSelectable={isRowSelectable}
        hideFooter={hideFooter}
        initialState={
          initialState ?? {
            density: density,
          }
        }
        getRowId={getRowId}
        rowReordering={rowReordering}
        editMode={editMode}
        processRowUpdate={processRowUpdate}
        sx={customStyle}
        getRowSpacing={getRowSpacing}
        onRowOrderChange={onRowOrderChange}
      />
    </>
  );
};

export default CustomTable;
