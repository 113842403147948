import Box from "@mui/material/Box";

import { SxProps, Theme } from "@mui/material/styles";
import {
  DataGridPremium,
  GridRowSpacingParams,
  gridClasses,
} from "@mui/x-data-grid-premium";
import React from "react";
import { inputColumns } from "./columns-row";
import { addIndex } from "./utils";

const MappingTableInput = ({ inPutParameters }: any) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginTop: "16px",
        }}
      >
        <InputTable inPutParameters={addIndex(inPutParameters)} />
      </Box>
    </>
  );
};

function InputTable({ inPutParameters }: any) {
  const columns = inputColumns;

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 1 : 10,
      bottom: params.isLastVisible ? -1 : 10,
    };
  }, []);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DataGridPremium
        rows={inPutParameters}
        columns={columns}
        getRowId={(rows) => rows.index}
        pagination
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25 },
          },
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        autoHeight={true}
        rowHeight={80}
        getRowSpacing={getRowSpacing}
        sx={customStyle}
      />
    </div>
  );
}

const customStyle: SxProps<Theme> = {
  border: "none",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",

  alignItems: "center",

  [`& .${gridClasses.cell}`]: {
    border: "none",
  },

  [`& .MuiDataGrid-virtualScroller`]: {
    paddingBottom: "15px",
  },
  [`& .MuiDataGrid-main`]: {},
  [`& .MuiDataGrid-footerContainer`]: {
    width: "100%",
  },
  [`& .${gridClasses.columnHeader}`]: {
    display: "none",
  },

  [`& .${gridClasses.rowReorderCellContainer}`]: {
    display: "flex",
    width: "10px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",

    borderRadius: "4px 0px 0px 4px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderLeft: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    background: "rgba(0, 0, 0, 0.00)",
  },
  ["& .super-app-theme--cell-0"]: {
    display: "flex",
    padding: "8px 10px",
    alignItems: "center",
    alignSelf: "stretch",

    borderRadius: "4px 0px 0px 4px",
    border: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    background: "rgba(0, 0, 0, 0.00)",
  },

  ["& .super-app-theme--cell"]: {
    display: "flex",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",

    borderRadius: "0px 4px 4px 0px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderLeft: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
  },

  ["& .super-app-theme--cell-2"]: {
    display: "flex",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",

    borderRadius: "0px 4px 4px 0px",
    borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderRight: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
    borderBottom: "1px solid var(--divider, rgba(0, 0, 0, 0.12))",
  },

  ["& .super-app-theme--cell-3"]: {
    display: "flex",
    paddingLeft: "8px",
    alignItems: "center",
    alignSelf: "stretch",
    border: "none",
  },
};
export default MappingTableInput;
