import {
  Typography,
  Button,
  CardActions,
  Card,
  Skeleton,
  AlertTitle,
  Alert,
} from "@mui/material";
import MUICardContent from "@mui/material/CardContent";
import styled from "@mui/material/styles/styled";
import { BaseState } from "common/models";
import { Empty } from "utils";

export interface DashboardCardProps extends BaseState {
  isPaymentMyob?: boolean;
  title: string;
  content: string;
  actionName: string;
  actionFunc: null | any;
  isEnterprise?: boolean;
}

type Props = {
  cardState: DashboardCardProps;
  disableActionBtn?: boolean;
};

const CardContent = styled(MUICardContent)(({ theme: { spacing } }) => ({
  padding: spacing(0),
}));

export const DashboardCard = ({ cardState, disableActionBtn }: Props) => {
  return (
    <Card
      id={`dashboard-card-${cardState.title
        .toLowerCase()
        .replaceAll(" ", "-")}`}
      style={{
        height: "130px",

        marginBottom: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "16px",
      }}
      sx={{
        width: {
          xs: "230px",
          sm: "230px",
          md: "220px",
          lg: "230px",
          xl: "260px",
        },

        // bgcolor: "red"
      }}
    >
      {cardState.isLoading ? (
        <>
          <CardContent style={{ width: "100%" }}>
            <Typography component="p" variant="h5" textAlign="left">
              <Skeleton variant="rectangular" width="100%" />
            </Typography>
            <Typography
              component="p"
              variant="body2"
              textAlign="left"
              color="text.secondary"
            >
              <Skeleton variant="text" width="100%" />
            </Typography>
          </CardContent>

          {cardState.actionFunc && !disableActionBtn ? (
            <CardActions
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "18px 0px 0px 0px",
                // gap: "8px",
                alignSelf: "stretch",
                // width: "100%",
                // top: "84px",
                height: "46px",
                justifyContent: "flex-start",
              }}
            >
              <Button
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px",
                  borderRadius: "4px",
                }}
                onClick={cardState.actionFunc}
                size="small"
              >
                {/* {cardState.actionName} */}
                {/* <Skeleton variant="rectangular" /> */}
                <Skeleton variant="text" width="100%" />
              </Button>
            </CardActions>
          ) : (
            <Empty></Empty>
          )}
        </>
      ) : (
        <>
          {cardState.isError ? (
            <>
              {/* <CardHeader
          style={{ minHeight: "52px" }}
          title={cardState.title}
          titleTypographyProps={{ variant: "body2" }}
        /> */}
              <CardContent style={{ width: "100%" }}>
                <Alert severity="warning" variant="outlined" style={{}}>
                  <AlertTitle>{cardState.message}</AlertTitle>
                </Alert>
                {/* <Alert variant="outlined" severity="error">
            
          </Alert> */}
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "18px 0px 0px 0px",
                  // gap: "8px",
                  alignSelf: "stretch",
                  // width: "100%",
                  // top: "84px",
                  height: "46px",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    borderRadius: "4px",
                  }}
                  onClick={cardState?.actionFunc}
                  size="small"
                >
                  {cardState.actionName}
                </Button>
              </CardActions>
            </>
          ) : cardState.isPaymentMyob ? (
            <>
              <CardContent style={{ width: "100%" }}>
                <Alert severity="info" variant="outlined" style={{}}>
                  {/* <AlertTitle> */}
                  <Typography variant="body2">{cardState.message}</Typography>
                </Alert>
              </CardContent>
              <CardActions
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "18px 0px 0px 0px",
                  // gap: "8px",
                  alignSelf: "stretch",
                  // width: "100%",
                  // top: "84px",
                  height: "46px",
                  justifyContent: "flex-start",
                }}
              >
                <Button
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "0px",
                    borderRadius: "4px",
                  }}
                  onClick={cardState?.actionFunc}
                  size="small"
                >
                  {cardState.actionName}
                </Button>
              </CardActions>
            </>
          ) : (
            <>
              <CardContent>
                <Typography component="p" variant="h5" textAlign="left">
                  {cardState.content}
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  textAlign="left"
                  color="text.secondary"
                >
                  {cardState.title}
                </Typography>
              </CardContent>

              {cardState.actionFunc && !disableActionBtn ? (
                <CardActions
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "18px 0px 0px 0px",
                    // gap: "8px",
                    alignSelf: "stretch",
                    // width: "100%",
                    // top: "84px",
                    height: "46px",
                    justifyContent: "flex-start",
                  }}
                >
                  <Button
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0px",
                      borderRadius: "4px",
                    }}
                    onClick={cardState.actionFunc}
                    size="small"
                  >
                    {cardState.actionName}
                  </Button>
                </CardActions>
              ) : (
                <Empty></Empty>
              )}
            </>
          )}
        </>
      )}
    </Card>
  );
};
