import { Box } from "@mui/material";
import BillingDetailsPage from "components/settings/shared/billing-details/billingDetailsPage";
import PaymentMethodInfo from "components/settings/shared/billing-details/paymentMethodInfo";

export const BillingDetails = () => {
  return (
    <Box>
      <PaymentMethodInfo isUserPage={true} />
      <BillingDetailsPage isUserPage={true} />
    </Box>
  );
};
