import { Paper, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Basemap } from "components/basemap/basemap";
import { useEffect, useRef, useState } from "react";
import { FillLayer, Layer, MapRef, Source } from "react-map-gl";
import bbox from "@turf/bbox";
import { Geo } from "aws-amplify";
import { geometry } from "@turf/turf";

interface MapThumbnailProps {
  geometry?: GeoJSON.FeatureCollection;
}
export const MapThumbnail = (props: MapThumbnailProps) => {
  const classes = useStyles();
  //const [mapRef, setMapRef] = useState<MapRef | null>(null);

  const mapRef = useRef<MapRef>();

  const [minLng, minLat, maxLng, maxLat] = props.geometry
    ? bbox(props.geometry)
    : // bounding box of Australia
      [96, -45, 168, -8];

  const dataLayer: FillLayer = {
    id: "data",
    type: "fill",
    paint: {
      "fill-color": "#00ffff",
      "fill-opacity": 0.8,
    },
  };


  return (
    <Paper elevation={0} square className={classes.photo}>
      <Basemap
        // @ts-ignore
        mapRef={mapRef}
        attributionControl={false}
        onZoom={(zoom) => {}}
        style={{
          width: "100px",
          height: "100px",
        }}
        interactive={false}
        bbox={[minLng, minLat, maxLng, maxLat]}
        bboxPadding={20}
      >
        <Source type="geojson" data={props.geometry}>
          <Layer {...dataLayer} />
        </Source>
      </Basemap>
    </Paper>
  );
};

const useStyles = makeStyles((theme: any) => ({
  photo: {
    width: "100px",
    height: "100px",
    textAlign: "center",
    alignContent: "center",
    backgroundColor: theme.palette.action.hover,
  },
}));
