import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Link,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Auth } from "aws-amplify";
import { Form, Formik, FormikConfig } from "formik";
import React, { useState } from "react";
import * as Routes from "routes";
import { boolean, InferType, object, ref, string } from "yup";
import { useMessageState } from "./use-message-state";

export const forgotPasswordValidationSchema = object({
  isDelivered: boolean(),
  email: string().email("Enter a valid email.").required("Email is required."),
  code: string().when("isDelivered", {
    is: true,
    then: string()
      .matches(/^[0-9]+$/, "Must be only digits.")
      .min(6, "Must be exactly 6 digits.")
      .max(6, "Must be exactly 6 digits.")
      .required("Code is required"),
  }),
  newPassword: string().when("isDelivered", {
    is: true,
    then: string()
      .min(8, "New Password should be of minimum 8 characters length")
      .required("New Password is required."),
  }),
  confirmNewPassword: string().when("isDelivered", {
    is: true,
    then: string()
      .oneOf([ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required."),
  }),
});

type ResetPasswordValues = InferType<typeof forgotPasswordValidationSchema>;

export const ResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useMessageState({
    passwordReset: false,
    type: null,
    text: "",
  });

  const onSubmit: FormikConfig<
    InferType<typeof forgotPasswordValidationSchema>
  >["onSubmit"] = async (
    { email, isDelivered, code, newPassword },
    { setFieldValue }
  ) => {
    try {
      setIsLoading(true);
      if (!isDelivered) {
        await Auth.forgotPassword(email.toLowerCase());
        setFieldValue("isDelivered", true, false);
        setMessage({
          type: "success",
          text: `A verification code has been successfully sent to ${email}.`,
        });
      } else {
        await Auth.forgotPasswordSubmit(
          email.toLowerCase(),
          code!,
          newPassword!
        );
        setMessage({
          passwordReset: true,
          type: "success",
          text: `Your password has been successfully reset.`,
        });
        // history.goBack();
      }
    } catch (e: any) {
      setMessage({
        type: "error",
        text: e?.message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <Formik<ResetPasswordValues>
        initialValues={{
          isDelivered: false,
          email: "",
          code: "",
          newPassword: "",
          confirmNewPassword: "",
        }}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          values: { isDelivered, email, code, newPassword, confirmNewPassword },
          handleChange,
          touched,
          errors,
        }) => (
          <Form method="POST">
            <Card
              style={{
                display: "flex",
                width: "410px",
                minWidth: "410px",
                maxWidth: "500px",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "32px",
              }}
            >
              <CardHeader
                sx={{
                  padding: "32px 32px 0px 32px",
                }}
                titleTypographyProps={{ variant: "h5" }}
                title="Password Reset"
                subheader={
                  isDelivered
                    ? "Complete the form with the 'forgot password' code and a new password."
                    : "Don't worry, it happens to the best of us"
                }
              />
              <CardContent
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  padding: "var(--4,32px) !important",
                  alignItems: "flex-start",
                  gap: "16px",
                  alignSelf: "stretch",
                }}
              >
                {message.passwordReset ? (
                  <>
                    <Alert
                      variant="outlined"
                      severity={"success"}
                      sx={{
                        borderRadius: "8px",
                      }}
                    >
                      {message.text}
                    </Alert>

                    <Divider />
                    <Button
                      style={{ padding: "16px" }}
                      LinkComponent={Link}
                      disabled={isLoading}
                      size="small"
                      fullWidth
                      href={Routes.signIn}
                    >
                      Sign In
                    </Button>
                  </>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      label="Email"
                      value={email}
                      onChange={handleChange}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                      disabled={isDelivered || isLoading}
                      sx={customStyle}
                      InputProps={{
                        sx: {
                          borderRadius: "8px",
                          height: "40px",
                          display: "flex",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                        },
                      }}
                    />
                    {isDelivered && (
                      <>
                        <TextField
                          fullWidth
                          id="code"
                          name="code"
                          label="Code"
                          type="text"
                          value={code}
                          onChange={handleChange}
                          error={touched.code && Boolean(errors.code)}
                          helperText={touched.code && errors.code}
                          disabled={isLoading}
                          sx={customStyle}
                          InputProps={{
                            sx: {
                              borderRadius: "8px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            },
                          }}
                        />
                        <TextField
                          fullWidth
                          id="newPassword"
                          name="newPassword"
                          label="New Password"
                          type={showPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={handleChange}
                          error={
                            touched.newPassword && Boolean(errors.newPassword)
                          }
                          helperText={touched.newPassword && errors.newPassword}
                          disabled={isLoading}
                          sx={customStyle}
                          InputProps={{
                            sx: {
                              borderRadius: "8px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            },
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                        <TextField
                          fullWidth
                          id="confirmNewPassword"
                          name="confirmNewPassword"
                          label="Confirm New Password"
                          type={showPassword ? "text" : "password"}
                          value={confirmNewPassword}
                          onChange={handleChange}
                          error={
                            touched.confirmNewPassword &&
                            Boolean(errors.confirmNewPassword)
                          }
                          helperText={
                            touched.confirmNewPassword &&
                            errors.confirmNewPassword
                          }
                          disabled={isLoading}
                          sx={customStyle}
                          InputProps={{
                            sx: {
                              borderRadius: "8px",
                              height: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            },
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            ),
                          }}
                        />
                      </>
                    )}

                    {isLoading ? (
                      <Box width="100%" display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        {message.text === "" || !message.type ? null : (
                          <Alert
                            variant="outlined"
                            severity={message.type}
                            sx={{
                              borderRadius: "8px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              alignSelf: "stretch",
                            }}
                          >
                            <Typography variant="body2">
                              {message.text}
                            </Typography>
                          </Alert>
                        )}

                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isLoading}
                          fullWidth
                          children="Submit"
                          sx={{ borderRadius: "8px", height: "36px" }}
                        />

                        <ButtonGroup
                          variant="text"
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignSelf: "stretch",
                            borderRadius: "4px",
                          }}
                          aria-label="Basic button group"
                        >
                          <Button
                            LinkComponent={Link}
                            size="small"
                            color="primary"
                            href={Routes.signUp}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",

                              padding: "4px 5px",
                              gap: "8px",
                              fontSize: "12px",
                            }}
                          >
                            Sign up
                          </Button>

                          <Button
                            LinkComponent={Link}
                            size="small"
                            color="primary"
                            href={Routes.signIn}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",

                              padding: "4px 5px",
                              gap: "8px",
                              fontSize: "12px",
                            }}
                          >
                            Sign In
                          </Button>

                          <Button
                            LinkComponent={Link}
                            size="small"
                            color="primary"
                            href={Routes.resendVerification}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",

                              padding: "4px 5px",
                              gap: "8px",
                              fontSize: "12px",
                            }}
                          >
                            Resend Verification
                          </Button>
                        </ButtonGroup>
                      </>
                    )}
                  </>
                )}
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  );
};

const customStyle: SxProps<Theme> = {
  [`& .MuiInputLabel-root `]: {
    top: "-5px",
    //
  },

  [`& .MuiInputLabel-shrink `]: {
    top: "0px",
  },
};
