import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  InputAdornment,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { Overage } from "data/models";
import { ReactQueryMutationError, updateOverageLimit } from "data/mutations";
import {
  fetchOverage,
  fetchPaymentMethod,
  fetchSubscription,
} from "data/queries";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Routes from "routes";
import {
  calculateOverageCredits,
  PlanDetails,
  roundDownOverageBudget,
} from "utils/calculations";
import { resolveIdentityId } from "utils/identity";
import OverageSummary from "./overageSummary";

const useStyles = makeStyles({
  noneDisplay: { display: "none" },
  error: { color: "red" },
});

interface OverageInfoProps {
  isUserPage: boolean;
}

const OverageInfo: FC<OverageInfoProps> = (props: OverageInfoProps) => {
  const classes = useStyles();

  const [newOverageBudget, setNewOverageBudget] = useState<number>(); // new budget limit
  const [editingOverage, setEditingOverage] = useState<boolean>();

  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState, props.isUserPage);

  const overageQuery = useQuery(
    [QueryKeys.overage, identityId],
    () => fetchOverage(props.isUserPage, identityState),
    {
      onSuccess: (overage) => {
        if (newOverageBudget === undefined) {
          setNewOverageBudget(overage.dollarsLimit);
        }
      },
    }
  );
  const paymentMethodQuery = useQuery(
    [QueryKeys.paymentMethod, identityId],
    () => fetchPaymentMethod(props.isUserPage, identityState)
  );
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(props.isUserPage, identityState)
  );

  const planDetails: PlanDetails = {
    overageRate: 1,
    planCredits: 1,
  };

  if (subscriptionQuery.isSuccess) {
    planDetails.overageRate = subscriptionQuery.data.activeSubscription.plan
      .overageRate
      ? subscriptionQuery.data.activeSubscription.plan.overageRate
      : 1;
    planDetails.planCredits =
      subscriptionQuery.data.activeSubscription.plan.creditAmount;
  }

  const queryClient = useQueryClient();
  const overageMutation = useMutation<Overage, ReactQueryMutationError>(
    () => {
      if (newOverageBudget === undefined || !overageQuery.isSuccess) {
        throw Error(
          "Unable to update overage, incomplete overage information."
        );
      }

      return updateOverageLimit(
        newOverageBudget,
        planDetails,
        overageQuery.data,
        props.isUserPage,
        identityState,
        setEditingOverage
      );
    },
    {
      onSuccess: (newOverage) => {
        queryClient.setQueryData([QueryKeys.overage, identityId], newOverage);
        setNewOverageBudget(newOverage.dollarsLimit);
      },
    }
  );

  let isLoading: boolean =
    overageQuery.isLoading ||
    paymentMethodQuery.isLoading ||
    subscriptionQuery.isLoading;
  let isEnterprise: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  const isPaymentMyob =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);

  return (
    <Box
      sx={{
        position: "relative",
        top: "-70px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="rectangular" width={187} height={42} />
        ) : (
          <Button
            onClick={() => setEditingOverage(true)}
            variant="contained"
            size="large"
            startIcon={<EditIcon />}
            disabled={
              isLoading || isPaymentMyob || paymentMethodQuery.data === null
            }
            className={editingOverage ? classes.noneDisplay : ""}
          >
            Edit Overage
          </Button>
        )}
      </Box>

      <ReactQueryErrorWrapper
        queries={[overageQuery, paymentMethodQuery, subscriptionQuery]}
        mutations={[overageMutation]}
      />

      <Box sx={{ marginTop: editingOverage ? "92px" : "0px" }}>
        {paymentMethodQuery.data === null && !isLoading && !isPaymentMyob && (
          <Alert
            severity="warning"
            style={{ marginTop: "32px", marginBottom: "28px" }}
            action={
              <Button
                color="inherit"
                href={
                  props.isUserPage
                    ? Routes.userBillingDetails
                    : Routes.orgBillingDetails
                }
              >
                Add Payment Method
              </Button>
            }
          >
            <AlertTitle>Overage can't be configured</AlertTitle>
            Overage can't be configured without a valid payment method attached
            to your account.
          </Alert>
        )}

        <OverageSummary
          isUserPage={props.isUserPage}
          renderIfEnterprise={false}
          showAlerts={false}
          showActions={false}
        />

        <Box style={{ marginBottom: "24px" }}>
          {isLoading ? (
            <Skeleton variant="rectangular" height={187} />
          ) : (
            <Paper style={{ padding: "16px" }}>
              <Typography variant="h5">Overage Budget</Typography>
              <Typography variant="body2">
                Your overage budget defines the maximum you can be charged in a
                billing period for usage beyond your plans standard amount.
              </Typography>

              {isPaymentMyob ? (
                <Alert severity="info" style={{ marginTop: "32px" }}>
                  Overage is managed as part of your {""}
                  {isEnterprise
                    ? "Enterprise Subscription."
                    : "Professional Subscription"}
                </Alert>
              ) : (
                <TextField
                  fullWidth
                  disabled={!editingOverage}
                  margin="normal"
                  variant="outlined"
                  label="Budget"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, max: 10000 },
                  }}
                  helperText={
                    overageQuery.data?.dollarsLimit === 0
                      ? ""
                      : `This is equivalent to ${calculateOverageCredits(
                          newOverageBudget,
                          planDetails
                        )} credits above your ${
                          subscriptionQuery.data?.activeSubscription.plan.name
                        } plan's ${planDetails.planCredits} monthly limit`
                  }
                  defaultValue={overageQuery.data?.dollarsLimit}
                  value={newOverageBudget}
                  onChange={(event) => {
                    setNewOverageBudget(
                      Math.min(parseInt(event.target.value), 10000)
                    );
                  }}
                ></TextField>
              )}
              {editingOverage &&
                newOverageBudget !== undefined &&
                newOverageBudget % planDetails.overageRate !== 0 && (
                  <Alert severity="info" sx={{ marginBottom: "16px" }}>
                    Budget will automatically be rounded down to the nearest $
                    {planDetails.overageRate} increment ($
                    {roundDownOverageBudget(newOverageBudget, planDetails)})
                    when saved.
                  </Alert>
                )}
              {editingOverage &&
                newOverageBudget !== undefined &&
                overageQuery.data?.dollarsLimit !== undefined &&
                (newOverageBudget < overageQuery.data.dollarsSpent ||
                  roundDownOverageBudget(newOverageBudget, planDetails) <
                    overageQuery.data.dollarsSpent) && (
                  <Alert severity="warning" sx={{ marginBottom: "16px" }}>
                    Setting a budget below your current spend will cause your
                    API access to end immediately. You will still be charged for
                    any existing Overage Usage at the end of your billing cycle.
                  </Alert>
                )}
              {!isPaymentMyob && newOverageBudget === 0 && (
                <Alert severity="warning" sx={{ marginBottom: "16px" }}>
                  With a $0 budget, services will stop when you consume your
                  plan's credit limit.
                </Alert>
              )}

              {editingOverage && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    marginTop: "16px",
                    gap: "16px",
                  }}
                >
                  <LoadingButton
                    size="small"
                    variant="outlined"
                    loading={overageMutation.isLoading}
                    onClick={() => {
                      if (newOverageBudget === undefined) {
                        // console.error("Something has gone wrong with overage.")
                        return;
                      }
                      overageMutation.mutate();
                    }}
                  >
                    Save Changes
                  </LoadingButton>
                  <Button
                    size="small"
                    color="warning"
                    variant="outlined"
                    disabled={overageMutation.isLoading}
                    onClick={() => {
                      setNewOverageBudget(
                        overageQuery.isSuccess
                          ? overageQuery.data.dollarsLimit
                          : 0
                      );
                      setEditingOverage(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              )}
            </Paper>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default OverageInfo;
