// OVERAGE SUMMARY COMPONENT
// Data can come from the parent or the component can fetch it itself
import * as QueryKeys from "data";
import * as Routes from "routes";
import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Link,
  Skeleton,
  Typography,
} from "@mui/material";
import { useIdentity } from "contexts/identity-context";
import { fetchOverage, fetchSubscription } from "data/queries";
import { resolveIdentityId } from "utils/identity";
import { useQuery } from "react-query";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

interface OverageSummaryProps {
  isUserPage: boolean;
  renderIfEnterprise: boolean;
  showAlerts: boolean;
  showActions: boolean;
}

const OverageSummary = (props: OverageSummaryProps) => {
  const [identityState] = useIdentity();
  const identityId = resolveIdentityId(identityState, props.isUserPage);

  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage(props.isUserPage, identityState)
  );
  const subscriptionQuery = useQuery([QueryKeys.subscription, identityId], () =>
    fetchSubscription(props.isUserPage, identityState)
  );

  const isLoading = overageQuery.isLoading || subscriptionQuery.isLoading;

  const isPaymentMyob =
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.plan.isEnterprise) ||
    (subscriptionQuery.isSuccess &&
      subscriptionQuery.data.activeSubscription.isMyob);
  const isEnterprise =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.plan.isEnterprise;

  if (!props.renderIfEnterprise && isPaymentMyob && !isLoading) {
    // Do not render component if it is enterprise
    return <></>;
  }

  return (
    <Box sx={{ marginBottom: "24px" }}>
      <ReactQueryErrorWrapper
        queries={[overageQuery, subscriptionQuery]}
        mutations={[]}
      />

      {isLoading && <Skeleton variant="rectangular" height={164} />}

      {overageQuery.isSuccess && (
        <Card>
          <CardContent
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <Typography variant="h5">Overage Usage</Typography>
              <Typography variant="body2">
                Allows for additional usage above your plan's quota. With an
                optional maximum spend.
              </Typography>
            </Box>
            {!isPaymentMyob && (
              <Box
                // style={{ "display": "flex", "flexDirection": "row" }}
                style={{ textAlign: "right" }}
              >
                <Typography variant="h5">
                  ${overageQuery.data.dollarsSpent}
                </Typography>
                <Typography variant="body2">Total Spend</Typography>
              </Box>
            )}
          </CardContent>
          <CardContent>
            {(!props.showAlerts ||
              (!isPaymentMyob && overageQuery.data.dollarsLimit > 0)) && (
              <>
                <LinearProgress
                  variant="determinate"
                  sx={{ marginBottom: "16px" }}
                  value={Math.min(
                    (overageQuery.data.dollarsSpent /
                      overageQuery.data.dollarsLimit) *
                      100,
                    100
                  )}
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography variant="body2">$0</Typography>
                  <Typography variant="body2">
                    ${Math.round(overageQuery.data.dollarsLimit)}
                  </Typography>
                </Box>
              </>
            )}

            {props.showAlerts && isPaymentMyob && (
              <Alert severity="info">
                Overage is managed as part of your{" "}
                {isEnterprise
                  ? "Enterprise Subscription."
                  : "Profesional Subscription."}
              </Alert>
            )}

            {props.showAlerts &&
              !isPaymentMyob &&
              overageQuery.data.dollarsLimit === 0 && (
                <Alert severity="warning">
                  With a $0 budget, services will stop when you consume your
                  plan's credit limit.
                </Alert>
              )}
          </CardContent>

          {props.showActions && (
            <CardActions>
              <Button
                LinkComponent={Link}
                size="small"
                href={props.isUserPage ? Routes.userOverage : Routes.orgOverage}
              >
                Edit Overage
              </Button>
            </CardActions>
          )}
        </Card>
      )}
    </Box>
  );
};

export default OverageSummary;
