import * as QueryKeys from "data";
import {
  Alert,
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemButton,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import { Product } from "contexts/api-key-state";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import { generatePath } from "react-router-dom";
import * as Routes from "routes";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  ReactQueryQueryError,
  fetchAdminUserApiKeys,
  fetchApiKeys,
  fetchApiProducts,
} from "data/queries";
import { resolveIdentityId } from "utils/identity";
import {
  ReactQueryMutationError,
  createAdminUserApiKey,
  createApiKey,
} from "data/mutations";

import { LoadingButton } from "@mui/lab";
import { ApiKey } from "data/models";
import { createApiKeyValidationSchema } from "./validation";
import BackButton from "components/navigation/back-button";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

interface HistoryState {
  accountId: string;
  accountName: string;
}

export const AdminCreateApiKey = () => {
  const history = useHistory();
  const [identityState] = useIdentity();
  const queryClient = useQueryClient();

  const { accountId, accountName } = history.location.state as HistoryState;

  const adminUserApiKeysQuery = useQuery(
    [QueryKeys.adminUserApiKeys, accountId],
    () => fetchAdminUserApiKeys(accountId, identityState)
  );

  const apiProductsQuery = useQuery([QueryKeys.apiProducts, accountId], () =>
    fetchApiProducts(identityState)
  );

  const adminUserApiKeyCreateMutation = useMutation(
    (values: ApiKey) => createAdminUserApiKey(values, accountId, identityState),
    {
      onSuccess: (createdApiKey) => {
        let allApiKeys = [createdApiKey];
        if (adminUserApiKeysQuery.isSuccess) {
          allApiKeys = [...adminUserApiKeysQuery.data, createdApiKey];
        }
        queryClient.setQueryData(
          [QueryKeys.adminUserApiKeys, accountId],
          allApiKeys
        );
        history.push(
          generatePath(Routes.adminApiKeys, { accountId: accountId }),
          {
            accountId: accountId,
            accountName: accountName,
          }
        );
      },
    }
  );

  const isLoading =
    adminUserApiKeysQuery.isLoading || apiProductsQuery.isLoading;
  const isSuccess =
    adminUserApiKeysQuery.isSuccess && apiProductsQuery.isSuccess;
  const isError =
    adminUserApiKeysQuery.isError ||
    apiProductsQuery.isError ||
    adminUserApiKeyCreateMutation.isError;

  return (
    // <Box>
    <Box sx={{ position: "relative", top: "-70px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          marginBottom: "50px",
          height: "43px",
        }}
      >
        <BackButton />
      </Box>

      <ReactQueryErrorWrapper
        queries={[adminUserApiKeysQuery, apiProductsQuery]}
        mutations={[adminUserApiKeyCreateMutation]}
      />

      <Card sx={{ padding: "16px" }}>
        {isLoading && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Skeleton variant="rectangular" height="56px" />
            <Skeleton variant="rectangular" height="56px" />
            <Skeleton variant="rectangular" height="171px" />
            <Skeleton variant="rectangular" height="36.5px" />
          </Box>
        )}

        {isSuccess && (
          <Formik<ApiKey>
            initialValues={{
              createdDate: 0,
              expireDate: 0,
              consumerKey: "",
              consumerSecret: "",
              name: "",
              status: "approved",
              products: [],
              notes: "",
              displayName: "",
            }}
            onSubmit={async (values) =>
              adminUserApiKeyCreateMutation.mutate(values)
            }
            validationSchema={createApiKeyValidationSchema}
          >
            {({
              values: { name, products, notes },
              handleChange,
              handleBlur,
              errors,
              touched,
            }) => {
              return (
                <Form>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <TextField
                      id="name"
                      name="name"
                      label="Name"
                      type="text"
                      value={name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                      disabled={adminUserApiKeyCreateMutation.isLoading}
                      variant="outlined"
                      fullWidth
                    />

                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="products-label">Products</InputLabel>
                      <Select
                        labelId="products-label"
                        id="products-select"
                        label="Products"
                        value={products}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        variant="outlined"
                        disabled={adminUserApiKeyCreateMutation.isLoading}
                        fullWidth
                        multiple
                      >
                        {apiProductsQuery.data.map((product: Product) => {
                          return (
                            <ListItemButton
                              sx={{ padding: "0px" }}
                              disabled={adminUserApiKeyCreateMutation.isLoading}
                            >
                              <FormControlLabel
                                sx={{
                                  width: "100%",
                                  padding: "8px",
                                  margin: "0px",
                                }}
                                control={
                                  <Checkbox
                                    id="products"
                                    name="products"
                                    onChange={handleChange}
                                    value={product.name}
                                    checked={products.includes(product.name)}
                                  />
                                }
                                label={product.displayName}
                              />
                            </ListItemButton>
                          );
                        })}
                      </Select>
                    </FormControl>

                    <TextField
                      id="notes"
                      name="notes"
                      label="Notes"
                      type="text"
                      multiline
                      rows={6}
                      value={notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.notes && Boolean(errors.notes)}
                      helperText={touched.notes && errors.notes}
                      disabled={adminUserApiKeyCreateMutation.isLoading}
                      variant="outlined"
                      fullWidth
                    />

                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      loading={adminUserApiKeyCreateMutation.isLoading}
                    >
                      Create
                    </LoadingButton>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </Card>
    </Box>
  );
};
