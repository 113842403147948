import * as QueryKeys from "data";
// Selectable card and card list https://codepen.io/sheefu/pen/mddGQqb

import EmailIcon from "@mui/icons-material/Email";
import {
  AccordionActions,
  Alert,
  AlertTitle,
  Box,
  Button,
  CardActions,
  CardHeader,
  FormGroup,
  ListItemButton,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Link as LinkRouter, useHistory } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useIdentity } from "contexts/identity-context";
import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useIntercom } from "react-use-intercom";
import { resolveIdentityId } from "utils/identity";

import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { LoadingButton } from "@mui/lab";
import { abbreviateNumber } from "components/dashboard/utils-card";
import BackButton from "components/navigation/back-button";
import { OverageInput } from "components/settings/shared/overage/overageInput";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";
import { dummyInitialNewPlan, dummyInitialSub } from "data/dummy";
import {
  BillingInterval,
  Coupon,
  PortalPlan,
  PortalSubscription,
  UpdateSubscription,
} from "data/models";
import {
  ReactQueryMutationError,
  updateSubscription,
  validateCoupon,
} from "data/mutations";
import {
  fetchBillingUsage,
  fetchOverage,
  fetchSubscription,
} from "data/queries";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Routes from "routes";
import { formatPrice } from "utils/format";
import PaymentMethodInfo from "../billing-details/paymentMethodInfo";

const OrderSummaryRow = (left: string, right: string) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="body1">{left}</Typography>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <Typography variant="body1">{right}</Typography>
        </Box>
      </Box>
      <hr style={{ margin: "0px" }} />
    </>
  );
};

const SubscriptionSummaryRow = (currentValue: string, newValue: string) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Box sx={{ width: "45%", textAlign: "left" }}>
        <Typography variant="body1">{currentValue}</Typography>
      </Box>
      <Box sx={{ width: "10%", textAlign: "center" }}>
        <Typography variant="body1">{">"}</Typography>
      </Box>
      <Box sx={{ width: "45%", textAlign: "right" }}>
        <Typography variant="body1">{newValue}</Typography>
      </Box>
    </Box>
  );
};

interface SubscriptionSummaryProps {
  currentSubscription: PortalSubscription;
  currentOverageLimitDollars: number;
  newPlan: PortalPlan;

  selectedPlanName: string;
  selectedInterval: BillingInterval;
  newSubscriptionOverageLimitDollars: number;
  coupon?: Coupon;
}

function getPlan(
  productName: string,
  interval: BillingInterval,
  plans: PortalPlan[]
) {
  return plans.filter(
    (plan) =>
      plan.name.toLowerCase() === productName.toLowerCase() &&
      plan.interval === interval
  )[0];
}

function getRateLimit(plan: PortalPlan, overageEnable: boolean): number {
  if (plan.name.toLowerCase() === "freemium" && overageEnable) {
    return 5;
  }
  return plan.rateLimit;
}

const planValueTable: { [key: string]: number } = {
  freemium_day: 1,
  freemium_month: 2,
  professional_month: 3,
  professional_year: 4,
};

type PlanChangeType = "upgrade" | "downgrade" | "same";

function getChangeType(
  newPlan: PortalPlan,
  oldPlan: PortalPlan
): PlanChangeType {
  const newPlanValue =
    planValueTable[`${newPlan.name.toLowerCase()}_${newPlan.interval}`];
  const oldPlanValue =
    planValueTable[`${oldPlan.name.toLowerCase()}_${oldPlan.interval}`];
  if (newPlanValue < oldPlanValue) {
    return "downgrade";
  } else if (newPlanValue === oldPlanValue) {
    return "same";
  }
  return "upgrade";
}

const intervalShortForm: { [key: string]: string } = {
  day: "day",
  month: "mo",
  year: "yr",
};

const intervalFormatted: { [key: string]: string } = {
  day: "Daily",
  month: "Monthly",
  year: "Annual",
};

const SubscriptionSummary = (props: SubscriptionSummaryProps) => {
  function newPlanHeader() {
    return `$${(
      props.newPlan.price / 100 -
      Math.min(
        calculateCouponDiscount(
          props.newPlan.price / 100,
          props.newPlan.productId,
          props.coupon
        ),
        props.newPlan.price / 100
      )
    ).toLocaleString()}/${props.newPlan.interval} ${
      props.coupon && isCouponValid(props.newPlan.productId, props.coupon)
        ? `(First ${props.newPlan.interval})`
        : ""
    }`;
  }

  function beforeDiscountHeader() {
    return `$${(props.newPlan.price / 100).toLocaleString()}/${
      props.newPlan.interval
    } after`;
  }

  function summaryPlanName(plan: PortalPlan) {
    return `${plan.name} Plan`;
  }

  function summaryMailPointIncluded(plan: PortalPlan) {
    return plan.mailpointAccess
      ? "Mailpoint included"
      : "No access to Mailpoint";
  }

  function summaryRateLimitInfo(plan: PortalPlan, overageLimit: number) {
    return `${getRateLimit(plan, overageLimit > 0)} Requests/s`;
  }

  function summaryCreditLimit(plan: PortalPlan) {
    return `${abbreviateNumber(plan.creditAmount)} credits/${
      intervalShortForm[plan.interval]
    }`;
  }

  function summaryOverageBudget(overageLimitDollars: number) {
    return `Overage Budget - $${overageLimitDollars}`;
  }

  return (
    <Card sx={{ padding: "8px" }}>
      <Box sx={{ margin: "8px" }}>
        <Typography
          variant="h5"
          sx={{ marginBottom: "16px" }}
        >{`${props.newPlan.name} Plan`}</Typography>
        <Typography variant="h4">{newPlanHeader()}</Typography>
        {props.coupon && (
          <Typography variant="subtitle2">{beforeDiscountHeader()}</Typography>
        )}
      </Box>
      <Box sx={{ margin: "24px", marginTop: "32px" }}>
        {SubscriptionSummaryRow("Current Plan", "New Plan")}

        <hr style={{ marginTop: "24px", marginBottom: "24px" }} />

        {SubscriptionSummaryRow(
          summaryPlanName(props.currentSubscription.plan),
          summaryPlanName(props.newPlan)
        )}
        {SubscriptionSummaryRow(
          summaryCreditLimit(props.currentSubscription.plan),
          summaryCreditLimit(props.newPlan)
        )}
        {SubscriptionSummaryRow(
          summaryMailPointIncluded(props.currentSubscription.plan),
          summaryMailPointIncluded(props.newPlan)
        )}
        {SubscriptionSummaryRow(
          summaryRateLimitInfo(
            props.currentSubscription.plan,
            props.currentOverageLimitDollars
          ),
          summaryRateLimitInfo(
            props.newPlan,
            props.newSubscriptionOverageLimitDollars
          )
        )}

        <hr style={{ marginTop: "24px", marginBottom: "24px" }} />

        {SubscriptionSummaryRow(
          summaryOverageBudget(props.currentOverageLimitDollars),
          summaryOverageBudget(props.newSubscriptionOverageLimitDollars)
        )}
      </Box>
    </Card>
  );
};

const selectedStyling = (selected: boolean, borderColour: string) => {
  return {
    border: selected ? `${borderColour} 1px solid` : "",
    padding: selected ? "0px" : "1px",
  };
};

interface ManageSubscriptionProps {
  isUser: boolean;
}

export const GenericManageSubscription = (props: ManageSubscriptionProps) => {
  const [identityState] = useIdentity();
  const isUser = props.isUser;
  const identityId = resolveIdentityId(identityState, isUser);

  const { enqueueSnackbar } = useSnackbar();

  const queryClient = useQueryClient();

  const subscriptionQuery = useQuery(
    [QueryKeys.subscription, identityId],
    () => fetchSubscription(isUser, identityState),
    {
      onSuccess: (data) => {
        setSubscriptionSummary((prevState) => {
          return {
            ...prevState,
            currentSubscription: data.activeSubscription,
            newPlan: getPlan(
              subscriptionSummary.selectedPlanName,
              subscriptionSummary.selectedInterval,
              data.availablePlans
            ),
          };
        });
      },
    }
  );

  const [coupon, setCoupon] = useState("");

  const couponMutation = useMutation(
    (promoCode: string) => validateCoupon(promoCode, identityState),
    {
      onSuccess: (coupon: Coupon) => {
        setSubscriptionSummary((prevState) => {
          return {
            ...prevState,
            coupon: coupon,
          };
        });
      },
      onError: () => {
        setSubscriptionSummary((prevState) => {
          return {
            ...prevState,
            coupon: undefined,
          };
        });
      },
    }
  );
  const overageQuery = useQuery([QueryKeys.overage, identityId], () =>
    fetchOverage(isUser, identityState)
  );
  const billingUsageQuery = useQuery([QueryKeys.billingUsage, identityId], () =>
    fetchBillingUsage(isUser, identityState)
  );

  const [subscriptionSummary, setSubscriptionSummary] =
    useState<SubscriptionSummaryProps>({
      selectedPlanName: "professional",
      selectedInterval: "month",
      currentSubscription: subscriptionQuery.data
        ? subscriptionQuery.data.activeSubscription
        : dummyInitialSub,
      currentOverageLimitDollars: overageQuery.data
        ? overageQuery.data.dollarsLimit
        : -1,
      newSubscriptionOverageLimitDollars: overageQuery.data
        ? overageQuery.data.dollarsLimit
        : -1,
      newPlan:
        getPlan(
          "professional",
          "month",
          subscriptionQuery.data?.availablePlans || []
        ) || dummyInitialNewPlan,
    });

  const [roundedOverageLimit, setRoundedOverageLimit] = useState(
    overageQuery.data ? overageQuery.data.dollarsLimit : 0
  );

  const history = useHistory();

  const freemiumProductId = subscriptionQuery.isSuccess
    ? subscriptionQuery.data.availablePlans.filter(
        (plan) => plan.name.toLowerCase() === "freemium"
      )[0].productId
    : "";
  const professionalProductId = subscriptionQuery.isSuccess
    ? subscriptionQuery.data.availablePlans.filter(
        (plan) => plan.name.toLowerCase() === "professional"
      )[0].productId
    : "";

  if (
    overageQuery.isSuccess &&
    subscriptionSummary.newSubscriptionOverageLimitDollars === -1
  ) {
    setSubscriptionSummary((prevState) => {
      setRoundedOverageLimit(overageQuery.data.dollarsLimit);

      return {
        ...prevState,
        currentOverageLimitDollars: overageQuery.data.dollarsLimit,
        newSubscriptionOverageLimitDollars:
          prevState.newSubscriptionOverageLimitDollars === -1
            ? overageQuery.data.dollarsLimit
            : prevState.newSubscriptionOverageLimitDollars,
      };
    });
  }

  const subscriptionMutation = useMutation(
    (values: UpdateSubscription) =>
      updateSubscription(values, identityState, isUser),
    {
      onSuccess: (updatedSubscription) => {
        queryClient.setQueryData([QueryKeys.subscription, identityId], {
          ...subscriptionQuery.data,
          activeSubscription: updatedSubscription.activeSubscription,
        });

        queryClient.setQueryData([QueryKeys.overage, identityId], {
          ...overageQuery.data,
          dollarsLimit: updatedSubscription.overageLimitDollars,
        });

        enqueueSnackbar(
          subscriptionChangeSnackbarMessage(subscriptionSummary, changeType),
          {
            variant: "success",
          }
        );

        // Redirect
        history.push(Routes.developerDashboard);
      },
    }
  );

  const excessiveOverageCreditsSpend =
    overageQuery.isSuccess &&
    overageQuery.data.creditsSpent *
      (subscriptionSummary.newPlan.overageRate / 1000) >
      1000;
  const isError =
    subscriptionQuery.isError ||
    overageQuery.isError ||
    billingUsageQuery.isError ||
    excessiveOverageCreditsSpend;
  const isLoading =
    subscriptionQuery.isLoading ||
    overageQuery.isLoading ||
    billingUsageQuery.isLoading;
  const isSuccess =
    subscriptionQuery.isSuccess &&
    overageQuery.isSuccess &&
    billingUsageQuery.isSuccess;
  const hasScheduledDowngrade: boolean =
    subscriptionQuery.isSuccess &&
    subscriptionQuery.data.activeSubscription.nextSub !== null;
  const changeType = getChangeType(
    subscriptionSummary.newPlan,
    subscriptionSummary.currentSubscription.plan
  );

  const [expanded, setExpanded] = useState<string | false>("panel1");

  const openPanel =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const { show } = useIntercom();

  // Styling
  const theme = useTheme();
  return (
    <Box sx={{ position: "relative", top: "-70px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
          gap: "16px",
          height: "43px",
        }}
      >
        <BackButton />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginBottom: isError ? "16px" : "0px",
        }}
      >
        <ReactQueryErrorWrapper
          queries={[subscriptionQuery, overageQuery, billingUsageQuery]}
          mutations={[couponMutation, subscriptionMutation]}
        />

        {overageQuery.isSuccess &&
          excessiveOverageCreditsSpend &&
          changeType === "upgrade" && (
            <Alert
              variant="outlined"
              severity="error"
              action={
                <Button
                  startIcon={<EmailIcon />}
                  size="small"
                  onClick={() => show()}
                >
                  Contact Us
                </Button>
              }
            >
              {`Your current overage usage of ${overageQuery.data.creditsSpent.toLocaleString()} credits is greater than the threshold for self-service upgrades. Please contact Geoscape Support to manage your upgrade.`}
            </Alert>
          )}
      </Box>

      <Box
        style={{
          display: "flex",
          gap: "16px",
          position: "relative",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            position: "relative",
          }}
        >
          {isLoading && (
            <Skeleton variant="rectangular" height="240px"></Skeleton>
          )}

          {subscriptionQuery.isSuccess &&
            overageQuery.isSuccess &&
            billingUsageQuery.isSuccess && (
              <Formik<UpdateSubscription>
                initialValues={{
                  productId: professionalProductId,
                  overageLimitDollars: overageQuery.isSuccess
                    ? overageQuery.data.dollarsLimit
                    : 0,
                  cadence: "month",
                }}
                onSubmit={(values) => {
                  subscriptionMutation.reset();
                  subscriptionMutation.mutate({
                    ...values,
                    coupon: isCouponValid(values.productId, couponMutation.data)
                      ? couponMutation.data?.id
                      : undefined,
                    overageLimitDollars: roundedOverageLimit,
                  });
                }}
              >
                {({
                  values: { productId, overageLimitDollars, cadence },
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleReset,
                  errors,
                  touched,
                }) => {
                  return (
                    <Form>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={openPanel("panel1")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>1. Choose Your Plan</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "16px", paddingBottom: "8px" }}
                        >
                          <FormGroup
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                            }}
                          >
                            <ListItemButton
                              id="planNameFreemium"
                              sx={{ padding: "0px" }}
                              onClick={(event) => {
                                setSubscriptionSummary((prevState) => {
                                  return {
                                    ...prevState,
                                    selectedPlanName: "Freemium",
                                    selectedInterval: "month",
                                    newPlan: getPlan(
                                      "Freemium",
                                      "month",
                                      subscriptionQuery.data.availablePlans
                                    ),
                                  };
                                });
                                setFieldValue("cadence", "month");
                                setFieldValue("productId", freemiumProductId);
                              }}
                            >
                              <Card
                                sx={{
                                  ...selectedStyling(
                                    productId === freemiumProductId,
                                    theme.palette.text.primary
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CardHeader
                                  title="Freemium"
                                  subheader="Your starting point to building spatially enabled applications."
                                />
                                <CardContent>
                                  <ul style={{ margin: "0px" }}>
                                    <li>No credit card needed</li>
                                    <li>20k credits a month</li>
                                  </ul>
                                </CardContent>
                              </Card>
                            </ListItemButton>

                            <ListItemButton
                              id="planNameProfessional"
                              sx={{ padding: "0px" }}
                              onClick={(event) => {
                                setSubscriptionSummary((prevState) => {
                                  return {
                                    ...prevState,
                                    selectedPlanName: "Professional",
                                    newPlan: getPlan(
                                      "Professional",
                                      subscriptionSummary.selectedInterval,
                                      subscriptionQuery.data.availablePlans
                                    ),
                                  };
                                });
                                setFieldValue(
                                  "productId",
                                  professionalProductId
                                );
                              }}
                            >
                              <Card
                                sx={{
                                  ...selectedStyling(
                                    productId === professionalProductId,
                                    theme.palette.text.primary
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CardHeader
                                  title="Professional"
                                  subheader="Everything you need to support professional applications with peace of mind."
                                />
                                <CardContent>
                                  <ul style={{ margin: "0px" }}>
                                    <li>
                                      Access to MailPoint (AMAS Certified
                                      Address Validation)
                                    </li>
                                    <li>Volume discounting</li>
                                    <li>Increased rate limits</li>
                                    <li>Email Support</li>
                                  </ul>
                                </CardContent>
                              </Card>
                            </ListItemButton>

                            <Card>
                              <CardHeader
                                title="Enterprise"
                                subheader="Built for scale and flexibility, access to additional APIs, alternative deployment platters and SLA/Support Contracts."
                              />
                              <CardActions>
                                <Button
                                  fullWidth
                                  startIcon={<EmailIcon />}
                                  variant="outlined"
                                  size="small"
                                  onClick={() => show()}
                                >
                                  Contact Us
                                </Button>
                              </CardActions>
                            </Card>
                          </FormGroup>
                        </AccordionDetails>

                        <AccordionActions
                          sx={{ padding: "16px", paddingTop: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            id="panel1-button"
                            onClick={() => setExpanded("panel2")}
                          >
                            Continue
                          </Button>
                        </AccordionActions>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel2"}
                        onChange={openPanel("panel2")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>2. Overage Budgets</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Card>
                            <CardHeader
                              titleTypographyProps={{ variant: "h5" }}
                              subheaderTypographyProps={{ variant: "body2" }}
                              subheader="The Overage Budget allows you to go over your plan up to a specified amount. The Overage Budget resets monthly"
                            />
                            <CardContent>
                              <OverageInput
                                selectedAvailableSubscriptionName={
                                  subscriptionSummary.newPlan.name
                                }
                                selectedSubscriptionInterval={
                                  subscriptionSummary.newPlan.interval
                                }
                                value={overageLimitDollars}
                                roundedOverageLimit={roundedOverageLimit}
                                setRoundedOverageLimit={setRoundedOverageLimit}
                                setFieldValue={setFieldValue}
                                setSubscriptionSummary={setSubscriptionSummary}
                              />
                            </CardContent>
                          </Card>
                        </AccordionDetails>
                        <AccordionActions
                          sx={{ padding: "16px", paddingTop: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            id="panel2-button"
                            onClick={() => setExpanded("panel3")}
                          >
                            Continue
                          </Button>
                        </AccordionActions>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel3"}
                        onChange={openPanel("panel3")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>3. Contract Term</Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "16px", paddingBottom: "8px" }}
                        >
                          <FormGroup
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "16px",
                            }}
                          >
                            <ListItemButton
                              id="cadenceYear"
                              disabled={
                                subscriptionSummary.selectedPlanName.toLowerCase() ===
                                "freemium"
                              }
                              sx={{ padding: "0px" }}
                              onClick={(event) => {
                                setSubscriptionSummary((prevState) => {
                                  return {
                                    ...prevState,
                                    selectedInterval: "year",
                                    newPlan: getPlan(
                                      subscriptionSummary.selectedPlanName,
                                      "year",
                                      subscriptionQuery.data.availablePlans
                                    ),
                                  };
                                });
                                setFieldValue("cadence", "year");
                              }}
                            >
                              <Card
                                sx={{
                                  ...selectedStyling(
                                    cadence === "year",
                                    theme.palette.text.primary
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CardHeader
                                  title="Annual"
                                  subheader="Get 2 months free by committing to a 12-month plan."
                                />
                              </Card>
                            </ListItemButton>

                            <ListItemButton
                              id="cadenceMonth"
                              sx={{ padding: "0px" }}
                              onClick={(event) => {
                                setSubscriptionSummary((prevState) => {
                                  return {
                                    ...prevState,
                                    selectedInterval: "month",
                                    newPlan: getPlan(
                                      subscriptionSummary.selectedPlanName,
                                      "month",
                                      subscriptionQuery.data.availablePlans
                                    ),
                                  };
                                });
                                setFieldValue("cadence", "month");
                              }}
                            >
                              <Card
                                sx={{
                                  ...selectedStyling(
                                    cadence === "month",
                                    theme.palette.text.primary
                                  ),
                                  width: "100%",
                                }}
                              >
                                <CardHeader
                                  title="Monthly"
                                  subheader="month-to-month contract"
                                />
                              </Card>
                            </ListItemButton>
                          </FormGroup>
                        </AccordionDetails>

                        <AccordionActions
                          sx={{ padding: "16px", paddingTop: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            id="panel3-button"
                            onClick={() => setExpanded("panel4")}
                          >
                            Continue
                          </Button>
                        </AccordionActions>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel4"}
                        onChange={openPanel("panel4")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>4. Promotional Code</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TextField
                            sx={{ mb: 1 }}
                            fullWidth
                            variant="outlined"
                            disabled={changeType === "downgrade"}
                            onChange={(e) => {
                              couponMutation.reset();
                              setSubscriptionSummary((prevState) => {
                                return {
                                  ...prevState,
                                  coupon: undefined,
                                };
                              });
                              setCoupon(e.target.value);
                            }}
                            value={coupon}
                            size="small"
                            id="promotional-code"
                            InputProps={{
                              endAdornment: (
                                <>
                                  {couponMutation.isSuccess &&
                                    isCouponValid(
                                      productId,
                                      couponMutation.data
                                    ) && <CheckCircleIcon color="success" />}
                                  {couponMutation.isError && (
                                    <CancelIcon color="error" />
                                  )}
                                  {couponMutation.isSuccess &&
                                    !isCouponValid(
                                      productId,
                                      couponMutation.data
                                    ) && <CancelIcon color="error" />}
                                  <Button
                                    disabled={
                                      !!!coupon ||
                                      changeType === "downgrade" ||
                                      couponMutation.isLoading
                                    }
                                    onClick={() =>
                                      couponMutation.mutate(coupon)
                                    }
                                    size="small"
                                  >
                                    Apply
                                  </Button>
                                </>
                              ),
                            }}
                            label="Code"
                          />
                          {couponMutation.isSuccess &&
                            isCouponValid(productId, couponMutation.data) && (
                              <Alert variant="outlined" severity="success">
                                {generateCouponMessage(couponMutation.data)}
                              </Alert>
                            )}
                          {couponMutation.isError && (
                            <Alert variant="outlined" severity="error">
                              {
                                (
                                  couponMutation.error as ReactQueryMutationError
                                ).message
                              }
                            </Alert>
                          )}
                          {couponMutation.isSuccess &&
                            !isCouponValid(productId, couponMutation.data) && (
                              <Alert variant="outlined" severity="error">
                                Coupon code cannot be applied to the selected
                                subscription
                              </Alert>
                            )}
                        </AccordionDetails>
                        <AccordionActions
                          sx={{ padding: "16px", paddingTop: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            id="panel4-button"
                            onClick={() => setExpanded("panel5")}
                          >
                            Continue
                          </Button>
                        </AccordionActions>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel5"}
                        onChange={openPanel("panel5")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>5. Payment Method</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <PaymentMethodInfo isUserPage={isUser} />
                        </AccordionDetails>
                        <AccordionActions
                          sx={{ padding: "16px", paddingTop: "8px" }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            id="panel5-button"
                            onClick={() => setExpanded("panel6")}
                          >
                            Continue
                          </Button>
                        </AccordionActions>
                      </Accordion>

                      <Accordion
                        expanded={expanded === "panel6"}
                        onChange={openPanel("panel6")}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          <Typography>6. Review & Confirm</Typography>
                        </AccordionSummary>

                        {excessiveOverageCreditsSpend &&
                        changeType === "upgrade" ? (
                          <>
                            <AccordionDetails>
                              <Alert variant="outlined" severity="error">
                                <AlertTitle>
                                  You have pending overage
                                </AlertTitle>
                                Your account has pending overage from your
                                current subscription. We are unable to resolve
                                the pending overage automatically. Please
                                contact support who will help you through the
                                upgrade.
                              </Alert>
                            </AccordionDetails>
                            <AccordionActions
                              sx={{ padding: "16px", paddingTop: "8px" }}
                            >
                              <Button
                                variant="contained"
                                startIcon={<EmailIcon />}
                                size="small"
                                onClick={() => show()}
                              >
                                Contact Us
                              </Button>
                            </AccordionActions>
                          </>
                        ) : getChangeType(
                            subscriptionSummary.newPlan,
                            subscriptionSummary.currentSubscription.plan
                          ) === "same" ? (
                          <>
                            <AccordionDetails>
                              <Alert variant="outlined" severity="error">
                                <AlertTitle>No changes to make!</AlertTitle>
                                New subscription is the same as the current
                                subscription. There are no changes to make.
                                Please contact support if you have any queries.
                              </Alert>
                            </AccordionDetails>
                            <AccordionActions
                              sx={{ padding: "16px", paddingTop: "8px" }}
                            >
                              <Button
                                variant="contained"
                                startIcon={<EmailIcon />}
                                size="small"
                                onClick={() => show()}
                              >
                                Contact Us
                              </Button>
                            </AccordionActions>
                          </>
                        ) : hasScheduledDowngrade &&
                          subscriptionQuery.data.activeSubscription.nextSub &&
                          subscriptionQuery.data.activeSubscription.nextSub ? (
                          <>
                            <AccordionDetails>
                              <Alert variant="outlined" severity="error">
                                <AlertTitle>Downgrade Pending</AlertTitle>
                                There is already an account downgrade scheduled
                                from{" "}
                                {
                                  subscriptionQuery.data.activeSubscription.plan
                                    .name
                                }{" "}
                                {
                                  intervalFormatted[
                                    subscriptionQuery.data.activeSubscription
                                      .plan.interval
                                  ]
                                }{" "}
                                to{" "}
                                {
                                  subscriptionQuery.data.activeSubscription
                                    .nextSub?.plan.name
                                }{" "}
                                {
                                  intervalFormatted[
                                    subscriptionQuery.data.activeSubscription
                                      .nextSub.plan.interval
                                  ]
                                }
                                . This will take effect on{" "}
                                {dayjs(
                                  subscriptionQuery.data.activeSubscription
                                    .nextSub.startTime * 1000
                                ).format("D MMM YYYY")}
                                . To modify your subscription before this date,
                                cancel the downgrade or contact support.
                              </Alert>
                            </AccordionDetails>
                            <AccordionActions
                              sx={{ padding: "16px", paddingTop: "8px" }}
                            >
                              <Button
                                variant="contained"
                                startIcon={<EmailIcon />}
                                size="small"
                                onClick={() => show()}
                              >
                                Contact Us
                              </Button>
                            </AccordionActions>
                          </>
                        ) : (
                          <>
                            <AccordionDetails>
                              <Card style={{ border: "1px solid #616161" }}>
                                {changeType === "upgrade" ? (
                                  <CardHeader
                                    title="Effective changes as of today"
                                    subheader="These changes will apply immediately, and you will be charged today."
                                  />
                                ) : (
                                  <CardHeader
                                    title={`Effective changes as of ${calculateStartDate(
                                      subscriptionSummary,
                                      changeType === "downgrade"
                                    )}`}
                                    subheader={`Payment and change of plan will take effect at the end of the current billing cycle`}
                                  />
                                )}
                                <CardContent>
                                  {overageQuery.data.creditsSpent !== 0 &&
                                    changeType === "upgrade" && (
                                      <Alert
                                        variant="outlined"
                                        severity="warning"
                                        sx={{ marginBottom: "16px" }}
                                      >
                                        <AlertTitle>
                                          You have pending overage
                                        </AlertTitle>
                                        {`Your account has ${overageQuery.data.creditsSpent.toLocaleString()} credits of pending overage from your current subscription. We will deduct your pending overage from your new credit balance. Removing the pending overage charge.`}
                                      </Alert>
                                    )}

                                  <Typography variant="h6">
                                    Subscription
                                  </Typography>
                                  {OrderSummaryRow(
                                    `Subscription to ${subscriptionSummary.newPlan.name} plan`,
                                    ""
                                  )}
                                  {OrderSummaryRow(
                                    orderSummaryStartDateText(
                                      subscriptionSummary,
                                      changeType === "downgrade"
                                    ),
                                    ""
                                  )}
                                  {OrderSummaryRow(
                                    calculateEndDate(
                                      subscriptionSummary,
                                      changeType === "downgrade"
                                    ),
                                    ""
                                  )}
                                  {OrderSummaryRow(
                                    "Starting Credit Balance",
                                    changeType === "upgrade"
                                      ? summaryStartingCredits(
                                          subscriptionSummary,
                                          billingUsageQuery.data
                                            .creditsUsedTotal,
                                          overageQuery.data.creditsSpent
                                        )
                                      : subscriptionSummary.newPlan.creditAmount.toLocaleString()
                                  )}
                                  {subscriptionSummary.currentSubscription.plan
                                    .creditAmount -
                                    billingUsageQuery.data.creditsUsedTotal >
                                    0 && changeType === "upgrade" ? (
                                    OrderSummaryRow(
                                      "Upgrade Rollover Balance (One-off)",
                                      (
                                        billingUsageQuery.data.allowance -
                                        billingUsageQuery.data.creditsUsedTotal
                                      ).toLocaleString()
                                    )
                                  ) : (
                                    <></>
                                  )}
                                  {OrderSummaryRow(
                                    "Overage Budget",
                                    `Up to $${subscriptionSummary.newSubscriptionOverageLimitDollars.toLocaleString()}`
                                  )}

                                  <Typography
                                    variant="h6"
                                    sx={{ marginTop: "8px" }}
                                  >
                                    Charges
                                  </Typography>
                                  {OrderSummaryRow(
                                    "Previous Subscription Overage",
                                    `${formatPrice(
                                      overageQuery.data.dollarsSpent
                                    )}`
                                  )}
                                  {overageQuery.data.dollarsSpent !== 0 &&
                                    getChangeType(
                                      subscriptionSummary.newPlan,
                                      subscriptionSummary.currentSubscription
                                        .plan
                                    ) === "upgrade" && (
                                      <>
                                        {OrderSummaryRow(
                                          "Overage Deducted from Starting Credit Balance",
                                          `-${formatPrice(
                                            overageQuery.data.dollarsSpent
                                          )}`
                                        )}
                                      </>
                                    )}
                                  {OrderSummaryRow(
                                    `${
                                      subscriptionSummary.newPlan.interval ===
                                      "month"
                                        ? "Monthly"
                                        : "Annual"
                                    } ${
                                      subscriptionSummary.newPlan.name
                                    } Subscription`,
                                    `$${(
                                      subscriptionSummary.newPlan.price / 100
                                    ).toLocaleString()}`
                                  )}
                                  {isCouponValid(
                                    productId,
                                    couponMutation.data
                                  ) &&
                                    couponMutation.data?.valid &&
                                    OrderSummaryRow(
                                      couponMutation.data.name,
                                      `-$${calculateCouponDiscount(
                                        subscriptionSummary.newPlan.price / 100,
                                        productId,
                                        couponMutation.data
                                      ).toFixed(2)}`
                                    )}
                                  {OrderSummaryRow(
                                    "GST (10% Inclusive)",
                                    `$${(
                                      (subscriptionSummary.newPlan.price / 100 -
                                        Math.min(
                                          calculateCouponDiscount(
                                            subscriptionSummary.newPlan.price /
                                              100,
                                            productId,
                                            couponMutation.data
                                          ),
                                          subscriptionSummary.newPlan.price /
                                            100
                                        )) /
                                      11
                                    ).toFixed(2)}`
                                  )}

                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      fontWeight: "bold",
                                      marginTop: "16px",
                                    }}
                                  >
                                    <Box sx={{ width: "50%" }}>
                                      <Typography variant="h6">
                                        Total Due
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{ width: "50%", textAlign: "right" }}
                                    >
                                      <Typography variant="h6">
                                        {formatPrice(
                                          subscriptionSummary.newPlan.price /
                                            100 -
                                            Math.min(
                                              calculateCouponDiscount(
                                                subscriptionSummary.newPlan
                                                  .price / 100,
                                                productId,
                                                couponMutation.data
                                              ),
                                              subscriptionSummary.newPlan
                                                .price / 100
                                            ),
                                          0
                                        )}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </CardContent>
                              </Card>

                              {subscriptionMutation.isError && (
                                <Alert
                                  variant="outlined"
                                  severity="error"
                                  sx={{ marginTop: "16px" }}
                                >
                                  {
                                    (
                                      subscriptionMutation.error as ReactQueryMutationError
                                    ).message
                                  }
                                </Alert>
                              )}
                            </AccordionDetails>
                            <AccordionActions
                              sx={{ padding: "16px", paddingTop: "8px" }}
                            >
                              <LoadingButton
                                type="submit"
                                size="small"
                                variant="contained"
                                id="panel6-button"
                                loading={subscriptionMutation.isLoading}
                              >
                                Confirm Purchase
                              </LoadingButton>
                            </AccordionActions>
                          </>
                        )}
                      </Accordion>
                    </Form>
                  );
                }}
              </Formik>
            )}
        </Box>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            height: "80%",
            width: "50%",
            position: "relative",
          }}
        >
          {isLoading && (
            <Skeleton variant="rectangular" height="414px"></Skeleton>
          )}

          {isSuccess && <SubscriptionSummary {...subscriptionSummary} />}

          <Card style={{ padding: "16px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2" style={{}}>
                Have a question or need some help?
              </Typography>
              <Button
                style={{
                  textTransform: "none",
                }}
                variant="text"
                size="small"
                onClick={() => show()}
              >
                Ask Support
              </Button>
            </div>
          </Card>

          <Card style={{ padding: "16px" }}>
            <Typography variant="body2" style={{}}>
              Annual subscriptions require a one-year non-cancellable
              commitment. By using Geoscape you agree to{" "}
              <LinkRouter
                target="_blank"
                to={{
                  pathname:
                    "https://geoscape.com.au/legal/geoscape-developer-terms",
                }}
                style={{
                  textDecoration: "none",
                  color: "#90caf9",
                }}
              >
                Terms of Service.
              </LinkRouter>
            </Typography>
          </Card>
        </div>
      </Box>
    </Box>
  );
};

const isCouponValid = (productId: string, coupon?: Coupon): boolean => {
  return (
    !!coupon &&
    !!coupon.applies_to &&
    coupon.applies_to.products.includes(productId)
  );
};

const generateCouponMessage = (coupon: Coupon) => {
  const discount = coupon.amount_off
    ? `$${coupon.amount_off / 100}`
    : `${coupon.percent_off}%`;
  return `${discount} discount has been applied. Duration: ${coupon.duration}`;
};

const calculateCouponDiscount = (
  subscription_price: number,
  productId: string,
  coupon?: Coupon
): number => {
  if (coupon) {
    if (isCouponValid(productId, coupon)) {
      if (coupon.amount_off) return coupon.amount_off / 100;
      if (coupon.percent_off)
        return (subscription_price * coupon.percent_off) / 100;
    }
  }
  return 0;
};

function summaryStartingCredits(
  subscriptionSummary: SubscriptionSummaryProps,
  creditsUsedTotal: number,
  creditsSpent: number
): string {
  const remainingCredits =
    subscriptionSummary.currentSubscription.plan.creditAmount -
    creditsUsedTotal;

  const startingCredits =
    remainingCredits > -1
      ? subscriptionSummary.newPlan.creditAmount.toLocaleString()
      : (
          subscriptionSummary.newPlan.creditAmount - creditsSpent
        ).toLocaleString();

  return startingCredits;
}

function subscriptionChangeSnackbarMessage(
  subscriptionSummary: SubscriptionSummaryProps,
  changeType: PlanChangeType
): string {
  if (changeType === "upgrade") {
    return `You successfully changed your subscription to ${
      subscriptionSummary.newPlan.name
    } ${intervalFormatted[subscriptionSummary.newPlan.interval]}.`;
  }
  return `You successfully scheduled a downgrade to ${
    subscriptionSummary.newPlan.name
  } ${
    intervalFormatted[subscriptionSummary.newPlan.interval]
  }, effective ${calculateStartDate(
    subscriptionSummary,
    true
  )}. You will have access to all your current plans features until this time.`;
}

function calculateStartDate(
  subscriptionSummary: SubscriptionSummaryProps,
  downgrade: boolean
): string {
  let startDate;

  if (!downgrade) {
    startDate = dayjs();
  } else {
    startDate = dayjs(
      subscriptionSummary.currentSubscription.renewalDate * 1000
    );
  }
  return startDate.format("D MMM YYYY");
}

function orderSummaryStartDateText(
  subscriptionSummary: SubscriptionSummaryProps,
  downgrade: boolean
): string {
  return `Start Date: ${calculateStartDate(subscriptionSummary, downgrade)}`;
}

function calculateEndDate(
  subscriptionSummary: SubscriptionSummaryProps,
  downgrade: boolean
): string {
  let endDate;
  if (!downgrade) {
    endDate = dayjs().add(1, subscriptionSummary.newPlan.interval);
  } else {
    endDate = dayjs(
      subscriptionSummary.currentSubscription.renewalDate * 1000
    ).add(1, subscriptionSummary.newPlan.interval);
  }
  return `Automatically Renews: ${endDate.format("D MMM YYYY")}`;
}
