import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { ClipConfigProps, ClipStepsControlProps } from "../../clip-menu";
import { useS3FileUploadDropper } from "pages/geoscape-admin/custom-datasets/custom-hook/customHooks";
import { UploadFile, Warning } from "@mui/icons-material";
import { FileWithPath } from "react-dropzone";
import { useEffect, useState } from "react";
import { area, featureCollection } from "@turf/turf";
import { FileUploadProgress } from "components/upload/FileUploadProgress";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import { resolveIdentityId } from "utils/identity";
import { useMutation } from "react-query";
import { submitClipGeometryFile } from "data/mutations";
import { GeoJSONFeatureCollection } from "../../models";
import { BoundaryItem } from "./boundary-item";
import { BoundaryToolProps } from "./define-boundary";
import {
  zoomToFeature,
  zoomToFeatureCollection,
} from "pages/geoscape-data/explorer/utils";
import { useCustomSnackbars } from "components/snackbars/useCustomSnackbars";

type UploadStates = "ready" | "uploading" | "processing" | "complete";

const uploadBucket =
  import.meta.env.VITE_ENVIRONMENT === "production"
    ? "prod-hub-clip-storage"
    : "preprod-hub-clip-storage";

export const UploadComponent = (
  props: ClipConfigProps & ClipStepsControlProps & BoundaryToolProps
) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const [uploadState, setUploadState] = useState<UploadStates>("ready");
  const [featureExtractCount, setFeatureExtractCount] = useState(0);
  const [warnings, setWarnings] = useState<string[]>([]);

  const {
    uploadProgress,
    isUploading,
    haveUploaded,
    files,
    removeFile,
    getRootProps,
    getInputProps,
    uploadFiles,
  } = useS3FileUploadDropper({
    maxSize: 10 * 1024 * 1024, //10mb
    maxFiles: 1,
    prefixFileName: `${identityId}/raw_input/`,
    acceptExtension: {
      "application/zip": [".zip"],
      "application/x-zip-compressed": [".zip"],
    },
  });

  useEffect(() => {
    if (files.length > 0 && !isUploading) {
      uploadFiles(uploadBucket);
      setUploadState("uploading");
    }
  }, [files]);

  const { enqueueMutationFailed } = useCustomSnackbars();

  const submitFileMutation = useMutation(
    (s3Key: string) => submitClipGeometryFile(s3Key, identityState),
    {
      onSuccess: (fc: GeoJSONFeatureCollection) => {
        setUploadState("complete");
        setWarnings(fc.messages ?? []);
        setFeatureExtractCount(fc.features.length);
        props.addOrUpdateFeatures(fc.features);
        fc.features.map((f) => {
          props.draw.add(f);
        });
        zoomToFeatureCollection(props.mapRef, fc);
      },
      onError: (error: Error) => {
        setUploadState("complete");
        enqueueMutationFailed(error.toString());
      },
    }
  );
  const cancelUpload = (path: string) => {
    removeFile(path);
    setUploadState("ready");
  };
  useEffect(() => {
    if (haveUploaded === true) {
      setUploadState("processing");
      const uploadedFileKey = `${identityId}/raw_input/${files[0].name}`;
      submitFileMutation.mutate(uploadedFileKey);
      files.forEach((x) => {
        removeFile(x.name);
      });
    }
  }, [haveUploaded]);

  return (
    <Box>
      {uploadState === "ready" && (
        <>
          <Box
            style={{
              border: "dotted",
              borderColor: "grey",
              margin: "8px",
              padding: "8px",
              textAlign: "center",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input disabled={true} {...getInputProps()} />
            <UploadFile color="info" />
            <Typography>Click to upload or drag and drop file</Typography>
            <Typography variant="body2">*Max file size is 10 MB</Typography>
            <Typography></Typography>
          </Box>
        </>
      )}

      {uploadState === "uploading" && (
        <FileUploadProgress
          uploadProgress={uploadProgress}
          file={files[0]}
          onRemove={cancelUpload}
          isUploading={isUploading}
          haveUploaded={haveUploaded}
        />
      )}
      {uploadState === "processing" && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Processing file</Typography>
          <CircularProgress />
        </Box>
      )}
      {uploadState === "complete" &&
        warnings.length > 0 &&
        warnings.map((x) => (
          <Alert icon={<Warning />} color="warning">
            {x}
          </Alert>
        ))}
      {uploadState === "complete" &&
        (featureExtractCount > 0 ? (
          <Alert>
            {" "}
            {featureExtractCount} feature(s) extracted successfully
          </Alert>
        ) : (
          <Alert icon={<Warning />} color="error">
            No features could be extracted
          </Alert>
        ))}
      {uploadState === "complete" && (
        <Button
          onClick={() => {
            setUploadState("ready");
          }}
        >
          Upload again
        </Button>
      )}
    </Box>
  );
};
