import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Skeleton,
  Typography,
} from "@mui/material";
import { DashboardCards } from "./dasboard-cards";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { GetUserIfLoggedIn, useIdentity } from "contexts/identity-context";

export const HubPage = () => {
  const history = useHistory();
  const redirectPath = localStorage.getItem("redirectPath");
  const query = localStorage.getItem("query");
  const [identityState] = useIdentity();
  const user = GetUserIfLoggedIn(identityState);

  if (redirectPath && query) {
    history.push({
      pathname: redirectPath,
      search: query,
    });

    localStorage.removeItem("redirectPath");
    localStorage.removeItem("query");
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        height: "700px",
        position: "relative",
      }}
    >
      <Typography variant="h4">
        {`Hello ${
          user ? user.firstName : ""
        }, what would you like to do today?`}
      </Typography>
      <DashboardCards />
    </Box>
  );
};
