import { Card, Chip } from "@mui/material";

import { useHistory } from "react-router-dom";
import { formatTimestamp } from "utils";
import CustomTable from "components/shared/table";
import { generatePath } from "react-router-dom";
import { Release, ReleaseStatus } from "data/models";
import * as Routes from "routes";
import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";

const keysToRows = (keys: Release[]) => {
  const keys_ = keys.map((key) => {
    return {
      id: key.key.replace(" ", "-"),
      publish_date: key.publish_date,
      name: key.name,
      year: key.year,
      month: key.month,
      products: key.products,
      total_files: key.staged
        .map((x) => {
          return x.success_count;
        })
        .reduce((a, b) => {
          return a + b;
        }, 0),
      status: key.status,
      release: key,
    };
  });
  return keys_;
};

const renderProductChips = (products: string[]) => {
  let extraProducts: string[] = [];
  return (
    <>
      {products.map((product: string, index: number) => {
        if (index < 2) return <Chip key={product} label={product} />;
        extraProducts.push(product);
        return <></>;
      })}
      {extraProducts.length > 0 && (
        <Chip
          title={extraProducts.join(", ")}
          label={"+" + extraProducts.length}
        />
      )}
    </>
  );
};

const renderStatusChip = (status: ReleaseStatus) => {
  if (status === "draft") return <Chip label={"Draft"} color="secondary" />;
  else if (status === "internal")
    return <Chip label={"Internal"} color="info" />;
  else if (status === "published")
    return <Chip label={"Published"} color="success" />;
  else if (status === "staged")
    return <Chip label={"Staged"} color="primary" />;
  else return <Chip label={status} />;
};

const renderPublishDate = (publish_date: string) => {
  if (!!publish_date) return formatTimestamp(Number(publish_date));
  return "...";
};

const columns: GridColDef[] = [
  {
    flex: 1 / 2,
    field: "publish_date",
    headerName: "Publish Date",
    renderCell: (params: any) => renderPublishDate(params.row.publish_date),
  },
  {
    flex: 1 / 2,
    field: "name",
    headerName: "Release Name",
  },
  {
    flex: 1 / 2,
    field: "year",
    headerName: "Year",

    // renderCell: (params: any) => formatTimestamp(params.row.year),
  },
  {
    field: "month",
    headerName: "Month",
    renderCell: (params: any) => {
      let d = new Date();
      d.setMonth(params.row.month - 1);
      let fmtted = d.toLocaleString("en-US", { month: "long" });
      return fmtted;
    },
  },
  {
    flex: 2,
    field: "products",
    headerName: "Products",

    renderCell: (params: any) => {
      return renderProductChips(params.row.products);
    },
  },
  {
    flex: 1 / 2,
    field: "total_files",
    headerName: "Total Files",
  },
  {
    flex: 1 / 2,
    field: "status",
    headerName: "Status",

    disableColumnMenu: true,
    editable: false,
    // width: 200,

    renderCell: (params: any) => renderStatusChip(params.row.status),
  },
];

interface ReleaseTableProps {
  data?: Release[];
  isLoading: boolean;
}

export const ReleasesTable = (props: ReleaseTableProps) => {
  const history = useHistory();
  const handleOnRowClick = (params: GridRowParams) => {
    history.push(
      generatePath(Routes.adminEditRelease, {
        releaseKey: params.id,
      }),
      params.row.release
    );
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <CustomTable
        rows={props.data ? keysToRows(props.data) : []}
        cols={columns}
        handleOnRowClick={handleOnRowClick}
        isLoading={props.isLoading}
        overlayText={"No releases to display."}
      />
    </Card>
  );
};
