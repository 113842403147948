import { Box } from "@mui/material";

export const DataPage = () => {
  return (
    <Box
      style={{
        display: "flex",
        gap: "24px",
        height: "700px",
        position: "relative",
      }}
    ></Box>
  );
};
