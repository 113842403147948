import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  BatchConfig,
  CheckedItems,
  OutputFormat,
  SetCheckedItems,
} from "pages/geoscape-batch/models";
import {
  OutputBatchConfigFormat,
  SetBatchConfig,
} from "pages/geoscape-batch/types";
import React, { memo, useCallback, useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";

import {
  MANDATORY_ATTRIBUTES,
  PROPERTIES_DEFINITION,
  addIndex,
  transformString,
} from "./utils";

interface AccordionProps {
  outPutFormatDefinition: OutputBatchConfigFormat[];
  batchConfig: BatchConfig;
  title: string;
  setBatchConfig: SetBatchConfig;
  checkedItems: CheckedItems;
  setCheckedItems: SetCheckedItems;
  propertyCheckedItems: any;
  enrichmentRows: any;
  setEnrichmentRows: any;
}

export const AccordionComponent: React.FC<AccordionProps> = ({
  outPutFormatDefinition,
  batchConfig,
  title,
  setBatchConfig,
  checkedItems,
  setCheckedItems,
  enrichmentRows,
  setEnrichmentRows,
  propertyCheckedItems,
}) => {
  const [selectAll, setSelectAll] = React.useState(false);
  const [selectAllDisable, setselectAllDisable] = useState(false);

  useEffect(() => {
    if (propertyCheckedItems) {
      const allChecked = outPutFormatDefinition.every(
        (option) => propertyCheckedItems[option.attributeName]
      );

      const allMandatory = outPutFormatDefinition.every((option) =>
        MANDATORY_ATTRIBUTES[title]?.includes(option.attributeName)
      );

      setSelectAll(allChecked);

      setCheckedItems((prev: CheckedItems) => ({
        ...prev,
        [title]: propertyCheckedItems,
      }));

      if (allChecked && allMandatory) setselectAllDisable(true); // Disabled sellect all
    } else {
      setSelectAll(false);
    }
  }, [batchConfig.hubInfo.checkedItems[title]]);

  const handleCheckboxChange = useCallback(
    (
      attributeName: string,
      accordionKey: string,
      index: number,
      checked: boolean
    ) => {
      setCheckedItems((prev: CheckedItems) => ({
        ...prev,
        [title]: { ...prev[title], [`${attributeName}`]: checked },
      }));

      const option = outPutFormatDefinition[index];

      setEnrichmentRows((prev: OutputFormat[]) => {
        return checked
          ? addIndex([...prev, option])
          : addIndex(
              prev.filter(
                (item: OutputFormat) =>
                  item.attributeName !== option.attributeName ||
                  item.source !== option.source
              )
            );
      });
    },
    [outPutFormatDefinition, setBatchConfig, title]
  );

  useEffect(() => {
    setSelectAll(
      checkedItems[title] && Object.values(checkedItems[title]).every(Boolean)
    );
  }, [checkedItems[title]]);

  const handleSelectAllChange = useCallback(
    (event: any) => {
      const isChecked = event.target.checked;
      setSelectAll(isChecked);

      const newCheckedItems: { [key: string]: boolean } = {};

      if (outPutFormatDefinition) {
        const mandatoryAttributes = outPutFormatDefinition.filter((option) =>
          MANDATORY_ATTRIBUTES[title]?.includes(option.attributeName)
        );

        outPutFormatDefinition.forEach((option) => {
          newCheckedItems[option.attributeName] = isChecked;
        });

        mandatoryAttributes.forEach((option) => {
          newCheckedItems[option.attributeName] = true;
        });
      }

      setCheckedItems((prev: CheckedItems) => ({
        ...prev,
        [title]: newCheckedItems, // assuming you're toggling the boolean value
      }));

      const attributesPropertyOutput =
        outPutFormatDefinition.filter(
          // The mandatory attributes are discarted
          (option) =>
            !MANDATORY_ATTRIBUTES[title]?.includes(option.attributeName)
        ) || []; // Outpu.format came from the backend

      setEnrichmentRows((prev: OutputFormat[]) => {
        const currentOutputFormat: OutputFormat[] = prev.filter(
          (item: OutputFormat) =>
            attributesPropertyOutput.some(
              (option) =>
                option.attributeName === item.attributeName &&
                item.property == option.property
            )
        ); // Attributes from the property that are already in the Enrichment

        const result = attributesPropertyOutput.filter(
          // We add the outomes if it is not
          (option) =>
            !currentOutputFormat.some(
              (item: OutputFormat) =>
                item.attributeName === option.attributeName
            )
        );

        return isChecked
          ? addIndex([
              ...prev,
              ...attributesPropertyOutput.filter(
                // We add the outomes if it is not
                (option) =>
                  !currentOutputFormat.some(
                    (item: OutputFormat) =>
                      item.attributeName === option.attributeName &&
                      item.property == option.property
                  )
              ),
            ])
          : addIndex(
              prev.filter(
                (item: OutputFormat) =>
                  !attributesPropertyOutput.some(
                    (option) =>
                      option.attributeName === item.attributeName &&
                      item.property == option.property
                  )
              )
            );
      });
    },

    [outPutFormatDefinition, setBatchConfig, title]
  );

  return (
    <Accordion disableGutters sx={{ marginBottom: 0, gap: 0 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${title}-content`}
        id={`panel-${title}-header`}
        sx={{ margin: 0 }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography>{transformString(title)}</Typography>
            {title == "solarPanelBundle" ? (
              <InfoIcon fontSize="small" color="primary" style={{ marginLeft: "4px" }} />
            ) : (
              <></>
            )}
          </Box>
          {checkedItems[title] &&
            Object.values(checkedItems[title]).filter(Boolean).length !== 0 && (
              <Chip
                label={`${
                  Object.values(checkedItems[title]).filter(Boolean).length
                } attributes selected`}
                variant="filled"
                color="primary"
              />
            )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{PROPERTIES_DEFINITION[title]}</Typography>
        {title == "seifa" ? <Overview /> : ""}

        <FormGroup sx={{ marginLeft: "10px", marginBottom: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={selectAllDisable}
                checked={selectAll}
                onChange={handleSelectAllChange}
                indeterminate={
                  checkedItems[title] &&
                  Object.values(checkedItems[title]).filter(Boolean).length >
                    0 &&
                  Object.values(checkedItems[title]).filter(Boolean).length <
                    outPutFormatDefinition.length
                }
              />
            }
            label="Select All"
          />
        </FormGroup>

        {title == "solarPanelBundle" ? (
          <Alert variant="filled" severity="info" sx={{marginBottom: "10px"}}>
            3 Additional Credits Per Address Feature
          </Alert>
        ) : (
          <></>
        )}

        <Divider />

        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",

            gap: "0px",
            width: "100%",
          }}
        >
          {outPutFormatDefinition.map((option, index) => (
            <div key={index} style={{ width: "48%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!checkedItems[title]?.[option.attributeName] || false
                    }
                    disabled={
                      MANDATORY_ATTRIBUTES[title]?.includes(
                        option.attributeName
                      )
                        ? true
                        : false
                    }
                    onChange={(event) =>
                      handleCheckboxChange(
                        option.attributeName,
                        title,
                        index,
                        event.target.checked
                      )
                    }
                  />
                }
                label={transformString(option.attributeName)}
              />
            </div>
          ))}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

const Overview = () => {
  return (
    // <Box>
    <List sx={{ padding: "0" }}>
      <ListItem sx={{ padding: "0" }}>
        <ListItemText
          primary="Index of Relative Socio-economic Advantage and Disadvantage (IRSAD)"
          secondary="Measures community well-being through advantage and disadvantage scores. It ranks areas, showing where they stand nationally and by state, in terms of overall socio-economic conditions"
        />
      </ListItem>

      <ListItem sx={{ padding: "0" }}>
        <ListItemText
          primary="Index of Economic Resources (IER)"
          secondary="Assesses areas based on economic resources, highlighting differences in wealth. It includes scores and rankings to identify economic disparities at national and state levels"
        />
      </ListItem>

      <ListItem sx={{ padding: "0" }}>
        <ListItemText
          primary="Index of Education and Occupation (IEO)"
          secondary="Evaluates education levels and occupational status, offering insights into educational and job opportunities. Rankings help compare areas' educational and work-related achievements"
        />
      </ListItem>

      <ListItem sx={{ padding: "0" }}>
        <ListItemText
          primary="General Understanding (For Deciles and Percentiles)"
          secondary="Each theme uses deciles and percentiles to simplify comparisons. Deciles divide areas into ten parts based on their scores—higher deciles mean better standing. Percentiles offer a more precise position, with higher values indicating top ranks"
        />
      </ListItem>
    </List>
    // </Box>
  );
};
