import { Grid, Stack } from "@mui/material";
import addressAutocomplete from "icons/addressAutocomplete.png";
import emissionsMetric from "icons/emissionsMetric.jpg";
import localityAutocomplete from "icons/localityAutocomplete.png";
import * as Routes from "routes";
import { DemoCard } from "./demo-card";

export interface DemoItem {
  id: string;
  title: string;
  type: string;
  categories: string[];
  description: string;
  path: string;
  thumbnail: string;
  public: boolean;
}

export const DemosPage = () => {
  const dataDemos: DemoItem[] = [
    {
      id: "emissionsMetric",
      title: "Emissions Metric",
      type: "Prototype",
      categories: ["Climate"],
      description:
        "Visualise emissions metrics for property portfolios, supporting environmental compliance and informed decision making.",
      path: Routes.emissionsMetric,
      thumbnail: emissionsMetric,
      public: true,
    },
    {
      id: "addressAutocomplete",
      title: "Address Autocomplete",
      type: "Demo",
      categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
      description:
        "Predictive address auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
      path: Routes.predictiveAddress,
      thumbnail: addressAutocomplete,
      public: true,
    },
    {
      id: "localityAutocomplete",
      title: "Locality Autocomplete",
      type: "Demo",
      categories: ["Risk", "UX", "Service Delivery", "Ecommerce"],
      description:
        "Predictive locality auto-completion with real-time map visualisation. Enhance user experience and operational efficiency.",
      path: Routes.predictiveLocality,
      thumbnail: localityAutocomplete,
      public: true,
    },
  ];

  return (
    <Stack direction="column" gap={2}>
      <Grid container spacing={2}>
        {dataDemos
          .filter((demo) => demo.public)
          .map((demoItem: DemoItem, index: number) => {
            return (
              <Grid item md={12} lg={6} key={index}>
                <DemoCard {...demoItem}></DemoCard>
              </Grid>
            );
          })}
      </Grid>
    </Stack>
  );
};
