// components/UsageCard.js
import { Box, Card, CardContent, CardHeader, Skeleton } from "@mui/material";

const UsageCard = ({ isError, isLoading, isSuccess, totalCredits }: any) => (
    <Card
        style={{
            marginBottom: "auto",
            display: "flex",
            flexDirection: "column",
            width: "20%",
        }}
    >
        {isError && (
            <CardHeader
                style={{ minHeight: "90px" }}
                subheader="Total Credits"
                subheaderTypographyProps={{ variant: "body2" }}
            />
        )}

        {isLoading && (
            <CardContent style={{ minHeight: "90px" }}>
                <Box>
                    <Skeleton variant="rectangular" height="56px" />
                </Box>
            </CardContent>
        )}

        {isSuccess && (
            <CardHeader
                style={{ minHeight: "90px" }}
                variant="primary"
                title={totalCredits.toLocaleString("en-US", {
                    minimumFractionDigits: 1,
                })}
                subheader="Total Credits"
                subheaderTypographyProps={{ variant: "body2" }}
            />
        )}
    </Card>
);

export default UsageCard;
