import * as QueryKeys from "data";
import BlockIcon from "@mui/icons-material/Block";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Skeleton,
  TextField,
  IconButton,
  Select,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  ListItemButton,
  Alert,
} from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

import Checkbox from "@mui/material/Checkbox";
import * as Routes from "routes";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";

import EditIcon from "@mui/icons-material/Edit";
import MenuItem from "@mui/material/MenuItem";

import { ContentCopy } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";

import Card from "@mui/material/Card";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { makeStyles } from "@mui/styles";
import {
  ReactQueryQueryError,
  fetchApiKeys,
  fetchApiProducts,
} from "data/queries";
import { resolveIdentityId } from "utils/identity";
import { Form, Formik } from "formik";
import {
  ReactQueryMutationError,
  deleteApiKey,
  editApiKey,
} from "data/mutations";
import { updateApiKeyValidationSchema } from "./validation";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "components/modals/confirmation-modal";
import { ApiKey, Status } from "data/models";
import BackButton from "components/navigation/back-button";
import { ReactQueryErrorWrapper } from "components/shared/react-query-error-wrapper";

// TODO: Really need to put this somewhere it can be shared
const useStyles = makeStyles({
  noneDisplay: { display: "none" },
  error: { color: "red" },
});

const keyStatus = [
  {
    value: "approved",
    label: "Approved",
    icon: <CheckCircleOutlineIcon color="success" />,
  },
  {
    value: "revoked",
    label: "Revoked",
    icon: <BlockIcon color="warning" />,
  },
];

export const EditApiKey = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  let selectedKey: ApiKey = {
    createdDate: 0,
    expireDate: 0,
    consumerKey: "",
    consumerSecret: "",
    name: "",
    status: "approved",
    products: [],
    notes: "",
    displayName: "",
  };
  const [isEditing, setIsEditing] = useState(false);

  const apiKeysQuery = useQuery([QueryKeys.apiKeys, identityId], () =>
    fetchApiKeys(isUser, identityState)
  );
  const apiProductsQuery = useQuery([QueryKeys.apiProducts, identityId], () =>
    fetchApiProducts(identityState)
  );

  const queryClient = useQueryClient();
  const apiKeyUpdateMutation = useMutation(
    (values: ApiKey) => {
      const newStatus: Status =
        values.status === "approved" ? "approve" : "revoke";
      return editApiKey({ ...values, status: newStatus }, identityState);
    },
    {
      onSuccess: (updatedApiKey) => {
        const updatedApiKeys = apiKeysQuery.data.map((apiKey: ApiKey) => {
          if (apiKey.name === updatedApiKey.name) {
            return updatedApiKey;
          }

          return apiKey;
        });
        queryClient.setQueryData(
          [QueryKeys.apiKeys, identityId],
          updatedApiKeys
        );
        selectedKey = updatedApiKey;
        setIsEditing(false);
      },
    }
  );

  const apiKeyDeleteMutation = useMutation(
    () => deleteApiKey(selectedKey.name, identityState),
    {
      onSuccess: (deletedApiKey) => {
        const remainingApiKeys = apiKeysQuery.data.filter(
          (apiKey: ApiKey) => apiKey.name !== deletedApiKey.name
        );

        queryClient.setQueryData(
          [QueryKeys.apiKeys, identityId],
          remainingApiKeys
        );

        history.push(Routes.developerApiKeys);
      },
    }
  );

  if (apiKeysQuery.isSuccess) {
    selectedKey = apiKeysQuery.data.filter(
      (key: ApiKey) => key.name === props.match.params.keyName
    )[0];
  }

  const isLoading: boolean =
    apiKeysQuery.isLoading || apiProductsQuery.isLoading;
  const isSuccess: boolean =
    apiKeysQuery.isSuccess && apiProductsQuery.isSuccess;
  const isError: boolean =
    apiKeysQuery.isError ||
    apiProductsQuery.isError ||
    apiKeyUpdateMutation.isError;

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  return (
    <Box sx={{ position: "relative", top: "-70px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          alignContent: "space-between",
          marginBottom: "50px",
          gap: "16px",
          height: "43px",
        }}
      >
        {isLoading ? (
          <>
            <Skeleton variant="rectangular" width={141} height={42} />
            <Skeleton variant="rectangular" width={166} height={42} />
          </>
        ) : (
          <>
            <Button
              variant="contained"
              size="medium"
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
              className={isEditing ? classes.noneDisplay : ""}
            >
              Edit Key
            </Button>

            <Button
              variant="contained"
              size="medium"
              startIcon={<CancelIcon />}
              color="error"
              onClick={() => {
                apiKeyDeleteMutation.reset();
                setDeleteModalIsOpen(true);
              }}
              className={isEditing ? classes.noneDisplay : ""}
            >
              Delete Key
            </Button>

            <BackButton />

            <ConfirmationModal
              isLoading={apiKeyDeleteMutation.isLoading}
              isError={apiKeyDeleteMutation.isError}
              open={deleteModalIsOpen}
              header={`Are you sure?`}
              body={`Deleting "${selectedKey?.displayName}" API Key will permanently remove it from your account. This action cannot be undone.`}
              leftButtonText={"Yes, Delete API Key"}
              leftButtonColor="error"
              rightButtonText={"No, Keep API Key"}
              rightButtonColor="primary"
              handleLeftButton={() => apiKeyDeleteMutation.mutate()}
              handleRightButton={() => setDeleteModalIsOpen(false)}
              handleClose={() => setDeleteModalIsOpen(false)}
            />
          </>
        )}
      </Box>

      <Box>
        <ReactQueryErrorWrapper
          queries={[apiKeysQuery, apiProductsQuery]}
          mutations={[apiKeyUpdateMutation, apiKeyDeleteMutation]}
        />

        <Card sx={{ padding: "8px" }}>
          {isLoading && (
            <>
              <Box sx={{ display: "flex" }}>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    padding: "8px",
                  }}
                >
                  {/* Display Name */}
                  <Skeleton variant="rectangular" height="56px" />

                  {/* Key Status */}
                  <Skeleton variant="rectangular" height="56px" />

                  {/* API Products */}
                  <Skeleton variant="rectangular" height="429px" />
                </Box>

                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                    padding: "8px",
                  }}
                >
                  {/* Consumer Key */}
                  <Skeleton variant="rectangular" height="56px" />

                  {/* Consumer Secret */}
                  <Skeleton variant="rectangular" height="56px" />

                  {/* Notes */}
                  <Skeleton variant="rectangular" height="429px" />
                </Box>
              </Box>
            </>
          )}

          {isSuccess && (
            <>
              <Formik<ApiKey>
                initialValues={{
                  createdDate: selectedKey.createdDate,
                  expireDate: selectedKey.expireDate,
                  consumerKey: selectedKey.consumerKey,
                  consumerSecret: selectedKey.consumerSecret,
                  name: selectedKey.name,
                  status: selectedKey.status,
                  products: selectedKey.products,
                  notes: selectedKey.notes,
                  displayName: selectedKey.displayName,
                }}
                onSubmit={async (values) => {
                  apiKeyUpdateMutation.mutate(values);
                }}
                validationSchema={updateApiKeyValidationSchema}
              >
                {({
                  values: {
                    displayName,
                    status,
                    products,
                    consumerKey,
                    consumerSecret,
                    notes,
                  },
                  handleChange,
                  handleBlur,
                  handleReset,
                  errors,
                  touched,
                }) => {
                  return (
                    <Form>
                      <Box sx={{ display: "flex" }}>
                        <Box
                          sx={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                            padding: "8px",
                          }}
                        >
                          {/* Left Container */}

                          <TextField
                            id="displayName"
                            name="displayName"
                            label="Display Name"
                            type="text"
                            value={displayName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.displayName && Boolean(errors.displayName)
                            }
                            helperText={
                              touched.displayName && errors.displayName
                            }
                            disabled={
                              !isEditing || apiKeyUpdateMutation.isLoading
                            }
                            variant={"outlined"}
                            fullWidth
                          />

                          <FormControl sx={{ width: "100%" }}>
                            <InputLabel id="status-label">
                              Key Status
                            </InputLabel>
                            <Select
                              id="status"
                              label="Key Status"
                              labelId="status-label"
                              name="status"
                              value={status}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              disabled={
                                !isEditing || apiKeyUpdateMutation.isLoading
                              }
                              variant={"outlined"}
                              fullWidth
                            >
                              {keyStatus.map((option) => {
                                return (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "10px",
                                      }}
                                    >
                                      {option.icon} {option.label}
                                    </div>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>

                          <FormGroup>
                            <InputLabel>Products</InputLabel>
                            {apiProductsQuery.data
                              .sort((a: any, b: any) =>
                                a.displayName.toUpperCase() >
                                b.displayName.toUpperCase()
                                  ? 1
                                  : -1
                              )
                              .map((product: any) => {
                                return (
                                  <ListItemButton
                                    key={product.name}
                                    sx={{ padding: "0px" }}
                                    disabled={
                                      !isEditing ||
                                      apiKeyUpdateMutation.isLoading
                                    }
                                  >
                                    <FormControlLabel
                                      sx={{
                                        width: "100%",
                                        padding: "8px",
                                        margin: "0px",
                                      }}
                                      control={
                                        <Checkbox
                                          id="products"
                                          name="products"
                                          onChange={handleChange}
                                          value={product.name}
                                          checked={products.includes(
                                            product.name
                                          )}
                                        />
                                      }
                                      label={product.displayName}
                                    />
                                  </ListItemButton>
                                );
                              })}
                          </FormGroup>
                        </Box>

                        <Box
                          sx={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            gap: "24px",
                            padding: "8px",
                          }}
                        >
                          {/* Right Container */}

                          <TextField
                            id="consumerKey"
                            name="consumerKey"
                            label="Consumer Key"
                            type="text"
                            value={consumerKey}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.consumerKey && Boolean(errors.consumerKey)
                            }
                            helperText={
                              touched.consumerKey && errors.consumerKey
                            }
                            disabled={true}
                            variant={"outlined"}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={(): any => {
                                    navigator.clipboard.writeText(consumerKey);
                                  }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              ),
                            }}
                          />

                          <TextField
                            id="consumerSecret"
                            name="consumerSecret"
                            label="Consumer Secret"
                            type="text"
                            value={consumerSecret}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={
                              touched.consumerSecret &&
                              Boolean(errors.consumerSecret)
                            }
                            helperText={
                              touched.consumerSecret && errors.consumerSecret
                            }
                            disabled={true}
                            variant={"outlined"}
                            fullWidth
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={(): any => {
                                    navigator.clipboard.writeText(
                                      consumerSecret
                                    );
                                  }}
                                >
                                  <ContentCopy />
                                </IconButton>
                              ),
                            }}
                          />

                          <TextField
                            id="notes"
                            name="notes"
                            label="Notes"
                            type="text"
                            value={notes}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            error={touched.notes && Boolean(errors.notes)}
                            helperText={touched.notes && errors.notes}
                            disabled={
                              !isEditing || apiKeyUpdateMutation.isLoading
                            }
                            variant={"outlined"}
                            fullWidth
                            multiline
                            rows={17}
                          />
                        </Box>
                      </Box>

                      {isEditing && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            margin: "8px",
                            gap: "16px",
                          }}
                        >
                          <LoadingButton
                            size="small"
                            variant="outlined"
                            type="submit"
                            disabled={apiKeyUpdateMutation.isLoading}
                            loading={apiKeyUpdateMutation.isLoading}
                          >
                            Save Changes
                          </LoadingButton>
                          <Button
                            size="small"
                            color="warning"
                            variant="outlined"
                            disabled={apiKeyUpdateMutation.isLoading}
                            onClick={() => {
                              handleReset();
                              apiKeyUpdateMutation.reset();
                              setIsEditing(false);
                            }}
                          >
                            Cancel
                          </Button>
                        </Box>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
};
