import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { getActiveOrganisation, useIdentity } from "contexts/identity-context";
import * as QueryKeys from "data";
import { fetchBatchesDefinitions, ReactQueryQueryError } from "data/queries";
import _ from "lodash";

import { BatchConfig, BatchDefinition } from "pages/geoscape-batch/models";
import {
  BatchConfigProps,
  StepperControlProps,
} from "pages/geoscape-batch/types";
import {
  batchOutputGeocode,
  batchOutputReverseGeocode,
} from "pages/geoscape-batch/utils";
import { useQuery } from "react-query";
import { resolveIdentityId } from "utils/identity";
import { BatchJobTypeCard } from "./batch-job-type-card";
import { LoadingButton } from "@mui/lab";

interface DefineBatchJobProps {}

export const DefineBatchJob = (
  props: DefineBatchJobProps & BatchConfigProps & StepperControlProps
) => {
  const [identityState] = useIdentity();
  const isUser = !getActiveOrganisation(identityState);
  const identityId = resolveIdentityId(identityState, isUser);

  const definitionsQuery = useQuery<BatchDefinition>(
    [QueryKeys.batchesDefinitionsKey, identityId],
    () => fetchBatchesDefinitions(identityState)
  );

  // Get the definition for the selected job type (i.e. Geocoder, Reverse Geocoder, etc)
  const selectedJobDefinition =
    definitionsQuery.isSuccess &&
    definitionsQuery.data.create.filter(
      (jobDefinition: any) => jobDefinition.type === props.batchConfig.type
    )[0];

  // Set batchConfig.parameters.matchType to first value from definitions endpoint
  // TODO: This will probably break if we add job types without the matchType parameter
  // TODO: There must be a nicer way to do this, maybe doing it at the field level?
  if (
    definitionsQuery.isSuccess &&
    selectedJobDefinition &&
    props.batchConfig.parameters.matchType === ""
  ) {
    // Find the Selected Job Definition Parameter
    const selectedJobParameter = selectedJobDefinition.parameters.filter(
      (parameterDefinition) => parameterDefinition.name === "matchType"
    )[0];

    // Only prefill value if the field is required
    if (selectedJobParameter.isRequired) {
      props.setBatchConfig((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          matchType: selectedJobParameter.values[0],
        },
      }));
    }
  }

  let nameDefined = props.batchConfig.displayName ? false : true;

  return (
    <Card
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
      }}
    >
      <Box>
        <Typography variant="h5">Define Batch Job</Typography>
        <Typography variant="subtitle1" sx={{ color: "grey" }}>
          Define your batch job
        </Typography>
      </Box>

      {definitionsQuery.isLoading && (
        <Skeleton variant="rectangular" height={400} />
      )}

      {definitionsQuery.isError && (
        <Alert severity="error">
          <AlertTitle>
            There was an error fetching the batch definitions.
          </AlertTitle>
          {(definitionsQuery.error as ReactQueryQueryError).message}
        </Alert>
      )}

      {definitionsQuery.isSuccess && (
        <>
          <TextField
            size="small"
            id="batch-name"
            label="Job Name"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 200 }}
            onChange={(event) =>
              props.setBatchConfig((prev: BatchConfig) => ({
                ...prev,
                displayName: event.target.value,
              }))
            }
            value={props.batchConfig.displayName}
            required
          />
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            {definitionsQuery.data.create.map(
              (jobDefinition: any, idx: number) => (
                <BatchJobTypeCard
                  key={`batch-job-type-card-${idx}`}
                  name={jobDefinition.name}
                  subtitle={"XXX"}
                  mandatoryFields={["XXX"]}
                  selected={jobDefinition.type === props.batchConfig.type}
                  onClickHandler={() => {
                    props.setBatchConfig((prev: BatchConfig) => ({
                      ...prev,
                      type: jobDefinition.type,
                      parameters: {
                        matchType: "",
                        stateFilter: [],
                        dataset: [],

                        additionalProperties: [],
                      },
                      output: {
                        format: [],
                      },
                      hubInfo: {
                        ...prev.hubInfo,
                        checkedItems:
                          jobDefinition.type == "addressGeocoder"
                            ? batchOutputGeocode.properties
                            : batchOutputReverseGeocode.properties,
                      },
                    }));
                  }}
                />
              )
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
            {selectedJobDefinition &&
              selectedJobDefinition.parameters.map((selectedJobParameter) => {
                const label = _.startCase(selectedJobParameter.name);

                return (
                  <FormControl size="small" fullWidth>
                    <InputLabel id={label}>{label}</InputLabel>
                    <Select
                      label={label}
                      labelId={label}
                      required={selectedJobParameter.isRequired}
                      value={
                        props.batchConfig.parameters[selectedJobParameter.name]
                      }
                      multiple={selectedJobParameter.isMultiple}
                      renderValue={(selected) => {
                        if (selectedJobParameter.isMultiple) {
                          const startCaseValues = (
                            selected as unknown as string[]
                          ).map((value) => _.startCase(value));
                          return startCaseValues.join(", ");
                        } else {
                          return _.startCase(selected);
                        }
                      }}
                      onChange={(event: SelectChangeEvent) =>
                        props.setBatchConfig((prev: BatchConfig) => ({
                          ...prev,
                          parameters: {
                            ...prev.parameters,
                            [selectedJobParameter.name]: event.target.value,
                          },
                          hubInfo: {
                            ...prev.hubInfo,
                            sample: {
                              ...prev.hubInfo.sample,
                              request: [],
                              response: [],
                            },
                          },
                        }))
                      }
                      fullWidth
                    >
                      {selectedJobParameter.values.map(
                        (selectedJobParameterValue: string) => (
                          <MenuItem value={selectedJobParameterValue}>
                            {selectedJobParameter.isMultiple && (
                              <Checkbox
                                checked={props.batchConfig.parameters[
                                  selectedJobParameter.name
                                ].includes(selectedJobParameterValue)}
                                sx={{
                                  padding: "0px",
                                  paddingRight: "8px",
                                }}
                              />
                            )}
                            <ListItemText
                              primary={_.startCase(selectedJobParameterValue)}
                            />
                          </MenuItem>
                        )
                      )}
                    </Select>
                    {selectedJobParameter.isRequired && (
                      <FormHelperText>* Required</FormHelperText>
                    )}
                  </FormControl>
                );
              })}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "16px",
            }}
          >
            <LoadingButton
              variant="contained"
              onClick={() => {
                props.saveBatchConfig(
                  props.batchConfig,
                  Math.min(props.activeStep + 1, props.maxSteps)
                );
              }}
              loading={props.isSaveBatchConfigLoading}
            >
              Continue
            </LoadingButton>
          </Box>
        </>
      )}
    </Card>
  );
};
