import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ReactQueryQueryError } from "data/queries";
import {
  InputBatchConfigFormat,
  MappingTableProps,
} from "pages/geoscape-batch/types";
import { extractSampleRows } from "./upload-input-file-page";

const MappingTable = ({
  isError,
  deleteObjectMutation,
  headers,
  SEARCHTERMS,
  setHeaders,
  batchConfig,
  setBatchConfig,
  updateBatchConfig,
}: MappingTableProps) => {
  const handleProductsChange = (
    index: number,
    event: SelectChangeEvent<any>
  ) => {
    const { value } = event.target;

    setBatchConfig((prev: any) => ({
      ...prev,
      hubInfo: {
        ...prev.hubInfo,
        sample: {
          ...prev.hubInfo.sample,
          request: [],
          response: [],
        },
      },
    }));

    setHeaders((prevHeaders: InputBatchConfigFormat[]) => {
      const newHeaders = [...prevHeaders];
      newHeaders[index].apiParameter = value;
      return newHeaders;
    });
  };

  const inputApiParameters = batchConfig.input?.format.map(
    (d: any) => d.apiParameter
  );

  return (
    <>
      {headers.length == 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1"> No Input</Typography>
        </Box>
      )}

      {headers.length > 0 && (
        <>
          {isError && (
            <Typography color="red">
              Error -{" "}
              {(deleteObjectMutation.error as ReactQueryQueryError).message}
            </Typography>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            {headers.map((header: InputBatchConfigFormat, index: number) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "24px",
                  justifyContent: "space-around",
                  width: "100%",
                  marginBottom: "16px",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    width: "15%",
                    justifyContent: "flex-end",
                  }}
                >
                  Column {index + 1}
                </Typography>
                <Card
                  sx={{
                    boxShadow: "0px 0px 2px 0px",
                    display: "flex",
                    justifyContent: "center",
                    gap: "16px",
                    width: "80%",
                    padding: "8px",
                  }}
                >
                  <TextField
                    size="small"
                    id={`name-${index}`}
                    name="name"
                    label="Column Name"
                    type="text"
                    value={header.name}
                    variant="outlined"
                    sx={{ width: "50%" }}
                  />
                  <FormControl sx={{ width: "50%" }} size="small">
                    <InputLabel id={`products-label-${index}`}>
                      Search Term
                    </InputLabel>
                    <Select
                      labelId={`products-label-${index}`}
                      id={`products-select-${index}`}
                      label="Search Term"
                      value={
                        Object.keys(SEARCHTERMS).includes(header.apiParameter)
                          ? header.apiParameter
                          : "Ignore"
                      }
                      onChange={(e) => {
                        handleProductsChange(index, e);
                        updateBatchConfig();
                      }}
                      fullWidth
                    >
                      {Object.keys(SEARCHTERMS).map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          disabled={inputApiParameters.includes(
                            SEARCHTERMS[name]
                          )}
                        >
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Card>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};

export default MappingTable;
