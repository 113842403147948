import { Box, Card, Chip } from "@mui/material";

import { GridColDef, GridRowParams } from "@mui/x-data-grid-premium";
import CustomTable from "components/shared/table";
import { ApiKey, Status } from "contexts/api-key-state";
import { generatePath, useHistory } from "react-router-dom";
import { formatTimestamp } from "utils";
import { columns } from "./columns";



const keysToRows = (keys: ApiKey[]) => {
  const keys_ = keys.map((key) => {
    return {
      id: key.name,
      createdDate: key.createdDate,
      expireDate: key.expireDate,
      name: key.displayName ? key.displayName : key.name,
      status: key.status,
    };
  });
  return keys_.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  });
};



interface ApiKeyTableProps {
  data: any;
  isLoading: boolean;
  apiKeyDetailsRoute: string;
  accountName?: string;
  accountId?: string;
}

export const ApiKeyTable = (props: ApiKeyTableProps) => {
  const history = useHistory();
  const handleOnRowClick = (params: GridRowParams) => {
    history.push(
      generatePath(props.apiKeyDetailsRoute, {
        accountId: props.accountId,
        keyName: params.id,
      }),
      {
        accountId: props.accountId,
        accountName: props.accountName,
        keyName: params.id,
      }
    );
  };

  return (
    <Card
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <CustomTable
        rows={props.data ? keysToRows(props.data) : []}
        cols={columns}
        handleOnRowClick={handleOnRowClick}
        isLoading={props.isLoading}
        overlayText={"You do not have any API Keys."}
      />
    </Card>
  );
};
